<!--
 * @Author: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @Date: 2024-07-16 14:18:10
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-09-25 10:19:14
 * @FilePath: \vue2-rem\src\views\Home.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home">
    <van-index-bar :index-list="indexList">
      <template v-for="(item) in indexList">
        <van-index-anchor :index="item" />
        <van-cell v-for="(val,n) in brandsObj[item]" @click="brandsClick(val)" :key="item+'id'+n" :title="val.brandName" />
      </template>
    </van-index-bar>

    <van-popup v-model="brandShow" position="right" :style="{ height: '100%',width:'80%' }" >
      <div class="">
        <div class="flex-sb popupTopBox">
          <p @click="brandShow = false">返回</p>
          <span>{{brandName}}</span>
          <i></i>
        </div>
        <div class="van-index-anchor bg-col">车系</div>
        <p v-for="(item,i) in seriesList" @click="secondClick(item)" :key="i" class="chexiCla">{{item.seriesName}}</p>
      </div>
    </van-popup>

    <van-popup v-model="modelsShow" position="right" :style="{ height: '100%',width:'80%' }" >
      <div class="">
        <div class="flex-sb popupTopBox">
          <p @click="modelsShow = false">返回</p>
          <span>{{seriesName}}</span>
          <i></i>
        </div>
        <div class="van-index-anchor bg-col">车型</div>
        <p v-for="(item,i) in thirdList" @click="thirdClick(item)" :key="i" class="chexiCla">{{item.modelName}}</p>
      </div>
    </van-popup>

  </div>
</template>

<script>
// @ is an alias to /src
import { IndexBar,IndexAnchor,Cell,Popup } from 'vant'
import { getCatBrandsList,getCatSeriesList,getCatModelsList } from "@/api";
export default {
  name: 'Home',
  components: {
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
  },
  data() {
    return {
      brandShow:false,
      modelsShow:false,
      indexList:[],
      brandsObj:{},
      seriesList:[],
      thirdList:[],
      brandId:'',
      brandName:'',
      seriesId:'',
      seriesName:'',
      seriesPic:'', // 车系照片
    }
  },
  created() {
    this.$navBar.setShow(false);
    this.$tabBar.setShow(false);
    getCatBrandsList().then(res=>{
			if(res.code == '00000'){
        let arr1 = [];
        res.data.forEach(item => {
          if(!this.indexList.includes(item.brandInitial)){
            this.indexList.push(item.brandInitial);
          }
          if(!this.brandsObj[item.brandInitial]){
            this.brandsObj[item.brandInitial]= [];
          }
          this.brandsObj[item.brandInitial].push(item);
        });
        this.bandList = res.data;
			}
		})
  },
  methods: {
    thirdClick(obj){
      setTimeout(()=>{
        this.$bus.$emit('emitChangeIsCar',{
          brandId:this.brandId,
          brandName:this.brandName,
          seriesId:this.seriesId,
          seriesName:this.seriesName,
          modelId:obj.modelId,
          modelName:obj.modelName,
          seriesPic:this.seriesPic,
        });
      },250)
      this.$router.go(-1);
    },
    to_home(){
      this.$router.push({
        path:'/home',
      });
    },
    brandsClick(obj){
      this.brandId = obj.brandId;
      this.brandName = obj.brandName;
      getCatSeriesList(obj.brandId).then(res=>{
        if(res.code == '00000'){
          this.seriesList = res.data;
          this.brandShow = true;
        }
      })
    },
    secondClick(obj){
      this.seriesId = obj.seriesId;
      this.seriesName = obj.seriesName;
      this.seriesPic = obj.seriesPic;
      getCatModelsList(obj.seriesId).then(res=>{
        if(res.code == '00000'){
          this.thirdList = res.data;
          this.modelsShow = true;
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
@import '~@/styles/mixins.less';
.chexiCla{
  border-bottom: 2px solid #F5F5F5;
  font-size: 32px;
  color: #131212;
  padding: 24px;
  line-height: 36px;
}
.bg-col{
  background-color: #F5F5F5;
}
.popupTopBox{
  border-bottom: 2px solid #F5F5F5;
  position: sticky;
  background-color: white;
  z-index: 1;
  top: 0px;
  padding: 24px;
  margin-right: 32px;
  p{
    font-size: 32px;
    color: #131212;
  }
  span{
    font-size: 32px;
    color: #131212;
  }
}
.home {
  height: 100%;
}

</style>
