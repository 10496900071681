<!--
 * @Author: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @Date: 2024-07-16 14:18:10
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-10-15 17:30:05
 * @FilePath: \vue2-rem\src\views\Home.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home">
    <img class="img1" src="https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/h5_login_logo.png" alt="">
    <p class="pro-name">安信查</p>
    <van-field v-model="tel" class="inp-box" clearable type="tel" maxlength="11" placeholder="请输入账号"></van-field>
    <van-field v-model="password" class="inp-box" clearable type="password" placeholder="请输入密码"></van-field>
    <van-field
      class="inp-box inp-sms"
      v-model="verifyCode"
      center
      type="text"
      placeholder="请输入验证码"
    >
      <template #button>
        <img @click="getCaptchaInfo" class="imgCodeCla" :src="verifyCodeImgUrl" alt="">
      </template>
    </van-field>
    <van-button @click="to_home" class="aabb" size="small" type="primary">立即登录</van-button>
    <div class="flex-start-2 xieyi-box">
      <van-checkbox v-model="checked" shape="square" checked-color="#093F87"></van-checkbox>
      <p>已阅读并同意安信查检测<span @click="openAgreement('https://f.auto-axc.com/pc-an/static/new_privacy.pdf')">用户隐私政策</span>及
        <span @click="openAgreement('https://f.auto-axc.com/pc-an/static/new_agreement.pdf')">服务条款</span></p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Button,Field,Checkbox } from 'vant'
import { loginApi,getUserInfo,getCaptcha } from "@/api";
import { setToken,removeToken,setUserInfo } from '@/utils/userInfo';
export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      tel:'',
      password:'',
      verifyCode:'',
      checked:false,
      verifyCodeImgUrl:'',
    }
  },
  created() {
    this.$navBar.setShow(false);
    this.$tabBar.setShow(false);
    this.getCaptchaInfo();
  },
  methods: {
    openAgreement(URL){
			window.open(URL)
		},
    getCaptchaInfo(){
      getCaptcha().then(({ data })=>{
        const { captchaImgBase64, verifyCodeKey } = data;
        this.verifyCodeImgUrl = captchaImgBase64;
        this.verifyCodeKey = verifyCodeKey;
      })
    },
    to_home(){
      if(this.tel.length != 11){
        this.$toast("手机号长度为11位");
        return ;
      }
      if(this.password == ''){
        this.$toast("密码不能为空");
        return ;
      }
      if(this.verifyCode == ''){
        this.$toast("验证码不能为空");
        return ;
      }
      if(!this.checked){
        this.$toast("请先阅读并同意用户隐私政策和服务条款");
        return ;
      }
      
      loginApi({
        username:this.tel.trim(),
        password: this.password,
        grant_type: 'captcha',
        verifyCode: this.verifyCode,
        verifyCodeKey: this.verifyCodeKey,
      }).then(res=>{
        if(res.code == '00000'){
          // 持久化token
          setToken(res.data.access_token);
          // 将token存储至vuex
          this.$store.commit('user/setToken', res.data.access_token);
          getUserInfo().then(({ data })=>{
            if(data.roles.includes('INSPECTOR')){
              setUserInfo(data);
              this.$router.push({
                path:'/home',
              });
            }else{
              removeToken();
              this.$store.commit('user/loginOut');
              this.$toast("检测师账号不存在");
              this.verifyCode = '';
              this.getCaptchaInfo();
            }
          })
        }else{
          this.verifyCode = '';
          this.getCaptchaInfo();  
        }
      }).catch(err=>{
        this.verifyCode = '';
        this.getCaptchaInfo();
      })
    },
  },
}
</script>
<style>
  .inp-sms .van-field__control{
    border-right: 1px solid #DCDEE0;
  }
  .xieyi-box .van-checkbox{
    overflow: inherit;
  }
</style>
<style lang="less" scoped>
@import '~@/styles/mixins.less';
.inp-sms span{
  color: #042550;
}
.imgCodeCla{
  width: 180px;
  height: 50px;
  display: block;
}
.xieyi-box{
  width: 556px;
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  p{
    font-size: 30px;
    line-height: 38px;
    color: #A6A6A6;
    margin-left: 15px;
    span{
      color: #093F87;
      font-size: 28px;
      line-height: 34px;
    }
  }
}
.inp-sms{
  margin-top: 24px;
}
.img1{
  width: 312px;
  height: 88px;
}
.inp-box{
  margin-top: 20px;
  width: 686px;
  background: #F2F3F5;
}
.pro-name{
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
  color: #181818;
  margin-top: 24px;
  margin-bottom: 84px;
}
.aabb{
  width: 686px;
  height: 104px;
  line-height: 104px;
  color: white;
  font-size: 32px;
  text-align: center;
  background-color:#093F87;
  border-radius: 12px;
  margin-top: 24px;
  margin-bottom: 200px;
  border-color: #093F87;
}
.home {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/login-bg.png);
  background-size: 100% 100%;
}

</style>
