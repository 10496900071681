<template>
  <div>
    <van-overlay :show="dialogShow" style="background-color: rgba(0, 0, 0, 0.9);">
      <div class="wrapper" @click="dialogShow = false">
        <div class="mask-box" @click.stop>
          <van-swipe @change="onChange" autoplay="5000">
            <van-swipe-item v-for="(item, index) in imagesList" :key="index">
              <div class="mask-c">
                <div class="flex-center">
                  <img style="width: 50%;" v-lazy="item.src"/>
                </div>
                <span v-if="item.isRepairFlag" class="sel">修复前</span>
                <span v-if="item.repairFlag" class="sel">修复后</span>
              </div>
            </van-swipe-item>
          </van-swipe>
          
          <div v-if="imagesList[selIndex]" class="flex-sb mask-b">
            <div class="flex-start">
              <span v-if="imagesList[selIndex].repairFlag" class="sel">已修复</span>
              <span>{{imagesList[selIndex].name}}</span>
            </div>
            <p>{{selIndex+1}}/{{imagesList.length}}</p>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
  import { Overlay,Swipe,SwipeItem } from 'vant'

  export default {
    components: {
      [Overlay.name]: Overlay,
      [Swipe.name]: Swipe,
      [SwipeItem.name]: SwipeItem,
    },
    data() {
      return {
        dialogShow:false,
        selIndex:0,
        imagesList:[],
      }
    },
    methods: {
      setImgsList(list){
        this.dialogShow = true;
        this.imagesList = list;
        this.selIndex = 0;
      },
      onChange(index) {
        this.selIndex = index;
      },
    },
  }
</script>
<style lang="less" scoped>
  .mask-box{
    width: 100%;
    .mask-c{
      width: 100%;
      position: relative;
      img{
        width: 100%;
        display: block;
      }
      span{
        position: absolute;
        top: 24px;
        left: 24px;
      }
    }
    span{
      color: #A6A6A6;
      padding: 4px 16px;
      border: 2px solid #A6A6A6;
      border-radius: 6px;
      margin-right: 16px;
    }
    .sel{
      border-color: #618DFF;
      color: #618DFF;
    }
    .mask-b{
      padding: 24px;
      p{
        font-size: 32px;
        line-height: 32px;
        color: #FFFFFF;
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 120px;
    height: 120px;
    background-color: #fff;
  }
</style>