<!--
 * @Author: “lihui” 13723683480@163.com
 * @Date: 2024-07-26 14:53:52
 * @LastEditors: lihui 13723683480@163.com
 * @LastEditTime: 2024-10-13 17:26:52
 * @FilePath: \an-h5\src\views\AddTicket.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="ticketBox">
    <van-form ref="formRef" @submit="fromSubmit">
      <div class="col-box">
        <div class="col-item">
          <h6>车辆信息</h6>
          
          <van-field
            v-model="vehicleInfo.vin"
            label="VIN码"
            required
            maxlength="17"
            placeholder="请输入原车辆VIN码"
            :rules="[{ validator,required: true }]"
            @input="vinChange"
          />
          <!-- right-icon="scan"
          @click-right-icon="vinSweep" -->
          <van-field
            v-model="vehicleInfo.modelName"
            label="品牌车型"
            required
            @click="to_car"
            is-link
            readonly
            placeholder="请选择原车品牌"
            :rules="[{ required: true}]"
          />
          <van-field v-if="isShowPicImg" name="uploader" label="车系图片" required>
            <template #input>
              <van-uploader v-model="seriesPicList" lazy-load capture="camera" image-fit="contain" :max-count="1" :before-delete="deleteImg" :after-read="afterRead" />
            </template>
          </van-field>
        </div>
  
        <div class="col-item">
          <h6>回用件信息</h6>
          <van-field :value="vehiclePartInfo.partName" label="类型名称" required readonly is-link placeholder="请选择回用件类型名称" @click="showPicker = true"
            :rules="[{ required: true }]" />
            <van-popup v-model="showPicker" position="bottom">
              <van-picker title="" show-toolbar :columns="vehiclePartOption" value-key="name" @cancel="showPicker = false" @confirm="onConfirmVehiclePart" />
            </van-popup>
  
          <van-field name="uploader" label="图片留存" required>
            <template #input>
              <van-uploader v-model="vehiclePartInfo.partPics" lazy-load capture="camera" :max-count="10" :before-read="beforeRead" :before-delete="deleteImg" :after-read="afterRead" />
            </template>
          </van-field>
        </div>
  
        <div class="col-item">
          <h6>拆卸信息</h6>
          <van-field v-model="disassembleFlagName" label="是否拆卸" readonly required is-link placeholder="请选择回用件是否被拆卸"
            :rules="[{ required: true }]" @click="showPicker2 = true"/>
          <van-popup v-model="showPicker2" position="bottom">
            <van-picker title="" show-toolbar :columns="columns" multiple @cancel="showPicker2 = false" @confirm="onConfirmFlag" />
          </van-popup>
          <van-field name="uploader" label="留存照" required>
            <template #input>
              <van-uploader v-model="disassemblePhotos" capture="camera" :max-count="10" :before-delete="deleteImg" :after-read="afterRead" />
            </template>
          </van-field>
        </div>
  
        <div class="col-item">
          <h6>检测机构信息</h6>
          <p class="desc-t1"><span>检测机构：</span>{{userInfo.deptName||'-'}}</p>
          <p class="desc-t1"><span>检测人员：</span>{{userInfo.nickname||'-'}}</p>
        </div>
      </div>
      
      <div class="bottom-box flex-sb">
        <van-button native-type="button" @click="sweepClick" class="btn1" plain>扫一扫</van-button>
        <van-button native-type="submit" square type="primary" class="btn2">提交工单</van-button>
      </div>
    </van-form>
    
  </div>
</template>
<script>
  // @ is an alias to /src
  import { Form,Field,Button,Uploader,Popup,Picker,Dialog} from 'vant'
  import { deleteFileApi,getVehiclePartTypeList,getVehiclePartOptionList,submitCreateOrder,getCodeDetailItem,vinIdentify,directUpload,partLabelPrintFlag } from '@/api'
  import { getUserInfo } from "@/utils/userInfo";
  import * as OSS from "@/utils/oss";  // 引入oss.js


  export default {
    name: 'Home',
    components: {
      [Field.name]: Field,
      [Form.name]: Form,
      [Button.name]: Button,
      [Uploader.name]: Uploader,
      [Popup.name]: Popup,
      [Picker.name]: Picker,
      [Dialog.name]: Dialog,
      [Dialog.Component.name]: Dialog.Component,
    },
    data() {
      return {
        showPicker:false,
        showPicker2:false,
        userInfo:{}, 
        disassembleFlag:'', // 是否拆卸
        disassembleFlagName:'',
        columns:['未拆卸','已拆卸'],
        disassemblePhotos:[], // 拆卸留存图片
        vehicleInfo:{
          vin:'',
          brandId:'', // 品牌id
          brandName:'', // 品牌名称
          seriesId:'', // 车系id
          seriesName:'', // 车系名称
          modelId:'', // 车型ID
          modelName:'', //车型名称
          seriesPic:'', //车系图片
        },
        vehiclePartInfo:{
          typeId:'', // 回用件类型ID
          typeName:'', // 回用件类型名称
          partId:'', // 回用件ID
          partName:'', // 回用件名称
          partPics:[], // 回用件留存图片
        },
        vehiclePartOption:[],
        dataInfo:{
          val1:''
        },
        isShowPicImg:false, // 是否展示车系图片
        seriesPicList: [
        // {
        //   url: 'https://img01.yzcdn.cn/vant/leaf.jpg',
        //   status: 'uploading',
        //   message: '上传中...',
        // },
        ],
        uploader:[],
        tabsIndex:0,
        toastId:'',
        timeoutId:'',
      }
    },
    created () {
      this.$navBar.setTitle('创建工单')
      this.$tabBar.setShow(false);
      let data = localStorage.getItem('temporaryCacheAddTicketData');
      if(data){
        Object.assign(this,JSON.parse(data));
        localStorage.removeItem('temporaryCacheAddTicketData');
      }
      this.$bus.$on('emitChangeIsCar', this.emitChangeIsCarInfo);
      this.$bus.$on('detailWorkPartCode', this.getDetail);
      
      getVehiclePartTypeList().then(res=>{
        if(res.code == '00000'){
          res.data.forEach(item=>{
            item.children = [];
            this.getVehiclePartOptionListInfo(item);
          })
          this.vehiclePartOption = res.data;
        }
      })
      this.userInfo = getUserInfo();
    },
    // 以使用data和methods的钩子函数
    beforeDestroy(){
      
    },
    // 无法操作this
    destroyed(){
      this.$bus.$off('emitChangeIsCar');
      this.$bus.$off('detailWorkPartCode');
    },
    methods: {
      // 校验函数返回 true 表示校验通过，false 表示不通过
      validator(val) {
        var result = val.match(/^.*[a-z]+.*$/);
        if(result==null) return true;
        this.$toast('VIN码字母需为大写');
        return false;
      },
      vinChange(e){
        console.log(e);
        if(this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(()=>{
          vinIdentify({vin:e}).then(res=>{
            this.timeoutId = '';
            if(res.code == '00000' && res.data){
              if(res.data.brandId && res.data.modelId && res.data.seriesId) this.emitChangeIsCarInfo(res.data);
            }
          })
        },1500);
      },
      // vin扫一扫
      vinSweep(e){
        var that = this;
        var parameter={"timeout":50}; // 超时时间
        const toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '识别中',
        });
        Bridge.interfaces.Android.scan(JSON.stringify(parameter)).then(function (s) {
          console.log(s.toString());
          toast.clear();
          if(s.code == '00000' && s.data){
            that.vehicleInfo.vin = s.data.codeResult;
            vinIdentify({vin:that.vehicleInfo.vin}).then(res=>{
              that.timeoutId = '';
              if(res.code == '00000' && res.data){
                if(res.data.brandId && res.data.modelId && res.data.seriesId) that.emitChangeIsCarInfo(res.data);
              }
            })
          }
        }).catch(function (err) {
          toast.clear();
          that.$toast(err.message);
          console.log("openCamera=>" + err.message);
        });
      },
      // 扫一扫回显
      getDetail(partCode){
        const toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '获取中',
        });
        getCodeDetailItem(partCode).then(res=>{
          toast.clear();
          if(res.code == '00000'){
            this.vehicleInfo = res.data.vehicleInfo;
            this.seriesPicList = [{
              url:res.data.vehicleInfo.seriesPic,
              status:'done'
            }]
            this.vehiclePartInfo = res.data.vehiclePartInfo;
            let arr = [];
            res.data.vehiclePartInfo.partPics.forEach(item=>{
              arr.push({
                url:item,
                status:'done'
              })
            })
            this.vehiclePartInfo.partPics = arr;
            this.vehiclePartInfo.partId = res.data.vehiclePartInfo.partConfigId;
            delete res.data.vehiclePartInfo.partConfigId;
            this.disassembleFlag=res.data.workOrderInfo.disassembleFlag; // 是否拆卸
            this.disassembleFlagName=this.columns[res.data.workOrderInfo.disassembleFlag];
            
            let arr2 = [];
            res.data.workOrderInfo.disassemblePhotos.forEach(item=>{
              arr2.push({
                url:item,
                status:'done'
              })
            })
            this.disassemblePhotos = arr2;
          }
        })
      },
      // 工单扫描回显
      sweepClick(){
        window.h5ScanCode();
      },
      fromSubmit(value){
        let msg = '';
        if(!this.vehiclePartInfo.partPics.length){
          msg = "回用件留存照片不能为空";
        }else if(!this.disassemblePhotos.length){
          msg = "留存照不能为空";
        }
        if(msg){
          this.$toast(msg);
          return ;
        }
        if(this.seriesPicList.length){
          this.vehicleInfo.seriesPic = this.seriesPicList[0].url;
        }

        let parmas = {
          vehicleInfo:this.vehicleInfo,
          vehiclePartInfo:JSON.parse(JSON.stringify(this.vehiclePartInfo)),
          disassemblePhotos:this.disassemblePhotos,
          disassembleFlag:this.disassembleFlag,
        }
        let arr = [];
        this.vehiclePartInfo.partPics.forEach(item=>{
          arr.push(item.url);
        })
        parmas.vehiclePartInfo.partPics = arr;
        parmas.disassemblePhotos = [];
        this.disassemblePhotos.forEach(item=>{
          parmas.disassemblePhotos.push(item.url);
        })

        const toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '保存中',
        });
        submitCreateOrder(parmas).then(async res=>{
          toast.clear();
          if(res.code == '00000'){
            let status = await window.setPrintDevice(res.data.partId); // 打印回用件标签
            if(status ===1){
              partLabelPrintFlag({orderSn:res.data.orderSn,partLabelType:'1'});
              if(res.data.rfidMust === 1){
                this.$store.state.app.rfidPartCode = res.data.partCode;
                this.$store.state.app.vmScanOrderSn = res.data.orderSn;
                this.$store.state.app.isShowRfidMask = true;
                window.requestRFIDDevice();
              }else{
                this.to_detail(res);
              }
            }else{
              this.connectRetryDevice(1,res); // 重试机制
            }
          }
        }).catch(err=>{
          toast.clear();
        })
      },
      to_detail(res){
        // let list = localStorage.getItem('printDeviceList');
        // if(!list){
        //   list = [];
        // }else{
        //   list = JSON.parse(list);
        // }
        // list.push(res.data.orderSn);
        // localStorage.setItem('printDeviceList',JSON.stringify(list));
        this.$router.push('/detailTicket?orderSn='+res.data.orderSn); 
      },
      connectRetryDevice(n,res){ // 重试3次连接，连接都失败则直接跳到详情
        if(this.toastId){
          this.toastId.clear();
          this.toastId = null;
        }
        setTimeout(async ()=>{
          this.toastId = this.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: '打印失败,重试第'+n+'次',
          });
          let status = await window.setPrintDevice(res.data.partId);
          if(status ===1){
            this.to_detail(res);
          }else{
            n++;
            if(n==4){
              this.toastId.clear();
              Dialog.alert({
                title: '提示',
                message: '打印失败，请稍后再试',
                showCancelButton:false,
              }).then(() => {
                this.to_detail(res);
              })
            }else{
              this.connectRetryDevice(n,res);
            }
          }
        },1000)
      },
      getVehiclePartOptionListInfo(val){
        getVehiclePartOptionList(val.id).then(res=>{
          if(res.code == '00000'){
            val.children = res.data;
          }
        })
      },
      onConfirmVehiclePart(item,ids){
        this.vehiclePartInfo.typeName = item[0];
        this.vehiclePartInfo.typeId = this.vehiclePartOption[ids[0]].id;
        this.vehiclePartInfo.partName = item[1];
        this.vehiclePartInfo.partId = this.vehiclePartOption[ids[0]].children[ids[1]].id;
        this.showPicker = false;
      },
      onConfirmFlag(ag1,ag2){
        this.disassembleFlagName = ag1;
        this.disassembleFlag = ag2;
        this.showPicker2 = false;
      },
      beforeRead(){
        return true;
      },
      async afterRead(file) {
        delete file.content;
        file.uuid = Math.floor(Math.random() * 1000000000);
        file.status = 'uploading';
        file.message = '上传中...';
        try {
          // 上传文件
          const result = await OSS.client().put(`vehicle/${file.file.name}`, file.file);
          if(result.res && result.res.status == '200'){
            // result.url = result.url.replace('http://noi-ai.oss-cn-hangzhou.aliyuncs.com','https://f.auto-axc.com');
            file.url = result.url;
            file.status = 'done';
            return ;
          }
        } catch (error) {
          
        }
        file.status = 'failed';
        file.message = '上传失败';
        this.$toast('图片上传,请稍后重试');
        let i = this.vehiclePartInfo.partPics.findIndex(item=>{
          return item.uuid == file.uuid
        })
        if(i!==-1){
          this.vehiclePartInfo.partPics.splice(i,1);
        }
        let i2 = this.disassemblePhotos.findIndex(item=>{
          return item.uuid == file.uuid
        })
        if(i2!==-1){
          this.disassemblePhotos.splice(i2,1);
        }
      },
      deleteImg(file){
        return new Promise((resolve, reject) => {
          deleteFileApi(file.url).then(res=>{
            if(res.code == '00000'){
              resolve(true);
            }
          })
        })
        
      },
      emitChangeIsCarInfo(val){
        this.vehicleInfo.brandId = val.brandId;
        this.vehicleInfo.brandName = val.brandName;
        this.vehicleInfo.seriesId = val.seriesId;
        this.vehicleInfo.seriesName = val.seriesName;
        this.vehicleInfo.modelId = val.modelId;
        this.vehicleInfo.modelName = val.modelName;
        if(val.seriesPic){
          this.isShowPicImg = true;
          this.seriesPicList = [{url:val.seriesPic,status:'done',deletable: false}];
        }else{
          this.seriesPicList = [];
          this.isShowPicImg = false;
        }
        console.log(this.vehicleInfo);
      },
      inpClick(){
        this.showPicker = true;
      },
      to_car(){
        localStorage.setItem('temporaryCacheAddTicketData',JSON.stringify(this.$data))
        this.$router.push({
          path:'/carList',
        });
      },
    },
  }
  </script>
<style lang="less" scoped>
  .col-box{
    padding: 0 24px;
    padding-bottom: 180px;
  }
  .col-item{
    margin-top: 24px;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 24px 0;
    padding-bottom: 12px;
    h6{
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      margin-left: 24px;
      color: #181818;
    }
    .desc-t1{
      margin-top: 12px;
      color: #383838;
      font-size: 28px;
      line-height: 40px;
      margin-left: 24px;
      span{
        font-size: 32px;
        line-height: 40px;
        color: #5E5E5E;
      }
    }
  }
.ticketBox {
  height: 100%;
  background-color: #EDEDEF;
}
.bottom-box{
  position: fixed;
  bottom: 0px;
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  background: #FFFFFF;
  box-shadow: 0px -8px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 16px 24px;
  padding-bottom: 40px;
  .btn1{
    color: #000000;
    width: 343px;
    border: 2px solid #042550;
    border-radius: 12px;
  }
  .btn2{
    width: 343px;
    background: #042550;
    border-radius: 12px;
    border: 2px solid #042550;
  }
}
</style>
