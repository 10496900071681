<!--
 * @Author: “lihui” 13723683480@163.com
 * @Date: 2024-07-26 14:53:52
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-10-18 10:26:58
 * @FilePath: \an-h5\src\views\AddTicket.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="ticketBox">
    <div class="topBox">
      <div class="flex-start topT1">
        <p v-if="detailData.workOrderInfo.inboundOutboundStatus === 1">已入库</p>
        <p v-else-if="detailData.workOrderInfo.inboundOutboundStatus === 2">已出库</p>
        <p v-else-if="detailData.workOrderInfo.repairStatus === 0">整备中</p>
        <p v-else>{{orderStatusObj[detailData.workOrderInfo.orderStatus]}}</p>
        <span v-if="detailData.workOrderInfo.inboundOutboundStatus === 0" class="c_rr">待入库</span>
        <span v-if="detailData.workOrderInfo.repairStatus === 1">已整备</span>
        <span v-if="detailData.workOrderInfo.disassembleFlag ===1">已拆卸</span>
        <span v-if="detailData.workOrderInfo.cleanFlag ===1">已清洗</span>
        <span v-if="detailData.workOrderInfo.recheckFlag ===1">复检</span>
        
      </div>
      <p class="topT2 margin-t-24">[{{detailData.vehiclePartInfo.typeName}}]{{detailData.vehiclePartInfo.partName}}</p>
      <div class="flex-sb">
        <p class="topT2">工单编码</p>
        <p class="topT2">{{detailData.workOrderInfo.orderSn}}</p>
      </div>

      <div @click="to_page('/TestReport?reportSn='+detailData.workOrderInfo.reportSn)" 
      v-if="detailData.workOrderInfo.orderStatus==2" class="flex-sb colBox2">
        <div class="flex-start colBox2-l">
          <img src="https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/detailTicketIcon1.png" alt="">
          <div>
            <p class="t1">最近生成时间：{{detailData.workOrderInfo.lastReportGenerateTime}}</p>
            <p class="t2">检测报告</p>
          </div>
        </div>
        <van-icon name="arrow" size="20" />
      </div>

      <div class="colBox3">
        <p class="t1">工单信息</p>
        <p class="t2">创建时间：{{detailData.workOrderInfo.createTime}}</p>
        <p class="t2">工单编号：{{detailData.workOrderInfo.orderSn}}</p>
        <p v-if="detailData.workOrderInfo.recheckOrderSn" class="t2">复检工单编号：{{detailData.workOrderInfo.recheckOrderSn}}</p>
        <div class="t3C">
          <p>检测机构：{{detailData.workOrderInfo.detectMechanismName||'-'}}</p>
          <p>检测人员：{{detailData.workOrderInfo.inspectorName||'-'}}</p>
        </div>
      </div>

      <div class="colBox3">
        <p class="t1">回用件信息</p>
        <p class="t2">回用件名称：{{detailData.vehiclePartInfo.partName}}</p>
        <p class="t2">回用件类型：{{detailData.vehiclePartInfo.typeName}}</p>
        <p class="t2">回用件编码：{{detailData.vehiclePartInfo.partCode}}</p>
        <div v-if="detailData.vehiclePartInfo.partPics" class="flex-start imgsBox">
          <img v-for="(val,i) in detailData.vehiclePartInfo.partPics" :key="i" :src="val" alt="">
        </div>
      </div>

      <div class="colBox3">
        <p class="t1">车辆信息</p>
        <div class="flex-start col5Box">
          <img :src="detailData.vehicleInfo.seriesPic" alt="">
          <div class="col5Box-r">
            <p>{{detailData.vehicleInfo.modelName||'-'}}</p>
            <p>VIN码：{{detailData.vehicleInfo.vin||'-'}}</p>
          </div>
        </div>
      </div>

      <div v-if="detailData.workOrderInfo.orderStatus==2" 
      @click="to_page('/TestReport?reportSn='+detailData.workOrderInfo.reportSn)" class="colBox3">
        <p class="t1">检测报告</p>
        <div class="flex-sb col6Box">
          <p>检测报告{{detailData.workOrderInfo.reportSn}}</p>
          <div class="flex-start">
            <span>查看报告</span>
            <van-icon name="arrow" color="#5E5E5E" size="16" />
          </div>
        </div>
      </div>

      <div class="colBox3">
        <p class="t1">检测轨迹</p>
        <van-steps direction="vertical" :active="0" active-color="#042550">
          <van-step v-for="(item,i) in detailData.workOrderTrajectory" :key="i">
            <h3 class="step-h3">{{item.createTime}} 操作员：{{item.operatorName}}</h3>
            <p class="step-p">{{item.trackDesc}}</p>
          </van-step>
        </van-steps>
      </div>

    </div>
  
    
    <div class="bottom-box flex-sb">
      <template v-if="detailData.workOrderInfo.orderStatus !=3">
        <van-popover v-model="showPopover" trigger="click" placement="top-start" :offset="[0,14]">
          <div>
            <p @click="printBtn" class="popover-t1">打印回用件标签</p>
            <p v-if="
            (detailData.workOrderInfo.orderStatus===2 && detailData.workOrderInfo.recheckFlag===0 && detailData.workOrderInfo.repairStatus ===2) || 
            (detailData.workOrderInfo.orderStatus===2 && detailData.workOrderInfo.recheckFlag===1)" @click="printBtn2" class="popover-t1">打印溯源标签</p>
            <template v-if="detailData.workOrderInfo.orderStatus ==0 || detailData.workOrderInfo.orderStatus ==1">
              <p @click="dialogShow = true;" class="popover-t2">关闭工单</p>  
            </template>
            <p v-if="detailData.workOrderInfo.rfidMust !=3" @click="bindingRfid" class="popover-t1">绑定RFID</p>
          </div>
          <template #reference>
            <span class="btn1">更多</span>
          </template>
        </van-popover>
        <van-button @click="go_testing" v-if="detailData.workOrderInfo.orderStatus ===0" square type="primary" class="btn2">去检测</van-button>
        <van-button @click="to_page('/detectTicket?orderSn='+detailData.workOrderInfo.orderSn)" v-if="detailData.workOrderInfo.orderStatus ===1" square type="primary" class="btn2">继续检测</van-button>
        <van-button @click="dialogShow2 = true" v-if="detailData.workOrderInfo.inboundOutboundStatus ===1 && $route.query.tabName!=='检测工单详情'" square type="primary" class="btn2">出库</van-button>
        <van-button @click="zhengbeiYesBtn" v-if="detailData.workOrderInfo.repairStatus ===0" square type="primary" class="btn4">整备完成</van-button>
        
        <template v-if="detailData.workOrderInfo.orderStatus ===2">
          <van-button v-if="detailData.workOrderInfo.recheckFlag !==1 && detailData.workOrderInfo.repairStatus === null" @click="zhengbeiBtn" plain class="btn3">整备</van-button>
          <van-button v-if=" detailData.workOrderInfo.inboundOutboundStatus === 0" @click="rukuBtn" square type="primary" class="btn4">入库</van-button>    
        </template>    
      </template>
      <van-button v-else type="info" @click="$router.go(-1)" plain color="#042550" style="width: 100%;">返回</van-button>
    </div>

    <van-dialog v-model="dialogShow" title="确认关闭工单" :show-cancel-button="false" :showConfirmButton="false">
      <div class="flex-col-center">
        <van-field v-model="closeDesc" required class="inp-box" placeholder="请备注关闭工单原因"></van-field>
        <div class="flex-sb">
          <van-button class="btn5" @click="dialogShow = false;" type="default">取消</van-button>
          <van-button native-type="submit" @click="closeBtn" square type="primary" class="btn4">确认</van-button>
        </div>
      </div>
    </van-dialog>

    <van-dialog v-model="dialogShow2" title="确认使用" :show-cancel-button="false" :showConfirmButton="false">
      <div class="flex-col-center">
        <van-field v-model="closeDesc2" required class="inp-box" placeholder="请备注使用机构以及用途"></van-field>
        <div class="flex-sb margin-b-20">
          <van-button class="btn5" @click="dialogShow2 = false;" type="default">取消</van-button>
          <van-button native-type="submit" @click="shiyongBtn" square type="primary" class="btn4">确认</van-button>
        </div>
      </div>
    </van-dialog>

  </div>
</template>
<script>
  // @ is an alias to /src
  import { Field,Steps,Button,Step,Popover,Dialog,Icon} from 'vant'
  import { getvehiclePartDetail,setClose,getCodeDetailItem,setDetecting,setRepair,changeInboundOrOutbound,getRecheckOrder,partLabelPrintFlag } from "@/api";
  export default {
    name: 'Home',
    components: {
      [Field.name]: Field,
      [Steps.name]: Steps,
      [Button.name]: Button,
      [Step.name]: Step,
      [Popover.name]: Popover,
      [Dialog.name]: Dialog,
      [Dialog.Component.name]: Dialog.Component,
      [Icon.name]: Icon,
    },
    data() {
      return {
        orderStatusObj:{
          '0':'待检测',
          '1':'检测中',
          '2':'检测完成',
          '3':'已关闭',
        },
        dialogShow:false,
        dialogShow2:false,
        showPopover:false,
        closeDesc:'',
        closeDesc2:'',
        detailData:{
          workOrderInfo:{},
          vehicleInfo:{},
          vehiclePartInfo:{},
          workOrderTrajectory:[],
        },
      }
    },
    created () {
      if(this.$route.query.tabName){
        this.$navBar.setTitle(this.$route.query.tabName)
      }else{
        this.$navBar.setTitle('检测工单详情')
      }
      
      this.$tabBar.setShow(false);
      this.getDetail();
      // Dialog.alert({
      //   title: '确认整备',
      //   message: '请确认检测已完成并开始整备',
      //   showCancelButton:true,
      // }).then(() => {
      //   console.log(1);
      //   // on confirm
      // })
      // .catch(() => {
      //   console.log(0);
      //   // on cancel
      // });;
    },
    methods: {
      bindingRfid(){
        this.$store.state.app.rfidPartCode = this.detailData.vehiclePartInfo.partCode;
        this.$store.state.app.isShowRfidMask = true;
        window.requestRFIDDevice();
      },
      zhengbeiYesBtn(){
        const toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '保存中',
        });
        setRepair({
          "orderSn": this.detailData.workOrderInfo.orderSn,
          "repairStatus": 1
        }).then(res=>{
          if(res.code == '00000' && res.data){
            getRecheckOrder({
              "orderSn": this.detailData.workOrderInfo.orderSn,
            }).then(res2=>{
              toast.clear();
              if(res2.code == '00000'){
                this.$toast('复检工单创建成功');
                setTimeout(()=>{
                  localStorage.setItem('innerType','1');
                  this.$router.push('/inner?orderSn='+res2.data.orderSn);
                },1000)
              }else{
                this.$toast('创建复检工单失败');
              }
            })
          }else{
            toast.clear();
            this.$toast('保存失败');
          }
        })
      },
      shiyongBtn(){
        if(!this.closeDesc2){
          this.$toast('请备注使用机构以及用途');
          return ;
        }
        changeInboundOrOutbound({
          orderSn:this.detailData.workOrderInfo.orderSn,
          inboundOutboundStatus:2, //出入库状态（0：待入库，1：已入库，2：已出库）
          outboundRemark:this.closeDesc2,
        }).then(res=>{
          if(res.code == '00000' && res.data){
            this.$toast('操作成功');
            this.dialogShow2 = false;
            this.getDetail();
          }
        })
      },
      rukuBtn(){
        Dialog.alert({
          title: '确认入库',
          message: '请确认检测已完成并进行入库',
          showCancelButton:true,
        }).then(() => {
          changeInboundOrOutbound({
            "orderSn": this.detailData.workOrderInfo.orderSn,
            "inboundOutboundStatus": 1
          }).then(res=>{
            if(res.code == '00000'){
              this.$toast('操作成功');
              this.getDetail();
            }
          })
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
      },
      zhengbeiBtn(){
        Dialog.alert({
          title: '确认整备',
          message: '请确认检测已完成并是否整备',
          confirmButtonText:'整备',
          cancelButtonText:'不整备',
          showCancelButton:true,
        }).then(() => {
          setRepair({
            "orderSn": this.detailData.workOrderInfo.orderSn,
            "repairStatus": 0
          }).then(res=>{
            if(res.code == '00000'){
              this.$toast('操作成功');
              this.getDetail();
              // this.to_page('/detectTicket?orderSn='+this.detailData.workOrderInfo.orderSn)
            }
          })
          // on confirm
        })
        .catch(() => {
          // on cancel
          setRepair({
            "orderSn": this.detailData.workOrderInfo.orderSn,
            "repairStatus": 2
          }).then(res=>{
            if(res.code == '00000'){
              this.$toast('操作成功');
              this.getDetail();
              this.printBtn2();
            }
          })
        });
      },
      go_testing(){
        setDetecting({
          "orderSn": this.detailData.workOrderInfo.orderSn,
          "partLabelPrintFlag": this.detailData.vehiclePartInfo.partLabelPrintFlag?1:0
        }).then(res=>{
          if(res.code == '00000' && res.data){
            this.to_page('/detectTicket?orderSn='+this.detailData.workOrderInfo.orderSn)
          }
        })
      },
      async printBtn(){
        // localStorage.setItem('innerType','1');
        // this.$router.push('/inner?orderSn=VD172817881899529700939');
        // return ;
        let status = await window.setPrintDevice(this.detailData.vehiclePartInfo.partId); // 打印回用件标签
        if(status ===1){
          partLabelPrintFlag({orderSn:this.detailData.workOrderInfo.orderSn,partLabelType:'1'});
        }
      },
      async printBtn2(){
        let status = await window.setPrintDevice(this.detailData.vehiclePartInfo.partId,1); // 打印溯源标签
        if(status ===1){
          partLabelPrintFlag({orderSn:this.detailData.workOrderInfo.orderSn,partLabelType:'2'});
        }
      },
      closeBtn(){
        if(this.closeDesc){
          setClose({
            closeReason:this.closeDesc,
            orderSn:this.detailData.workOrderInfo.orderSn
          }).then(res=>{
            if(res.code == '00000' && res.data){
              this.$toast("关闭成功");
              this.dialogShow = false;
              setTimeout(()=>{
                this.$router.push('/home')
              },1500)
            }
          })
        }else{
          this.$toast('原因不能为空')
        }
        
      },
      getDetail(){
        const toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '获取中',
        });
        if(this.$route.query.partCode){ // 扫一扫code码获取详情
          getCodeDetailItem(this.$route.query.partCode).then(res=>{
            toast.clear();
            if(res.code == '00000'){
              this.detailData = res.data;
            }
          })
        }else{
          getvehiclePartDetail(this.$route.query.orderSn).then(res=>{
            toast.clear();
            if(res.code == '00000'){
              this.detailData = res.data;
            }
          })
        }
      },
      to_page(url){
        this.$router.push(url)
      },
      onSelect(action) {
        console.log(action);
      },
    },
  }
</script>
<style lang="less" scoped>
  .margin-b-20{
    margin-bottom: 20px;
  }
  .btn5{
    width: 251px;
    margin: 15px;
    background: #F3F3F3;
  }
  .btn4{
    width: 230px;
    margin: 0 15px;
    background: #042550;
    border-radius: 12px;
    border: 2px solid #042550;
  }
  .col5Box-r{
    p{
      font-size: 28px;
      line-height: 32px;
      color: #383838;
      line-height: 40px;
      padding: 10px 0;
    }
  }
  .inp-box{
    background: #F3F3F3;
    width: 526px;
    margin: 24px 0;
  }
  .col6Box{
    margin-top: 24px;
    height: 54px;
    p{
      font-size: 26px;
      line-height: 26px;
      color: #5E5E5E;
    }
    span{
      font-size: 26px;
      line-height: 26px;
      color: #042550;
    }
  }
  .popover-t1{
    font-size: 32px;
    line-height: 48px;
    color: rgba(0, 0, 0, 0.9);
    padding: 24px 32px;
    width: 375px;
  }
  .popover-t2{
    width: 375px;
    font-size: 32px;
    line-height: 48px;
    color: #D54941;
    padding: 24px 32px;
  }
  .step-p{
    font-size: 28px;
    line-height: 40px;
    color: #1B1C33;
  }
  .step-h3{
    font-size: 28px;
    line-height: 40px;
    font-weight: 400;
    color: #8D8E99;
  }
  .col5Box{
    margin-top: 24px;
    img{
      width: 160px;
      height: 120px;
      margin-right: 16px;
    }
    p{
      font-size: 28px;
      line-height: 32px;
      color: #383838;
    }
  }
  .imgsBox{
    overflow-x: scroll;
    height: 120px;
    img{
      width: 160px;
      height: 120px;
      margin-right: 16px;
    }
  }
  .colBox3{
    padding: 24px;
    margin-top: 24px;
    border-radius: 12px;
    background: #FFFFFF;
    .t1{
      font-size: 32px;
      line-height: 40px;
      font-weight: 900;
      color: #181818;
      padding-bottom: 12px;
    }
    .t2{
      font-size: 28px;
      line-height: 32px;
      color: #383838;
      padding: 12px 0;
    }
    .t3C{
      margin-top: 12px;
      padding: 12px;
      background: #F3F3F3;
      p{
        font-size: 28px;
        line-height: 32px;
        padding: 12px 0;
        color: #383838;
      }
    }
  }
  .colBox2{
    border-radius: 12px;
    padding: 26px 32px;
    background: #FFFFFF;
    margin-top: 44px;
    .colBox2-l{
      img{
        width: 48px;
        height: 48px;
        margin-right: 24px;
      }
      .t1{
        font-size: 24px;
        line-height: 24px;
        color: #5E5E5E;
      }
      .t2{
        margin-top: 16px;
        font-size: 28px;
        line-height: 28px;
        color: #242424;
      }
    }
  }
  .margin-t-24{
    margin-top: 24px;
  }
  .topBox{
    padding: 24px;
    .topT1{
      p{
        font-size: 44px;
        font-weight: 600;
        line-height: 44px;
        color: #FFFFFF;
        margin-right: 24px;
      }
      span{
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #0052D9;
        padding: 12px 14px;
        background: #F2F3FF;
        border-radius: 6px;
        margin-right: 12px;
      }
      .c_rr{
        color: #D54941;
        background: #FFF0ED;
      }
    }
    .topT2{
      font-size: 24px;
      line-height: 24px;
      color: #FFFFFF;
      padding: 12px 0;
    }
  }
 
.ticketBox {
  height: 100%;
  background-color: #EDEDEF;
  background-image: url(https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/detailTicketTopBg.png);
  background-size: 100% 640px;
  background-repeat: no-repeat;
  padding-bottom: 200px;
}
.bottom-box{
  position: fixed;
  bottom: 0px;
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  background: #FFFFFF;
  box-shadow: 0px -8px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 16px 24px;
  z-index: 1;
  padding-bottom: 40px;
  .btn1{
    display: inline-block;
    padding: 28px 28px;
    color: #242424;
    font-size: 32px;
  }
  .btn2,.btn4{
    width: 566px;
    background: #042550;
    border-radius: 12px;
    border: 2px solid #042550;
  }
  .btn3{
    color: #000000;
    width: 275px;
    border: 2px solid #042550;
    border-radius: 12px;
  }
  .btn4{
    width: 245px;
  }
}
</style>
