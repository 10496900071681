<!--
 * @Author: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @Date: 2024-07-16 14:18:10
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-10-18 09:40:00
 * @FilePath: \vue2-rem\src\views\Home.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home">
    <van-tabs v-model="tabsIndex" title-active-color="#042550" class="work-tabs-box" @change="tabsChange">
      <van-tab v-for="(item,i) in tabsList" :title="item.title" :key="i"></van-tab>
    </van-tabs>
    <van-dropdown-menu active-color="#042550" class="van-hairline--top">
      <van-dropdown-item :title="typeName" v-model="typeId" :options="vehiclePartOption" @change="initList"/>
      <van-dropdown-item v-if="tabsIndex ===0" title="入库时间" ref="dateTime1">
        <van-datetime-picker
          v-model="inboundTimeBegin"
          type="date"
          title="选择入库时间"
          cancel-button-text="重置"	
          :min-date="minDate"
          :max-date="maxDate"
          @cancel="closeTime"
          @confirm="changeShowTime1"
        />
      </van-dropdown-item>
      <van-dropdown-item v-if="tabsIndex ===1" title="出库时间" ref="dateTime2">
        <van-datetime-picker
          v-model="outboundTimeBegin"
          type="date"
          title="选择出库时间"
          cancel-button-text="重置"	
          :min-date="minDate"
          :max-date="maxDate"
          @cancel="closeTime2"
          @confirm="changeShowTime2"
        />
      </van-dropdown-item>
    </van-dropdown-menu>
    <van-pull-refresh v-model="isRefreshLoading" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-if="isSgowList"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getList"
        class="content-box"
      >
      <div @click="to_page('/detailTicket?tabName=回用件详情&orderSn='+val.orderSn)" class="col-item" v-for="(val,n) in dataList" :key="n">
        <div class="flex-sb">
          <p class="t1">[{{val.typeName}}] {{val.partName}}</p>
          <div class="flex-start">
            <p v-if="val.orderStatus==1" class="t2 t2-succ">{{orderStatusObj[val.orderStatus]}}</p>
            <p v-else-if="val.orderStatus==2" class="t2 t2-blue">{{orderStatusObj[val.orderStatus]}}</p>
            <p v-else-if="val.orderStatus==3" class="t2 t2-close">{{orderStatusObj[val.orderStatus]}}</p>
            <p v-else class="t2">{{orderStatusObj[val.orderStatus]}}</p>
            <p v-if="val.inboundOutboundStatus==1" class="t2 t2-blue">已入库</p>
            <p v-if="val.inboundOutboundStatus==2" class="t2 t2-blue">已出库</p>
          </div>
        </div>
        <div class="flex-start col-item-i-box">
          <img class="img1" :src="val.partPics[0]" alt="">
          <div>
            <p class="t3">回用件编码：{{val.partCode}}</p>
            <p class="t3">工单编码：{{val.orderSn}}</p>
            <p class="t3">工单时间：{{val.createTime}}</p>
          </div>
        </div>
        <div class="col-item-i-box2">
          <p>检测机构：{{val.detectMechanismName}}</p>
          <p>检测人员：{{val.inspectorName}}</p>
        </div>
        <div class="flex-end">
          <!-- <van-button @click.stop="printBtn(val.partId)" class="col-btn" plain>打印标签</van-button>       -->
          <van-button @click.stop="to_page('/TestReport?reportSn='+val.reportSn)" class="col-btn" plain>检测报告</van-button>      
          <van-button @click.stop="showDialogBtn(val.orderSn)" v-if="tabsIndex ===0 && val.inboundOutboundStatus !=2" class="col-btn" plain>出库</van-button>      
        </div>
      </div>
      </van-list>
    </van-pull-refresh>
    

    <div class="bottom-box flex-sb">
      <van-button @click="sweepClick" class="btn2" plain>扫一扫</van-button>
    </div>

    <van-dialog v-model="dialogShow" title="确认使用" :show-cancel-button="false" :showConfirmButton="false">
      <div class="flex-col-center">
        <van-field v-model="closeDesc" required class="inp-box" placeholder="请备注使用机构以及用途"></van-field>
        <div class="flex-sb margin-b-20">
          <van-button class="btn5" @click="dialogShow = false;" type="default">取消</van-button>
          <van-button native-type="submit" @click="shiyongBtn" square type="primary" class="btn4">确认</van-button>
        </div>
      </div>
    </van-dialog>

  </div>
</template>

<script>
// @ is an alias to /src
import { Tabs,Tab,Button,List,DropdownMenu, DropdownItem,Dialog,Field,DatetimePicker,PullRefresh } from 'vant'
import { getVehiclePartTypeList,vehiclePartOrderList,changeInboundOrOutbound } from '@/api'

export default {
  name: 'Home',
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Button.name]: Button,
    [List.name]: List,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
    [DatetimePicker.name]: DatetimePicker,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      orderStatusObj:{
        '0':'待检测',
        '1':'检测中',
        '2':'检测完成',
        '3':'已关闭',
      },
      isRefreshLoading:false,
      tabsIndex:0,
      typeId:'',
      minDate: new Date(),
      maxDate: new Date(),
      inboundTimeBegin: '',
      inboundTimeEnd: new Date(),
      outboundTimeBegin: '',
      outboundTimeEnd: new Date(),
      loading: false,
      finished: false,
      dialogShow:false,
      closeDesc:'',
      typeName:'回用件类型',
      tabsList:[
        {
          title:'已入库',
          id:'',
        },
        {
          title:'已出库',
          id:'1',
        },
      ],
      vehiclePartOption:[],
      dataList:[],
      pageNum:0,
      isSgowList:true,
    }
  },
  created () {
    this.$navBar.setTitle('回用件管理')
    this.$tabBar.setShow(false);
    this.minDate = new Date(new Date().getTime()-2*30*24*60*60*1000);
    this.inboundTimeBegin ='';
    this.outboundTimeBegin = '';
    getVehiclePartTypeList().then(res=>{
      if(res.code == '00000'){
        let arr = [{
          text:'默认',
          value:'',
        }];
        res.data.forEach(item => {
          arr.push({
            text:item.name,
            value:item.id,
          })
        });
        this.vehiclePartOption = arr;
      }
    })
  },
  methods: {
    timestampToDateTime(date) {
      var year = date.getFullYear(); // 获取年份
      var month = ("0" + (date.getMonth() + 1)).slice(-2); // 获取月份，转为两位数
      var day = ("0" + date.getDate()).slice(-2); // 获取日，转为两位数
      var hours = ("0" + date.getHours()).slice(-2); // 获取小时，转为两位数
      var minutes = ("0" + date.getMinutes()).slice(-2); // 获取分钟，转为两位数
      var seconds = ("0" + date.getSeconds()).slice(-2); // 获取秒钟，转为两位数
      // 拼接字符串
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    onRefresh(){
      this.dataList =[];
      this.initList();
    },
    changeShowTime1() {
      this.$refs.dateTime1.toggle();
      this.initList();
    },
    closeTime(){
      this.inboundTimeBegin = '';
      this.changeShowTime1();
    },
    changeShowTime2() {
      this.$refs.dateTime2.toggle();
      this.initList();
    },
    closeTime2(){
      this.outboundTimeBegin = '';
      this.changeShowTime2();
    },
    shiyongBtn(){
      if(!this.closeDesc){
        this.$toast('请备注使用机构以及用途');
        return ;
      }
      changeInboundOrOutbound({
        orderSn:this.vmClickOrderSn,
        inboundOutboundStatus:2, //出入库状态（0：待入库，1：已入库，2：已出库）
        outboundRemark:this.closeDesc,
      }).then(res=>{
        if(res.code == '00000' && res.data){
          this.$toast('操作成功');
          this.dialogShow = false;
        }
      })
    },
    showDialogBtn(orderSn){
      this.vmClickOrderSn = orderSn;
      this.dialogShow = true;
    },
    async printBtn(partId){
      let status = await window.setPrintDevice(partId); // 打印回用件标签
      if(status ===1){
        
      }
    },
    to_page(url){
      this.$router.push(url);
    },
    sweepClick(){
      window.h5ScanCode();
    },
    tabsChange(e,ag2){
      this.dataList=[];
      this.pageNum=0;
      this.finished = false;
      this.isSgowList = false;
      setTimeout(()=>{
        this.isSgowList = true;
      },100)
    },
    getList(){
      this.pageNum++;
      let parmas = {
        "pageNum": this.pageNum,
        "pageSize": 10,
        typeId:this.typeId,
        inboundOutboundStatus:this.tabsIndex===0?'1':'2',
      }
      if(this.tabsIndex ==0 && this.inboundTimeBegin){
        parmas.inboundTimeBegin = this.timestampToDateTime(this.inboundTimeBegin);
        parmas.inboundTimeEnd = this.timestampToDateTime(new Date());
      }
      if(this.tabsIndex ==1 && this.outboundTimeBegin){
        parmas.outboundTimeBegin = this.timestampToDateTime(this.outboundTimeBegin);
        parmas.outboundTimeEnd = this.timestampToDateTime(new Date());
      }
      vehiclePartOrderList(parmas).then(res=>{
        this.loading = false;
        this.isRefreshLoading = false;
        if(res.code == '00000' && res.data){
          if(res.data.list.length<10){
            this.finished = true;
          }
          res.data.list.forEach(item=>{
            if(item.partPics){
              item.partPics = JSON.parse(item.partPics)
            }else{
              item.partPics = []
            }
          })
          if(this.pageNum ===1) this.dataList =[];
          this.dataList = [...this.dataList,...res.data.list]
        }
      })
    },
    initList(){
      this.pageNum=0;
      this.finished = false;
      this.dataList =[];
      this.vehiclePartOption.forEach(item=>{
        if(this.typeId == item.value){
          this.typeName = item.text == '默认'?'回用件类型':item.text;
        }
      })
      this.getList();
    },
  },
}
</script>
<style>
  .work-tabs-box .van-tabs__line{
    background-color: #042550;
  }
  .work-tabs-box .van-tabs__wrap{
    position: sticky;
    top: 92px;
    z-index: 2;
  }
  #app .base-layout{
    height: min-content;
  }
</style>
<style lang="less" scoped>
@import '~@/styles/mixins.less';
.margin-b-20{
  margin-bottom: 20px;
}
.btn5{
  width: 251px;
  margin: 15px;
  background: #F3F3F3;
}
.btn4{
  width: 251px;
  margin: 15px;
  background: #042550;
  border-radius: 12px;
  border: 2px solid #042550;
}
.mask-t1{
  font-size: 32px;
  line-height: 48px;
  color: rgba(0, 0, 0, 0.6);
  padding: 24px 0;
}
.t2-succ{
  color: #2BA471;
  background: #E3F9E9;
}
.t2-blue{
  color: #0052D9;
  background: #F2F3FF;
}
.t2-close{
  color: rgba(0, 0, 0, 0.9);
  background: #F3F3F3;
}
.content-box{
  padding: 24px;
  box-sizing: border-box;
  padding-bottom: 140px;
}
.col-item{
  box-sizing: border-box;
  border-radius: 12px;
  background: #FFFFFF;
  padding: 24px;
  margin-bottom: 24px;
  &:nth-last-child(1){
    margin-bottom: 150px;
  }
  .col-item-i-box{
    padding: 16px 0;
  }
  .t1{
    font-size: 32px;
    line-height: 40px;
    color: #181818;
  }
  .t2{
    margin-left: 16px;
    font-size: 20px;
    line-height: 32px;
    color: #E37318;
    padding: 4px 12px;
    background: #FFF1E9;
    border-radius: 6px;
  }
  .img1{
    width: 160px;
    height: 120px;
    margin-right: 16px;
  }
  .t3{
    font-size: 26px;
    line-height: 26px;
    color: #9E9E9E;
    padding: 8px 0;
  }
  .col-item-i-box2{
    background: #F7F8F9;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 16px;
    p{
      font-size: 24px;
      line-height: 24px;
      color: #9E9E9E;
      padding: 8px 0;
    }
  }
}
.col-btn{
  color: #000000;
  padding: 0 14px;
  height: 56px;
  border: 2px solid #042550;
  border-radius: 12px;
  margin-left: 16px;
}
.bottom-box{
  position: fixed;
  bottom: 0px;
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  background: #FFFFFF;
  box-shadow: 0px -8px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 16px 24px;
  padding-bottom: 40px;
  .btn2{
    width: 702px;
    background: #042550;
    border-radius: 12px;
    color: white;
    border: 2px solid #042550;
  }
}

.home {
  height: 100%;
  background: #F2F3F5;
}
.inp-box{
  background: #F3F3F3;
  width: 526px;
  margin: 24px 0;
}

</style>
