<!--
 * @Author: “lihui” 13723683480@163.com
 * @Date: 2024-07-26 14:53:52
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-10-18 11:52:56
 * @FilePath: \an-h5\src\views\AddTicket.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="ticketBox">
    <div class="topBox">
      <div class="flex-start topT1">
        <p>检测报告</p>
      </div>
      <div class="flex-sb">
        <div>
          <p class="topT2 margin-t-24">[{{detailData.vehiclePartInfo.typeName}}]{{detailData.vehiclePartInfo.partName}}</p>
          <div class="flex-sb">
            <p class="topT2">回用件编号：{{detailData.vehiclePartInfo.partCode}}</p>
          </div>
        </div>
        <img class="img-box1" :src="detailData.vehiclePartInfo.partPics[0]" alt="">
      </div>

      <div class="flex-sb colBox2">
        <div class="colBox2Content">
          <div class="flex-sb colBox2ContentT1">
            <img src="https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/testReportImg2.png" alt="">
            <span>NO：{{detailData.reportSn}}</span>
          </div>
          <div class="flex-col-center colBox2ContentT2">
            <p class="t1">安信查回用件溯源检测</p>
            <img src="https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/testReportImg1.png" alt="">
            <p class="t2">{{detailData.resultLevel||'-'}}级</p>
            <p class="t3">{{resultLevelObj[detailData.resultLevel]}}</p>
          </div>
        </div>
      </div>

      <div class="colBox3">
        <p class="t1">回用件信息</p>
        <p class="t2">回用件名称：[{{detailData.vehiclePartInfo.typeName}}]&nbsp;&nbsp;{{detailData.vehiclePartInfo.partName}}</p>
        <p class="t2">回用件编码：{{detailData.vehiclePartInfo.partCode}}</p>
        <div class="flex-start col5Box">
          <img v-for="(val,i) in detailData.vehiclePartInfo.partPics" :key="i" :src="val" alt="">
        </div>
      </div>

      <div class="colBox3">
        <p class="t1">车辆信息</p>
        <div class="flex-start col5Box">
          <img :src="detailData.vehicleInfo.seriesPic" alt="">
          <div class="col5Box-r">
            <p>{{detailData.vehicleInfo.modelName}}</p>
            <p>VIN码：{{detailData.vehicleInfo.vin||'-'}}</p>
          </div>
        </div>
      </div>

      <div class="colBox3">
        <p class="t1">基本信息</p>
        <p class="t2">检测机构：{{detailData.detectMechanismName||'-'}}</p>
        <p class="t2">检测人员：{{detailData.inspectorName}}</p>
        <p class="t2">检测日期：{{detailData.detectTime}}</p>
      </div>

      <template v-for="(item,i) in detailData.detectDetail">
        <div v-if="item.typeName !='漆膜检测'" class="colBox4">
          <p class="t1">{{item.typeName}}</p>
          <div class="flex-start t2box">
            <template v-if="item.passCount>0">
              <van-icon name="checked" size="16" color="#2BA471" />
              <p>检测通过 {{item.passCount}}</p>    
            </template>
            <template v-if="item.defectCount>0">
              <img src="https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/testReportImg3.png" alt="">
              <p>检测缺陷 {{item.defectCount}}</p>
            </template>
            <template v-if="item.repairCount>0">
              <img src="https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/testReportImg4.png" alt="">
              <p>已修复 {{item.repairCount}}</p>  
            </template>
          </div>
          <div v-for="(val,n) in item.detectItems" :key="n" class="flex-sb t3box">
            <span>{{val.itemName}}</span>
            <div class="flex-start" @click="imgClick(val)">
              <van-icon v-if="val.defectFlag === 0" name="checked" size="16" color="#2BA471" />
              <template v-else-if="val.defectFlag === 1">
                <span class="ellipsis" style="margin-right: 10px;">{{val.rightText}}</span>
                <img src="https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/testReportImg3.png" alt="">    
              </template>
              <template v-else-if="val.defectFlag === 2">
                <span style="margin-right: 10px;">已修复</span>
                <img src="https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/testReportImg4.png" alt="">    
              </template>
            </div>
          </div>
        </div>
        <div v-else class="colBox4">
          <p class="t1">{{item.typeName}}</p>
          <div class="flex-sb t3box">
            <span>漆膜数据最高值</span>
            <span>{{item.maxValue}}</span>
          </div>
          <div class="flex-sb t3box">
            <span>漆膜数据最低值</span>
            <span>{{item.minValue}}</span>
          </div>
          <div v-for="(val,n) in item.detectItems[0].defectList" v-if="val.detectValue" :key="n" class="t4box">
            <div class="t4box-c">
              <div class="flex-sb t3box">
                <span>漆膜数据</span>
                <span>{{val.detectValue||'-'}}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
     

      

      <div class="colBox4">
        <p class="t1">免责声明</p>
        <p class="mianze">
          <b class="t2">一、服务性质说明</b><br/>
          本公司所提供的服务为回用件检测工具服务，即我们将向您提供专业的检测工具以及相应的操作指导，以协助您对回用件进行检测。我们的服务仅限于此，并不对回用件本身的质量、性能、安全性或适用性提供任何形式的保障。
        </p>
        <p class="mianze">
          <b class="t2">二、检测结果的局限性</b><br/>
          1. 虽然我们提供的检测工具经过专业设计和校准，但检测结果可能受到多种因素的影响，包括但不限于回用件的原始状况、检测环境、操作过程中的人为因素等。因此，检测结果仅作为您评估回用件的参考依据，不能被视为绝对准确或具有决定性意义的结论。<br/>
          2. 本公司不对因检测结果的误差或不准确而导致的任何直接或间接损失承担责任，包括但不限于经济损失、业务中断、人身伤害等。
        </p>
        <p class="mianze">
          <b class="t2">三、客户责任</b><br/>
          1. 客户在使用我们的检测工具服务时，应严格按照本公司提供的操作指南进行操作。如因客户未按照操作指南操作而导致的任何问题，包括但不限于检测工具的损坏、回用件的损坏、人员伤亡等，本公司不承担任何责任。<br/>
          2. 客户应自行承担对回用件进行检测所产生的一切风险，包括但不限于检测过程中可能发现的回用件存在的潜在缺陷、安全隐患等。本公司不对客户基于检测结果所做出的任何决策（如回用件的使用、修复、废弃等决策）承担任何责任。
        </p>
        <p class="mianze">
          <b class="t2">四、不承担后续责任</b><br/>
          1. 在完成检测工具服务后，本公司对回用件的后续使用、存储、运输等环节不承担任何责任。无论回用件在后续过程中发生何种问题，包括但不限于质量问题、性能下降、引发安全事故等，均与本公司无关。<br/>
          2. 客户明确知晓并同意，本公司不会因回用件在任何情况下（包括但不限于在检测过程中未被检测出的潜在问题、在检测完成后的使用过程中等）对客户或任何第三方造成的损害而承担任何法律责任，包括但不限于赔偿责任、维修责任、恢复原状责任等。
        </p>
        <p class="mianze">
          <b class="t2">五、适用法律与争议解决</b><br/>
          1. 本免责声明受法律管辖。<br/>
          2. 如因本免责声明或与本公司的回用件检测工具服务相关的任何事项产生争议，双方应首先通过友好协商解决；协商不成的，应提交至天津市东丽区的有管辖权的人民法院进行诉讼解决。
        </p>
      </div>

    </div>

    <ImagePreview ref="previewImg"></ImagePreview>
  </div>
</template>
<script>
  // @ is an alias to /src
  import { Field,Steps,Button,Step,Popover,Dialog,Icon,Overlay} from 'vant'
  import ImagePreview from "@/components/ImagePreview"
  import { getReportDetail } from '@/api';
  export default {
    name: 'Home',
    components: {
      [Field.name]: Field,
      [Steps.name]: Steps,
      [Button.name]: Button,
      [Step.name]: Step,
      [Popover.name]: Popover,
      [Dialog.name]: Dialog,
      [Dialog.Component.name]: Dialog.Component,
      [Icon.name]: Icon,
      [Overlay.name]: Overlay,
      ImagePreview
    },
    data() {
      return {
        resultLevelObj:{
          1:'外观形态良好，结构完整，具有良好的使用功能和性能，不宜存在维修历史；',
          2:'结构完整，具有完整的使用功能和性能，除外观件可视表面外其余表面可存在不影响使用性能的划伤、轻微变形等，不应存在维修历史；',
          3:'结构完整，具有完整的使用功能和性能，表面可存在有一定程度的划伤、变形等，可有部分维修痕迹；',
        },
        dialogShow:false,
        showPopover:false,
        printShow:false,
        closeDesc:'',
        detailData:{
          vehiclePartInfo:{
            partPics:[],
          },
          vehicleInfo:{},
          detectDetail:[],
        },
      }
    },
    created () {
      this.$navBar.setTitle('检测报告')
      this.$tabBar.setShow(false);
      this.getDetail();
      // Dialog.alert({
      //   title: '确认整备',
      //   message: '请确认检测已完成并开始整备',
      //   showCancelButton:true,
      // }).then(() => {
      //   console.log(1);
      //   // on confirm
      // })
      // .catch(() => {
      //   console.log(0);
      //   // on cancel
      // });;
    },
    methods: {
      getDetail(){
        getReportDetail(this.$route.query.reportSn).then(res=>{
          if(res.code =='00000'){
            res.data.detectDetail.forEach(item => {
              if(item.typeName == '漆膜检测'){
                item.maxValue = 0;
                item.minValue = 0;
                item.detectItems.forEach(val=>{
                  val.defectList.forEach(val2=>{
                    if(val2.detectValue){
                      if(Number(val2.detectValue)>item.maxValue){
                        item.maxValue = val2.detectValue;
                      }
                      if(Number(val2.detectValue) < item.minValue || item.minValue===0){
                        item.minValue = val2.detectValue;
                      }  
                    }
                  })
                })
              }else{
                item.detectItems.forEach(val=>{
                  let rightText = [],rightImgList=[];
                  if(val.defectFlag === 1){
                    val.defectList.forEach(val2=>{
                      if (!rightText.includes(val2.defectName)) {
                        rightText.push(val2.defectName)
                      }
                      if(val2.repairFlag ===1 && val2.repairPhotoList && val2.repairPhotoList.length>0){
                        val.defectFlag =2;
                      }
                      if(val2.defectPhotos && val2.defectPhotos.length>0){
                        val2.defectPhotos.forEach(val3=>{
                          rightImgList.push({
                            repairFlag:0,
                            src:val3,
                            name:val2.defectName,
                            isRepairFlag:val2.repairFlag, // 是否有修复的数据，有则图片中展示修复前修复后标识
                          })
                        })
                      }
                      if(val2.repairPhotoList && val2.repairPhotoList.length>0){
                        val2.repairPhotoList.forEach(val3=>{
                          rightImgList.push({
                            repairFlag:1,
                            src:val3,
                            name:val2.defectName,
                          })
                        })
                      }
                    })
                    val.rightText = rightText.join('、');
                    val.rightImgList = rightImgList;
                  }
                })
              }
            });
            this.detailData = res.data;
          }
        })
      },
      imgClick(item){
        console.log(item.rightImgList);
        console.log(this.$refs['previewImg']);
        this.$refs['previewImg'].setImgsList(item.rightImgList);
      },
    },
  }
</script>
<style lang="less" scoped>
  .ellipsis {
    max-width: 250px;
    white-space: nowrap; /* 确保文本在一行内显示 */
    overflow: hidden; /* 隐藏溢出的内容 */
    text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
  }
  .img-box1{
    width: 200px;
    height: 120px;
    object-fit: contain;
  }
  .colBox2Content{
    background: #F9FAFC;
    border: 1px solid #E6E9ED;
    padding: 14px;
    width: 100%;
    border-radius: 12px;
    .colBox2ContentT1{
      img{
        width: 144px;
      }
      span{
        font-size: 22px;
        line-height: 22px;
        color: #A6A6A6;
      }
    }
    .colBox2ContentT2{
      padding: 60px 0;
      .t1{
        font-size: 36px;
        font-weight: 900;
        line-height: 36px;
        color: #000000;
      }
      img{
        margin-top: 40px;
        width: 346px;
      }
      .t2{
        margin-top: 32px;
        font-size: 48px;
        font-weight: 900;
        line-height: 48px;
        color: #3A99BE;
      }
      .t3{
        font-size: 24px;
        line-height: 32px;
        color: #A6A6A6;
        margin-top: 12px;
        width: 528px;
        text-align: center;
      }
    }
  }
  .col5Box{
    margin-top: 24px;
    overflow-x: scroll;
    img{
      width: 160px;
      height: 120px;
      object-fit: contain;
      margin-right: 16px;
    }
    p{
      font-size: 28px;
      line-height: 32px;
      color: #383838;
    }
  }
  .colBox3{
    padding: 24px;
    margin-top: 32px;
    border-radius: 12px;
    background: #FFFFFF;
    .t1{
      font-size: 32px;
      line-height: 40px;
      font-weight: 900;
      color: #181818;
      padding-bottom: 12px;
    }
    .t2{
      font-size: 28px;
      line-height: 32px;
      color: #383838;
      padding: 12px 0;
    }
    .t3C{
      margin-top: 12px;
      padding: 12px;
      background: #F3F3F3;
      p{
        font-size: 28px;
        line-height: 32px;
        padding: 12px 0;
        color: #383838;
      }
    }
  }
  .colBox4{
    padding: 24px 0;
    margin-top: 32px;
    border-radius: 12px;
    background: #FFFFFF;
    .t1{
      padding-top: 28px;
      padding-bottom: 40px;
      font-size: 36px;
      font-weight: 900;
      line-height: 44px;
      color: #181818;
      padding-left: 32px;
    }
    .t2{
      font-size: 26px;
      font-weight: 900;
      color: #181818;
      padding-bottom: 16px;
    }
    .t2box{
      background: #F3F3F3;
      height: 80px;
      padding-left: 32px;
      margin-bottom: 16px;
      p{
        font-size: 28px;
        line-height: 44px;
        color: #181818;
        margin-right: 28px;
        margin-left: 10px;
      }
      img{
        width: 32px;
        height: 32px;
      }
    }
    .t3box{
      padding:16px 32px;
      img{
        width: 32px;
        height: 32px;
      }
      span{
        font-size: 28px;
        line-height: 36px;
        color: #181818;
      }
    }
    .t4box{
      padding:0 24px;
      box-sizing: border-box;
      .t4box-c{
        background: #F7F8F9;
      }
    }
    .mianze{
      font-size: 28px;
      line-height: 44px;
      color: #181818;
      padding: 0 32px;
      padding-bottom: 24px;
    }
  }
  .colBox2{
    border-radius: 12px;
    padding: 26px 32px;
    background: #FFFFFF;
    margin-top: 44px;
  }
  .margin-t-24{
    margin-top: 24px;
  }
  .topBox{
    padding: 24px;
    .topT1{
      p{
        font-size: 44px;
        font-weight: 600;
        line-height: 44px;
        color: #FFFFFF;
        margin-right: 24px;
      }
      span{
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #0052D9;
        padding: 12px 4px;
        background: #F2F3FF;
        border-radius: 6px;
        margin-right: 12px;
      }
    }
    .topT2{
      font-size: 24px;
      line-height: 24px;
      color: #FFFFFF;
      padding: 12px 0;
    }
  }
 
.ticketBox {
  height: 100%;
  background-color: #EDEDEF;
  background-image: url(https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/detailTicketTopBg.png);
  background-size: 100% 640px;
  background-repeat: no-repeat;
  padding-bottom: 200px;
}
</style>
