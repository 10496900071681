/*
 * @Author: “lihui” 13723683480@163.com
 * @Date: 2024-09-10 14:55:05
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-09-12 09:58:53
 * @FilePath: \an-h5\src\utils\userInfo.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 存储用户信息
export const setUserInfo = val => localStorage.setItem('userInfo', JSON.stringify(val));

// 存储token
export const setToken = val => localStorage.setItem('token', val);

// 获取浏览器token
export const getToken = () => {
	let token = localStorage.getItem('token');
	if (token) return token;
	return null;
};

// 获取用户信息
export const getUserInfo = () => {
	let userInfo = localStorage.getItem('userInfo');
	return JSON.parse(userInfo);
};

// 移除浏览器用户信息
export const removeUserInfo = () => localStorage.removeItem('userInfo');

// 移除浏览器用户token
export const removeToken = () => localStorage.removeItem('token');
