<!--
 * @Author: “lihui” 13723683480@163.com
 * @Date: 2024-07-16 14:18:10
 * @LastEditors: lihui 13723683480@163.com
 * @LastEditTime: 2024-10-07 22:52:56
 * @FilePath: \an-h5\src\layout\BaseLayout.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="base-layout">
    <van-nav-bar
      v-if="navBar.show"
      :title="navBar.title"
      :left-text="navBar.leftText"
      :right-text="navBar.rightText"
      :border="navBar.border"
      :safe-area-inset-top="navBar.safeAreaInsetTop"
      @click-left="onClickLeft"
      @click-right="onClickRight"
      class="navBarBox"
    >
    <template #left>
      <van-icon name="arrow-left" size="20" color="#000000" />
    </template>
      <template v-if="navBar.rightIcon" #right>
        <span v-if="navBar.rightText" class="van-nav-bar__text">{{navBar.rightText}}</span>
        <van-icon :name="navBar.rightIcon" size="18" />
      </template>
    </van-nav-bar>

    <div class="container">
      <!-- <keep-alive> -->
        <router-view :key="$route.path" />
      <!-- </keep-alive> -->
    </div>

    <van-tabbar
      v-if="false"
      v-model="active"
      :border="tabBar.border"
      :active-color="tabBar.activeColor"
      :inactive-color="tabBar.inactiveColor"
      :safe-area-inset-bottom="tabBar.safeAreaInsetBottom"
      :fixed="false"
      route
      @change="onTabBarChange"
    >
      <van-tabbar-item
        v-for="item of tabBar.list"
        :key="item.key"
        :to="item.path"
        :icon="item.icon"
        :dot="item.dot"
        :badge="item.badge"
        replace
      >{{ item.title }}</van-tabbar-item>
    </van-tabbar>

    <van-overlay :show="$store.state.app.isShowPrintMask">
      <div class="wrapperBox" @click="$store.state.app.isShowPrintMask = false">
        <div class="printBox flex-col-center" @click.stop>
          <img src="https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/detailTicketIcon2.png" alt="">
          <p class="t1">标签打印中，请等待...</p>
        </div>
      </div>
    </van-overlay>
    <!-- 入库弹框 -->
    <van-dialog v-model="$store.state.app.isShowWarehousingMask" title="确认入库" :show-cancel-button="false" :showConfirmButton="false">
      <div class="flex-col-center warehousingMaskCla">
        <p class="desc">请确认检测已完成并进行入库</p>
        <van-button native-type="submit" @click="dialogSubmit" square type="primary" class="btn2">确认</van-button>
        <van-button class="btn3" @click="to_page('/detailTicket?orderSn='+$store.state.app.vmScanOrderSn)" type="default">查看详情</van-button>
        <van-button class="btn3" @click="$store.state.app.isShowWarehousingMask = false" type="default">取消</van-button>
      </div>
    </van-dialog>

    <!-- 确认使用弹框 -->
    <van-dialog v-model="$store.state.app.isShowUseMask" title="确认使用" :show-cancel-button="false" :showConfirmButton="false">
      <div class="flex-col-center warehousingMaskCla">
        <van-field v-model="closeDesc" required class="inp-box" placeholder="请备注使用机构以及用途"></van-field>
        <van-button native-type="submit" @click="warehousingSubmit" square type="primary" class="btn2">确认</van-button>
        <van-button class="btn3" @click="to_page('/detailTicket?orderSn='+$store.state.app.vmScanOrderSn)" type="default">查看详情</van-button>
        <van-button class="btn3" @click="$store.state.app.isShowUseMask = false" type="default">取消</van-button>
      </div>
    </van-dialog>

    <van-popup v-model="$store.state.app.isShowRfidMask" :close-on-click-overlay="false" round position="bottom" :style="{ height: 'auto' }">
      <div class="rfidBox">
        <p class="t1">RFID扫描中，请等待...</p>
        <p class="t2">数据写入时，请保持周围两米内只有一枚RFID标签，否则无法写入数据</p>
        <div class="flex-start t3">
          <p>附近的RFID标签</p>
          <van-loading v-if="$store.state.app.isLoadingRfidRes" color="#042550" size="16" />
        </div>
        <template v-if="$store.state.app.rfidList.length">
          <div v-for="(item,i) in $store.state.app.rfidList" :key="i" class="flex-sb t4ListBox">
            <p v-if="item.detail" style="max-width: 70%;">[{{item.detail.partCode}}]_{{item.detail.partName}}</p>
            <p v-else>[新]_[{{item.epc}}]</p>
            <div class="flex-end">
              <span @click="to_page('/detailTicket?orderSn='+item.detail.orderSn)" v-if="item.detail">查看</span>
              <span @click="rukuBtn(item.detail.orderSn)" v-if="item.detail && item.detail.stock == 0">入库</span>
              <span @click="shiyongBtn(item.detail.orderSn)" v-if="item.detail && (item.detail.stock == 1 || item.detail.stock == 2)">使用</span>
              <span v-if="$store.state.app.rfidPartCode" @click="setRfid(item)">绑定</span>
            </div>
          </div>
        </template>
        <van-empty v-else :description="$store.state.app.isLoadingRfidRes?'扫描中':'扫描完成'" />
        <div class="flex-sb rfidBtnBox">
          <van-button @click="$store.state.app.isShowRfidMask = false" class="btn-l" color="#042550" plain type="primary">关闭</van-button>
          <van-button @click="rfidScan" class="btn-r" color="#042550" type="info">重新扫描</van-button>
        </div>
      </div>
    </van-popup>

    <!-- <van-overlay :show="$store.state.app.rfidLoadingType===0">
      <div class="wrapperBox" @click="$store.state.app.isShowPrintMask = false">
        <div class="printBox flex-col-center" @click.stop>
          <van-loading  color="#042550" size="46" />
          <p class="t3">RFID写入中</p>
          <p class="t4">请保持两米内仅有一枚标签...</p>
        </div>
      </div>
    </van-overlay> -->
    

  </div>
</template>

<script>
// utils
import { mapGetters } from 'vuex'
// components
import { NavBar, Tabbar, TabbarItem, Icon , Overlay,Dialog,Button,Field,Popup,Loading,Empty } from 'vant'
import { changeInboundOrOutbound,bindRfId } from '@/api';
// others
function notUndefined (val) {
  return typeof val !== 'undefined'
}
// vm
export default {
  components: {
    [NavBar.name]: NavBar,
    [Tabbar.name]: Tabbar,
    [Button.name]: Button,
    [TabbarItem.name]: TabbarItem,
    [Icon.name]: Icon,
    [Overlay.name]: Overlay,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Loading.name]: Loading,
    [Empty.name]: Empty,
  },
  computed: {
    ...mapGetters(['navBar', 'tabBar'])
  },
  watch: {
    // 每当 route 改变时，进行一些处理
    $route: {
      handler (route) {
        // 先重置 nav bar 设置
        this.$navBar.resetConfig()
        // 根据 route meta 设置一些默认值
        const { title, showTabBar, hiddenNavBar, navBarLeftText, navBarHiddenLeftArrow, navBarRightText, navBarRightIcon } = route.meta
        this.$tabBar.setShow(showTabBar)
        // 如果显示 tab bar 就不需要默认展示内页的相关配置
        if (showTabBar) {
          this.$navBar.setLeftText('')
          this.$navBar.setRightText('')
          this.$navBar.setRightIcon('')
        }
        if (hiddenNavBar) {
          this.$navBar.setShow(false)
        } else {
          // 显示 nav bar 时再进行相关设置
          notUndefined(title) && this.$navBar.setTitle(title)
          notUndefined(navBarLeftText) && this.$navBar.setLeftText(navBarLeftText)
          notUndefined(navBarHiddenLeftArrow) && this.$navBar.setShowLeftArrow(false)
          notUndefined(navBarRightText) && this.$navBar.setRightText(navBarRightText)
          notUndefined(navBarRightIcon) && this.$navBar.setRightIcon(navBarRightIcon)
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      active: 0,
      closeDesc:'',
    }
  },
  methods: {
    to_page(url){
      this.$store.state.app.isShowRfidMask = false;
      this.$router.push(url);
    },
    shiyongBtn(orderSn){
      this.closeDesc = '';
      this.$store.state.app.isShowUseMask = true;
      this.$store.state.app.vmScanOrderSn = orderSn;
    },
    rukuBtn(orderSn){
      Dialog.alert({
        title: '确认入库',
        message: '请确认检测已完成并进行入库',
        showCancelButton:true,
      }).then(() => {
        changeInboundOrOutbound({
          "orderSn": orderSn,
          "inboundOutboundStatus": 1
        }).then(res=>{
          if(res.code == '00000'){
            this.$store.state.app.isShowRfidMask = false;
            this.$toast('操作成功');
          }
        })
        // on confirm
      })
      .catch(() => {
        // on cancel
      });
    },
    setRfid(item){
      bindRfId({
        "rfId": item.epc,
        "code": this.$store.state.app.rfidPartCode
      }).then(res=>{
        if(res.code == '00000'){
          this.$toast('绑定成功');
          this.$store.state.app.isShowRfidMask = false;
          if(this.$route.path == '/addTicket'){
            let list = localStorage.getItem('printDeviceList');
            if(!list){
              list = [];
            }else{
              list = JSON.parse(list);
            }
            let orderSn = this.$store.state.app.vmScanOrderSn;
            this.$store.state.app.vmScanOrderSn = '';
            list.push(orderSn);
            localStorage.setItem('printDeviceList',JSON.stringify(list));
            this.$router.push('/detailTicket?orderSn='+orderSn); 
          }
        }
      })
    },
    rfidScan(){
      window.requestRFIDDevice();
    },
    warehousingSubmit(){
      if(!this.closeDesc){
        this.$toast('请备注使用机构以及用途');
        return ;
      }
      changeInboundOrOutbound({
        orderSn:this.$store.state.app.vmScanOrderSn,
        inboundOutboundStatus:2, //出入库状态（0：待入库，1：已入库，2：已出库）
        outboundRemark:this.closeDesc,
      }).then(res=>{
        if(res.code == '00000' && res.data){
          this.$toast('操作成功'); 
          this.$store.state.app.isShowUseMask = false;
          this.$store.state.app.vmScanOrderSn = '';
          this.$store.state.app.isShowRfidMask = false;
        }
      })
    },
    dialogSubmit(){
      changeInboundOrOutbound({
        orderSn:this.$store.state.app.vmScanOrderSn,
        inboundOutboundStatus:1, //出入库状态（0：待入库，1：已入库，2：已出库）
      }).then(res=>{
        if(res.code == '00000' && res.data){
          this.$toast('操作成功'); 
          this.$store.state.app.isShowWarehousingMask = false;
          this.$store.state.app.vmScanOrderSn = '';
        }
      })
    },
    changePrintMask(){
      console.log(2233);
      this.$store.state.app.isShowPrintMask = false
    },
    onClickLeft () {
      let flag = true
      const cb = this.navBar.handleLeftClick
      const next = (bool = true) => (flag = bool)
      cb && cb(next)
      flag && this.$router.go(-1)
    },
    onClickRight () {
      const cb = this.navBar.handleRightClick
      cb && cb()
    },
    onTabBarChange (index) {
      console.log(index, this.tabBar.list[index])
    }
  }
}
</script>

<style lang="less" scoped>
  .rfidBox{
    padding: 40px;
    padding-bottom: 0px;
    .t1{
      font-size: 36px;
      font-weight: 600;
      line-height: 36px;
      color: #383838;
      text-align: center;
    }
    .t2{
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #5E5E5E;
      margin-top: 16px;
    }
    .t3{
      padding-top: 40px;
      padding-bottom: 28px;
      border-bottom: 2px solid #E7E7E7;
      p{
        font-size: 26px;
        font-weight: 600;
        line-height: 26px;
        color: #383838;
        margin-right: 12px;
      }
    }
    .t4ListBox{
      height: 96px;
      line-height: 96px;
      border-bottom: 2px solid #E7E7E7;
      p{
        font-size: 28px;
        line-height: 32px;
        color: #242424;
      }
      span{
        font-size: 28px;
        font-weight: 600;
        line-height: 32px;
        text-align: right;
        color: #0052D9;
        margin-left: 32px;
      }
    }
    .rfidBtnBox{
      padding: 16px 0;
      .btn-l{
        width: 323px;
        border-radius: 14px;
      }
      .btn-r{
        width: 323px;
        border-radius: 14px;
      }
    }
  }
  .inp-box{
    background: #F3F3F3;
    width: 80%;
    margin: 24px 0;
  }
  .desc{
    font-size: 32px;
    line-height: 48px;
    color: rgba(0, 0, 0, 0.6);
  }
  .warehousingMaskCla{
    padding-bottom: 30px;
  }
  .btn3{
    width: 80%;
    margin: 15px;
  }
  .btn2{
    margin: 15px;
    margin-top: 30px;
    width: 80%;
    background: #042550;
    border-radius: 12px;
    border: 2px solid #042550;
  }
.wrapperBox{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.printBox{
  width: 426px;
  height: 320px;
  background-color: #fff;
  padding: 52px 0;
  box-sizing: border-box;
  border-radius: 24px;
  img{
    width: 142px;
    height: 142px;
  }
  .t1{
    font-size: 32px;
    line-height: 32px;
    color: #042550;
    padding: 24px 0;
  }
  .t2{
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
    color: #9E9E9E;
  }
  .t3{
    font-weight: 600;
    font-size: 32px;
    color: #042550;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .t4{
    color: #042550;
    font-weight: 400;
    font-size: 26px;
  }
}
.base-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 2rem;
  .navBarBox{
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .container {
    height: min-content;
    flex: 1;
    position: relative;
    .animate-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.van-nav-bar__text {
  margin-right: 4px;
}
</style>
