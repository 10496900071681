<!--
 * @Author: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @Date: 2024-07-16 14:18:10
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-10-18 14:47:32
 * @FilePath: \vue2-rem\src\views\Home.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home">
    <van-tabs v-model="tabsIndex" title-active-color="#042550" :ellipsis="false" class="work-tabs-box" @change="tabsChange">
      <van-tab v-for="(item,i) in tabsList" :title="item.title" :key="i"></van-tab>
    </van-tabs>
    <van-dropdown-menu active-color="#042550" class="van-hairline--top">
      <van-dropdown-item :title="typeName" v-model="typeId" :options="vehiclePartOption" @change="initList"/>
      <van-dropdown-item :title="repairName" v-model="repairStatus" :options="option1" @change="initList"></van-dropdown-item>
      <van-dropdown-item title="创建时间" ref="dateTime">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          cancel-button-text="重置"	
          title="选择创建时间"
          :min-date="minDate"
          :max-date="maxDate"
          @cancel="closeTime"
          @confirm="changeShowTime"
        />
      </van-dropdown-item>
    </van-dropdown-menu>
    <van-pull-refresh v-model="isRefreshLoading" success-text="刷新成功" @refresh="onRefresh">
      <van-list
          v-if="isSgowList"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getList"
          class="content-box"
        >
        <div @click="to_page('/detailTicket?tabName=检测工单详情&orderSn='+val.orderSn)" class="col-item" v-for="(val,n) in dataList" :key="n">
          <div class="flex-sb">
            <p class="t1">[{{val.typeName}}] {{val.partName}}</p>
            <div class="flex-start">
              <p v-if="val.repairStatus===0" class="t2 t2-succ">整备中</p>
              <p v-if="val.orderStatus==1" class="t2 t2-succ">{{orderStatusObj[val.orderStatus]}}</p>
              <p v-else-if="val.orderStatus==2" class="t2 t2-blue">{{orderStatusObj[val.orderStatus]}}</p>
              <p v-else-if="val.orderStatus==3" class="t2 t2-close">{{orderStatusObj[val.orderStatus]}}</p>
              <p v-else class="t2">{{orderStatusObj[val.orderStatus]}}</p>
              <p v-if="val.recheckFlag==1" class="t2 t2-blue">复检</p>
              <p v-if="val.inboundOutboundStatus==0" class="t2">待入库</p>
              <p v-if="val.inboundOutboundStatus==1" class="t2 t2-blue">已入库</p>
              <p v-if="val.inboundOutboundStatus==2" class="t2 t2-blue">已出库</p>
            </div>
          </div>
          <div class="flex-start col-item-i-box">
            <img class="img1" :src="val.partPics[0]" alt="">
            <div>
              <p class="t3">回用件编码：{{val.partCode}}</p>
              <p class="t3">工单编码：{{val.orderSn}}</p>
              <p class="t3">工单时间：{{val.createTime}}</p>
            </div>
          </div>
          <div class="col-item-i-box2">
            <p>检测机构：{{val.detectMechanismName||'-'}}</p>
            <p>检测人员：{{val.inspectorName||'-'}}</p>
          </div>
          <div class="flex-end">
            <!-- <van-button @click.stop="printBtn(val)" class="col-btn" plain>打印标签</van-button>       -->
            <van-button @click.stop="go_testing(val)" v-if="tabsIndex == 1" class="col-btn" plain>去检测</van-button>      
            <van-button @click.stop="to_page('/TestReport?reportSn='+val.reportSn)" v-if="val.orderStatus == 2" class="col-btn" plain>检测报告</van-button>      
            <van-button @click.stop="zhengbeiBtn(val)" v-if="val.repairStatus==0" class="col-btn" plain>整备完成</van-button>   
            <van-button @click.stop="rukuBtn(val)" v-if="val.inboundOutboundStatus==0" class="col-btn" plain>入库</van-button>   
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    

    <div class="bottom-box flex-sb">
      <van-button @click="sheetShow = true" class="btn1" plain>扫一扫</van-button>
      <van-button @click="to_page('/addTicket')" square type="primary" class="btn2">新建工单</van-button>
    </div>

    <van-action-sheet
      v-model="sheetShow"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="onSheetSelect"
    />

  </div>
</template>

<script>
// @ is an alias to /src
import { Tabs,Tab,Button,List,DropdownMenu, DropdownItem,DatetimePicker,Dialog,PullRefresh,ActionSheet } from 'vant'
import { vehiclePartOrderList,getVehiclePartTypeList,changeInboundOrOutbound,setRepair,getRecheckOrder,getOrderAllNum,setDetecting,partLabelPrintFlag } from "@/api";
export default {
  name: 'Home',
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Button.name]: Button,
    [List.name]: List,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [DatetimePicker.name]: DatetimePicker,
    [Dialog.name]: Dialog,
    [PullRefresh.name]: PullRefresh,
    [ActionSheet.name]: ActionSheet,
  },
  data() {
    return {
      isRefreshLoading:false,
      sheetShow:false,
      actions: [{ name: '扫描回用件标签' }, { name: '扫描RFID标签' }],
      typeName:'回用件类型',
      repairName:'检测类型',
      orderStatusObj:{
        '0':'待检测',
        '1':'检测中',
        '2':'检测完成',
        '3':'已关闭',
      },
      typeId:'',
      repairStatus:'',
      minDate: new Date(),
      maxDate: new Date(),
      currentDate: '',
      option1:[{
        text:'默认',
        value:'',
      },
      {
        text:'整备',
        value:'0',
      },
      {
        text:'待入库',
        value:'1',
      }],
      option2:[
        {
          text:'创建时间',
          value:''
        },
      ],
      tabsIndex:0,
      loading: false,
      finished: false,
      tabsList:[
        {
          title:'全部',
          id:'',
        },
        {
          title:'待检测',
          id:'0',
        },
        {
          title:'检测中',
          id:'1',
        },
        {
          title:'已完成',
          id:'2',
        },
        {
          title:'已关闭',
          id:'3',
        },
      ],
      tel:'',
      sms:'',
      checked:true,
      vehiclePartOption:[],
      dataList:[],
      pageNum:0,
      orderStatus:'',
      isSgowList:true,
    }
  },
  created () {
    this.$navBar.setTitle('检测工单管理')
    this.$tabBar.setShow(false);
    this.minDate = new Date(new Date().getTime()-2*30*24*60*60*1000);
    this.currentDate = '';
    let i = localStorage.getItem('orderTabsIndex');
    if(i){
      localStorage.removeItem('orderTabsIndex');
      this.tabsIndex = Number(i);
    }else if(this.$route.query.tabsIndex){
      this.tabsIndex = Number(this.$route.query.tabsIndex);
    }
    this.orderStatus = this.tabsList[this.tabsIndex].id;
    getOrderAllNum().then(res=>{
      if(res.code =='00000'){
        let obj = {
          all:0,  
        }
        res.data.forEach(item=>{
          if(!item.count) item.count = 0;
          obj[item.orderStatus] = item.count;
          obj.all +=item.count;
        })
        this.tabsList.forEach(item=>{
          if(item.id){
            item.title = `${item.title}(${obj[item.id]||'0'})`;
          }else{
            item.title = `${item.title}(${obj.all})`;
          }
        })
      }
    })
    getVehiclePartTypeList().then(res=>{
      if(res.code == '00000'){
        let arr = [{
          text:'默认',
          value:'',
        }];
        res.data.forEach(item => {
          arr.push({
            text:item.name,
            value:item.id,
          })
        });
        this.vehiclePartOption = arr;
      }
    })
  },
  methods: {
    go_testing(val){
      setDetecting({
        "orderSn": val.orderSn,
        "partLabelPrintFlag": val.partLabelPrintFlag?1:0
      }).then(res=>{
        if(res.code == '00000' && res.data){
          this.to_page('/detectTicket?orderSn='+val.orderSn)
        }
      })
    },
    onSheetSelect(value,i){
      if(i === 0){
        this.sweepClick();
      }else{
        this.$store.state.app.isShowRfidMask = true;
        this.$store.state.app.rfidPartCode = '';
        window.requestRFIDDevice();
      }
    },
    onRefresh(){
      this.dataList =[];
      this.initList();
    },
    zhengbeiBtn(item){
      Dialog.alert({
        title: '确认整备完成',
        message: '请确认整备已完成',
        showCancelButton:true,
      }).then(() => {
        const toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '保存中',
        });
        setRepair({
          "orderSn": item.orderSn,
          "repairStatus": 1
        }).then(res=>{
          if(res.code == '00000' && res.data){
            getRecheckOrder({
              "orderSn": item.orderSn,
            }).then(res2=>{
              toast.clear();
              if(res2.code == '00000'){
                this.$toast('操作成功');
                this.to_page('/detailTicket?orderSn='+res2.data.orderSn)
              }else{
                this.$toast('创建复检工单失败');
              }
            })
          }else{
            toast.clear();
            this.$toast('保存失败');
          }
        })
        // on confirm
      })
      .catch(() => {
        // on cancel
      });
    },
    rukuBtn(item){
      Dialog.alert({
        title: '确认入库',
        message: '请确认检测已完成并进行入库',
        showCancelButton:true,
      }).then(() => {
        changeInboundOrOutbound({
          "orderSn": item.orderSn,
          "inboundOutboundStatus": 1
        }).then(res=>{
          if(res.code == '00000'){
            this.$toast('操作成功');
            item.inboundOutboundStatus = 1;
          }
        })
        // on confirm
      })
      .catch(() => {
        // on cancel
      });
    },
    async printBtn(item){
      let status = await window.setPrintDevice(item.partId); // 打印回用件标签
      if(status ===1){
        partLabelPrintFlag({orderSn:item.orderSn,partLabelType:'1'});
      }
    },
    initList(){
      this.pageNum=0;
      this.finished = false;
      this.vehiclePartOption.forEach(item=>{
        if(this.typeId == item.value){
          this.typeName = item.text == '默认'?'回用件类型':item.text;
        }
      })
      this.option1.forEach(item=>{
        if(this.repairStatus == item.value){
          this.repairName = item.text == '默认'?'检测类型':item.text;
        }
      })
      this.getList();
    },
    tabsChange(e,ag2){
      this.orderStatus = this.tabsList[e].id;
      this.dataList=[];
      this.pageNum=0;
      this.finished = false;
      this.isSgowList = false;
      setTimeout(()=>{
        this.isSgowList = true;
      },100)
    },
    changeShowTime() {
      this.$refs.dateTime.toggle();
      this.initList();
    },
    closeTime(){
      this.currentDate = '';
      this.changeShowTime();
    },
    timestampToDateTime(date) {
      var year = date.getFullYear(); // 获取年份
      var month = ("0" + (date.getMonth() + 1)).slice(-2); // 获取月份，转为两位数
      var day = ("0" + date.getDate()).slice(-2); // 获取日，转为两位数
      var hours = ("0" + date.getHours()).slice(-2); // 获取小时，转为两位数
      var minutes = ("0" + date.getMinutes()).slice(-2); // 获取分钟，转为两位数
      var seconds = ("0" + date.getSeconds()).slice(-2); // 获取秒钟，转为两位数
      // 拼接字符串
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    getList(){
      this.pageNum++;
      let parmas = {
        "pageNum": this.pageNum,
        "pageSize": 10,
        orderStatus:this.orderStatus,
        typeId:this.typeId,
      }
      if(this.currentDate){
        parmas.endDate = this.timestampToDateTime(new Date());
        parmas.beginDate = this.timestampToDateTime(this.currentDate);
      }

      if(this.repairStatus == '0'){
        parmas.repairStatus = '0';
      }else if(this.repairStatus == '1'){
        parmas.inboundOutboundStatus = '0';
      }
      vehiclePartOrderList(parmas).then(res=>{
        this.loading = false;
        this.isRefreshLoading = false;
        if(res.code == '00000' && res.data){
          if(res.data.list.length<10){
            this.finished = true;
          }
          res.data.list.forEach(item=>{
            if(item.partPics){
              item.partPics = JSON.parse(item.partPics)
            }else{
              item.partPics = []
            }
          })
          if(this.pageNum ===1) this.dataList =[];
          this.dataList = [...this.dataList,...res.data.list]
        }
      })
    },
    sweepClick(){
      window.h5ScanCode();
    },
    to_page(url){
      localStorage.setItem('orderTabsIndex',this.tabsIndex)
      this.$router.push(url);
    },
  },
}
</script>
<style>
  .work-tabs-box .van-tabs__line{
    background-color: #042550;
  }
  .work-tabs-box .van-tabs__wrap{
    position: sticky;
    top: 92px;
    z-index: 2;
  }
  #app .base-layout{
    height: min-content;
  }
</style>
<style lang="less" scoped>
@import '~@/styles/mixins.less';
.content-box{
  padding: 24px;
  box-sizing: border-box;
  padding-bottom: 140px;
}
.col-item{
  box-sizing: border-box;
  border-radius: 12px;
  background: #FFFFFF;
  padding: 24px;
  margin-bottom: 24px;
  &:nth-last-child(1){
    margin-bottom: 150px;
  }
  .col-item-i-box{
    padding: 16px 0;
  }
  .t1{
    font-size: 32px;
    line-height: 40px;
    color: #181818;
  }
  .t2{
    font-size: 20px;
    line-height: 32px;
    color: #E37318;
    padding: 4px 12px;
    background: #FFF1E9;
    border-radius: 6px;
    margin-left: 16px;
  }
  .t2-succ{
    color: #2BA471;
    background: #E3F9E9;
  }
  .t2-blue{
    color: #0052D9;
    background: #F2F3FF;
  }
  .t2-close{
    color: rgba(0, 0, 0, 0.9);
    background: #F3F3F3;
  }
  .img1{
    width: 120px;
    height: 120px;
    margin-right: 16px;
    object-fit: contain;
  }
  .t3{
    font-size: 26px;
    line-height: 32px;
    color: #9E9E9E;
    padding: 8px 0;
  }
  .col-item-i-box2{
    background: #F7F8F9;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 16px;
    p{
      font-size: 24px;
      line-height: 24px;
      color: #9E9E9E;
      padding: 8px 0;
    }
  }
}
.col-btn{
  color: #000000;
  padding: 0 14px;
  height: 56px;
  border: 2px solid #042550;
  border-radius: 12px;
  margin: 0 8px;
}
.bottom-box{
  position: fixed;
  bottom: 0px;
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  background: #FFFFFF;
  box-shadow: 0px -8px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 16px 24px;
  padding-bottom: 40px;
  .btn1{
    color: #000000;
    width: 343px;
    border: 2px solid #042550;
    border-radius: 12px;
  }
  .btn2{
    width: 343px;
    background: #042550;
    border-radius: 12px;
    border: 2px solid #042550;
  }
}

.home {
  height: 100%;
  background: #F2F3F5;
}

</style>
