<!--
 * @Author: “lihui” 13723683480@163.com
 * @Date: 2024-09-30 15:55:51
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-09-30 16:57:31
 * @FilePath: \an-h5\src\components\step.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<!-- Step.vue  -->
<template>
	<div>
    <div class="showProcess">
			<ul class="flex-sb">
				<li class="list" v-for="(item, index) in dataList" :key="index" @click="stepClick(index)" :style="{width:100/dataList.length+'%'}">
					<div class="ProcessBox">
						<div class="Process bg-icon active" v-if="index < active">
							<van-icon name="success" color="#1989fa"/>
						</div>
						<span class="Process" v-else :class="{ active:index <= active}">{{index+1}}</span>
						<div class="ProcessName" :class="{ actives:index <= active}">
							<span>{{item.typeName}}</span>
						</div>
					</div>
				</li>
			</ul>
		</div>
  </div>
</template>
 
<script>
	import { Icon } from 'vant'
	export default {
		name: 'steps',
		components: {
			[Icon.name]: Icon,
		},
		props: {
			dataList: {
				type: Array,
				default: function() {
					return []
				}
			},
			// 传递步骤参数
			active: {
				type: Number,
				default: function() {
					return 0
				}
			},
		},
		methods: {
			stepClick(i){
				this.$emit('changeStepIndex',i);
			}
		},
	}
</script>
 
<style lang="less" scoped>
.showProcess {
  box-sizing: border-box;
	padding: 16px 0;
  width: 100%;
  ul {
    display: flex;
    align-items: center;
    justify-content:center;
    .list {
			overflow: hidden;
      .ProcessBox {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-weight: 400;
        text-align: center;
        .Process {
          width: 44px;
          height: 44px;
          background: #CCCCCC;
          border-radius: 50%;
          font-size: 28px;
          font-weight: 400;
          color: #ffffff;
          line-height: 44px;
          img{
            width: 44px;
            height: 44px;
          }
        }
        .ProcessName {
          max-width: 165px;
          font-size: 28px;
          font-weight: 400;
          color: #818181;
          margin-top: 16px;
        }
        .active {
          background: #042550;
        }
        .actives {
          color: #042550;
        }
				.bg-icon{
					background: #F2F3FF;
				}
      }
    }
  }
}
/* 以下是关键部分 */
.Process{
  position: relative;
  /* 利用伪元素来添加左右横线 */
  &:before,&:after{
    content: '';
    width: 295px;
    height: 1px;
    border-bottom: 4px solid #ccc;
    display: inline-block;
    position: absolute;
    top: 20px;
  }
  &:before{
    left: 60px;
  }
  &:after{
    right: 60px;
  }
}
.active{
  &:before,&:after{
    border-bottom: 4px solid #042550;
  }
}
/* 利用伪类来控制样式去掉首尾的横线 */
.list:first-child{
  .Process{
    &::after{
      display: none;
    }
  }
}
.list:last-child{
  .Process{
    &::before{
      display: none;
    }
  }
}
</style>
