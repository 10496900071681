/*
 * @Author: “lihui” 13723683480@163.com
 * @Date: 2024-07-16 14:18:10
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-09-10 14:55:54
 * @FilePath: \an-h5\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

import app from './modules/app'
import user from './modules/user';

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  modules: {
    app,
    user
  }
})
