<!--
 * @Author: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @Date: 2024-07-16 14:18:10
 * @LastEditors: lihui 13723683480@163.com
 * @LastEditTime: 2024-09-29 23:15:48
 * @FilePath: \vue2-rem\src\views\Inner.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home">
  </div>
</template>

<script>
// @ is an alias to /src
import { Button } from 'vant'

export default {
  name: 'Home',
  components: {
    [Button.name]: Button
  },
  created () {
    console.log('2233');
    if(this.$route.query.orderSn){
      this.$router.push('/detailTicket?orderSn='+this.$route.query.orderSn);
    }
  }
}
</script>
