<!--
 * @Author: “lihui” 13723683480@163.com
 * @Date: 2024-07-26 14:53:52
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-10-10 16:47:40
 * @FilePath: \an-h5\src\views\AddTicket.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="ticketBox">
    <div class="topBox">
      <div class="flex-start topT1">
        <p>开始检测</p>
        <span v-if="detailData.disassembleFlag">已拆卸</span>
        <span v-if="detailData.cleanFlag">已清洗</span>
      </div>
      <p class="topT2 margin-t-24">[{{detailData.vehiclePartInfo.typeName}}]{{detailData.vehiclePartInfo.partName}}</p>
      <div class="flex-sb">
        <p class="topT2">工单编码</p>
        <p class="topT2">{{detailData.orderSn}}</p>
      </div>

      <div class="colBox3">
        <p class="t1">回用件图片</p>
        <div class="flex-start col5Box">
          <img v-for="(val,i) in detailData.vehiclePartInfo.partPics" :key="i" v-lazy="val" alt="">
        </div>
      </div>

      <div class="col-item">
        <h6>清洗信息</h6>
        <van-field v-model="cleanFlagName" label="是否清洗" readonly required is-link placeholder="请选择回用件是否清洗"
          :rules="[{ required: true, message: '请选择回用件是否清洗' }]" @click="showPicker = true"/>
          <van-popup v-model="showPicker" position="bottom">
            <van-picker title="" show-toolbar :columns="columns" @confirm="onConfirmFlag2" @cancel="showPicker = false" />
          </van-popup>
        <van-field name="uploader" label="清洗留存" :required="cleanFlagName=='否'?false:true" :rules="[{ required: true, message: '清洗留存不能为空' }]">
          <template #input>
            <van-uploader v-model="cleanPhotos" lazy-load capture="camera" :max-count="10" multiple :before-delete="deleteImg" :after-read="afterRead" />
          </template>
        </van-field>
      </div>
      
      <div class="col-item">
        <h6>检测项目</h6>
        <van-field v-for="(item,i) in detailData.detectItemTypes" :key="i" :label="item.typeName" readonly required input-align="right" is-link 
          :placeholder="item.completed ==0?'去添加':''" @click="to_page(`/reusablePartsDetect?tabsIndex=${i}&orderSn=${detailData.orderSn}`)">
          <template #button v-if="item.completed">
            <div v-if="item.completed == 2" class="flex-start myTagWarning">
              <van-icon name="warning" color="#E37318" size="20"/>
              <span>未完成</span>
            </div>
            <div v-if="item.completed == 1" class="flex-start myTagSucc">
              <van-icon name="checked" color="#2BA471" size="20"/>
              <span>已添加</span>
            </div>
          </template>
        </van-field>
      </div>

      <div class="col-item">
        <div class="flex-sb">
          <h6 class="t1">等级预估</h6>
          <span class="dengjiCla">系统评估：{{detailData.assessmentLevel?columns2[detailData.assessmentLevel-1]:'暂无'}}</span>
        </div>
        <van-field v-model="resultLevelName" label="检测等级" readonly required is-link placeholder="请选择回用件检测等级"
          :rules="[{ required: true, message: '请选择回用件检测等级' }]" @click="showLevel = true"/>
        <van-popup v-model="showLevel" position="bottom">
          <van-picker title="" show-toolbar :columns="columns2" @confirm="onConfirmFlag" @cancel="showLevel = false" />
        </van-popup>
      </div>
    </div>
    
    <div class="bottom-box flex-sb">
      <!-- <van-button square type="primary" class="btn2">去检测</van-button> -->
      <van-button @click="saveBtn" plain class="btn3">保存</van-button>
      <van-button @click="submitBtn" square type="primary" class="btn4">提交检测信息</van-button>
    </div>

  </div>
</template>
<script>
  // @ is an alias to /src
  import { Field,Popup,Uploader,Picker,Steps,Button,Step,Dialog,Icon} from 'vant'
  import { getDetectItem,deleteFileApi,vehiclePartDetectSubmit,partLabelPrintFlag } from "@/api";
  import * as OSS from "@/utils/oss";  // 引入oss.js
  export default {
    name: 'Home',
    components: {
      [Field.name]: Field,
      [Popup.name]: Popup,
      [Uploader.name]: Uploader,
      [Picker.name]: Picker,

      [Steps.name]: Steps,
      [Button.name]: Button,
      [Step.name]: Step,
      [Dialog.Component.name]: Dialog.Component,
      [Icon.name]: Icon,
    },
    data() {
      return {
        showLevel:false,
        columns2:['一级','二级','三级','四级'],
        resultLevel:'',
        resultLevelName:'',

        showPicker:false,
        columns:['否','是'],
        cleanFlag:'',
        cleanFlagName:'',

        uploader: [{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }],
        cleanPhotos:[],
        detailData:{
          vehiclePartInfo:{}
        },
        
        showPopover:false,
        closeDesc:'',
        dataInfo:{
          val1:''
        }
      }
    },
    created () {
      this.$navBar.setTitle('检测工单详情')
      this.$tabBar.setShow(false);
      this.$bus.$on('detectTicketSaveBtnInfo', this.saveBtn); // 回退时保存弹框
      this.getDetail();
      // Dialog.alert({
      //   title: '确认整备',
      //   message: '请确认检测已完成并开始整备',
      //   showCancelButton:true,
      // }).then(() => {
      //   console.log(1);
      //   // on confirm
      // })
      // .catch(() => {
      //   console.log(0);
      //   // on cancel
      // });;
      this.$store.state.app.isDetectDataChange = false;
    },
    methods: {
      onConfirmFlag2(ag1,ag2){
        this.cleanFlagName = ag1;
        this.cleanFlag = ag2;
        this.showPicker = false;
        this.$store.state.app.isDetectDataChange = true;
        this.saveBtn(null,true);
      },
      onConfirmFlag(ag1,ag2){
        this.resultLevelName = ag1;
        this.resultLevel = ag2;
        this.showLevel = false;
        this.$store.state.app.isDetectDataChange = true;
      },
      saveBtn(next,noMsg){
        let cleanPhotos = [];
        this.cleanPhotos.forEach(item => {
          if(item.url){
            cleanPhotos.push(item.url)
          }else{
            cleanPhotos.push(item);
          }
        });
        vehiclePartDetectSubmit({
          orderSn:this.detailData.orderSn,
          cleanFlag:this.cleanFlag,
          cleanPhotos,
          draftFlag:1,
          resultLevel:this.resultLevel+1, // 检测等级（1：一级，2：二级，3：三级，4：四级）
        }).then(res=>{
          if(res.code == '00000'&& res.data){
            if(!noMsg) this.$toast('保存成功');
            this.$store.state.app.isDetectDataChange = false;
            setTimeout(()=>{
              if(next && typeof next == 'function') next();
            },700);
          }
        })
      },
      submitBtn(){
        let msg = '';
        if(this.cleanFlag === '' || this.cleanFlag === null){
          msg = '请选择回用件是否清洗';
        }else if((!this.cleanPhotos || this.cleanPhotos.length===0) && this.cleanFlag !==0){
          msg = '请先上传清洗留存照片';
        }else if(this.resultLevel === ''){
          msg = '请先选择检测等级';
        }else{
          this.detailData.detectItemTypes.forEach(item=>{
            if(item.completed !=1) msg = '请先完成检测项目';
          })
        }

        if(msg){
          this.$toast(msg);
          return ;
        }
        let cleanPhotos = [];
        this.cleanPhotos.forEach(item=>{
          cleanPhotos.push(item.url);
        })
        vehiclePartDetectSubmit({
          orderSn:this.detailData.orderSn,
          cleanFlag:this.cleanFlag,
          cleanPhotos,
          resultLevel:this.resultLevel+1, // 检测等级（1：一级，2：二级，3：三级，4：四级）
          draftFlag:0,
        }).then(async res=>{
          if(res.code == '00000'&& res.data){
            this.$toast('保存成功');
            this.$store.state.app.isDetectDataChange = false;
            if(this.detailData.recheckFlag === 1){ // 复检后提交检测成功时打印溯源标签
              let status = await window.setPrintDevice(this.detailData.vehiclePartInfo.partId,1); // 打印溯源标签
              if(status ===1){
                partLabelPrintFlag({orderSn:this.detailData.orderSn,partLabelType:'2'});
                this.$router.push('/detailTicket?orderSn='+this.detailData.orderSn);
              }else{
                setTimeout(()=>{
                  this.$router.push('/detailTicket?orderSn='+this.detailData.orderSn);
                },750)
              }
            }else{
              setTimeout(()=>{
                this.$router.push('/detailTicket?orderSn='+this.detailData.orderSn);
              },1000);
            }
          }
        })
      },
      async afterRead(file) {
        file.status = 'uploading';
        file.message = '上传中...';
        file.uuid = Math.floor(Math.random() * 1000000000);
        try {
          // 上传文件
          const result = await OSS.client().put(`vehicle/${file.file.name}`, file.file);
          if(result.res && result.res.status == '200'){
          this.$store.state.app.isDetectDataChange = true;
          // result.url = result.url.replace('http://noi-ai.oss-cn-hangzhou.aliyuncs.com','https://f.auto-axc.com');
          file.url = result.url;
          file.status = 'done';
          this.saveBtn(null,true);
          return ;
          }
        } catch (error) {
          
        }
        file.status = 'failed';
        file.message = '上传失败';
        this.$toast('图片上传,请稍后重试');
        let i = this.cleanPhotos.findIndex(item=>{
          return item.uuid == file.uuid
        })
        if(i!==-1){
          this.cleanPhotos.splice(i,1);
        }
      },
      deleteImg(file){
        return new Promise((resolve, reject) => {
          this.$store.state.app.isDetectDataChange = true;
          resolve(true);
          // deleteFileApi(file.url).then(res=>{
          //   if(res.code == '00000'){
          //     resolve(true);
          //   }
          // })
        })
      },
      to_page(url){
        this.$router.push(url)
      },
      getDetail(){
        const toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '获取中',
        });
        getDetectItem(this.$route.query.orderSn).then(res=>{
          toast.clear();
          if(res.code == '00000'){
            let derList =[],derList2=[]; // 漆膜检测放最后
            res.data.detectItemTypes.forEach(item=>{
              if(item.typeName == '漆膜检测'){
                derList.push(item); 
              }else{
                derList2.push(item); 
              }
            })
            res.data.detectItemTypes = [...derList2,...derList];
            this.detailData = res.data;
            this.cleanFlag = res.data.cleanFlag;
            this.cleanFlagName = this.columns[res.data.cleanFlag];
            if(res.data.resultLevel){
              this.resultLevel = res.data.resultLevel-1;
              this.resultLevelName = this.columns2[res.data.resultLevel-1];
            }
            let list = [];
            if(res.data.cleanPhotos){
              res.data.cleanPhotos.forEach(item=>{
                list.push({
                  url:item,
                  status:'done'
                })
              })
            }
            this.cleanPhotos = list;
          }
        })
      },
    },
  }
</script>
<style lang="less" scoped>
  .myTagSucc{
    padding: 4px 16px;
    border-radius: 6px;
    background: #E3F9E9;
    span{
      margin-left: 12px;
      font-size: 24px;
      line-height: 32px;
      color: #2BA471;
    }
  }
  .myTagWarning{
    padding: 4px 16px;
    border-radius: 6px;
    background: #FFF1E9;
    span{
      margin-left: 12px;
      font-size: 24px;
      line-height: 32px;
      color: #E37318;
    }
  }
  .dengjiCla{
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    color: #3A99BE;
    margin-right: 24px;
  }
  .inp-box{
    background: #F3F3F3;
    width: 526px;
    margin: 24px 0;
  }
  .popover-t1{
    font-size: 32px;
    line-height: 48px;
    color: rgba(0, 0, 0, 0.9);
    padding: 24px 32px;
    width: 375px;
  }
  .popover-t2{
    width: 375px;
    font-size: 32px;
    line-height: 48px;
    color: #D54941;
    padding: 24px 32px;
  }
  .step-p{
    font-size: 28px;
    line-height: 40px;
  }
  .step-h3{
    font-size: 28px;
    line-height: 40px;
    font-weight: 400;
  }
  .col5Box{
    margin-top: 24px;
    overflow-x: scroll;
    img{
      width: 160px;
      height: 120px;
      margin-right: 16px;
      object-fit: contain;
    }
  }
  .colBox3{
    padding: 24px;
    margin-top: 32px;
    border-radius: 12px;
    background: #FFFFFF;
    .t1{
      font-size: 32px;
      line-height: 40px;
      font-weight: 900;
      color: #181818;
      padding-bottom: 12px;
    }
    .t2{
      font-size: 28px;
      line-height: 32px;
      color: #383838;
      padding: 12px 0;
    }
    .t3C{
      margin-top: 12px;
      padding: 12px;
      background: #F3F3F3;
      p{
        font-size: 28px;
        line-height: 32px;
        padding: 12px 0;
        color: #383838;
      }
    }
  }
  .margin-t-24{
    margin-top: 24px;
  }
  .topBox{
    padding: 24px;
    .topT1{
      p{
        font-size: 44px;
        font-weight: 600;
        line-height: 44px;
        color: #FFFFFF;
        margin-right: 24px;
      }
      span{
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #0052D9;
        padding: 4px 4px;
        background: #F2F3FF;
        border-radius: 6px;
        margin-right: 12px;
      }
    }
    .topT2{
      font-size: 24px;
      line-height: 24px;
      color: #FFFFFF;
      padding: 12px 0;
    }
  }
  .col-item{
    margin-top: 24px;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 24px 0;
    padding-bottom: 12px;
    h6{
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      margin-left: 24px;
      color: #181818;
    }
    .desc-t1{
      margin-top: 12px;
      color: #383838;
      font-size: 32px;
      line-height: 40px;
      margin-left: 24px;
      span{
        font-size: 32px;
        line-height: 40px;
        color: #5E5E5E;
      }
    }
  }
.ticketBox {
  height: 100%;
  background-color: #EDEDEF;
  background-image: url(https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/detailTicketTopBg.png);
  background-size: 100% 640px;
  background-repeat: no-repeat;
  padding-bottom: 200px;
}
.bottom-box{
  position: fixed;
  bottom: 0px;
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  background: #FFFFFF;
  box-shadow: 0px -8px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 16px 24px;
  z-index: 1;
  padding-bottom: 40px;
  .btn1{
    padding: 28px 28px;
    color: #242424;
    font-size: 32px;
  }
  .btn2,.btn4{
    width: 566px;
    background: #042550;
    border-radius: 12px;
    border: 2px solid #042550;
  }
  .btn3{
    color: #000000;
    width: 343px;
    border: 2px solid #042550;
    border-radius: 12px;
  }
  .btn4{
    width: 343px;
  }
}
</style>
