import { render, staticRenderFns } from "./BaseLayout.vue?vue&type=template&id=16c0b276&scoped=true"
import script from "./BaseLayout.vue?vue&type=script&lang=js"
export * from "./BaseLayout.vue?vue&type=script&lang=js"
import style0 from "./BaseLayout.vue?vue&type=style&index=0&id=16c0b276&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16c0b276",
  null
  
)

export default component.exports