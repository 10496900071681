<!--
 * @Author: “lihui” 13723683480@163.com
 * @Date: 2024-07-26 14:53:52
 * @LastEditors: lihui 13723683480@163.com
 * @LastEditTime: 2024-10-11 22:42:31
 * @FilePath: \an-h5\src\views\AddTicket.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="ticketBox">
    <div ref="stepsBox" class="van-hairline--bottom">
      <!-- <van-steps v-if="dataList.length>1" :active="tabsIndex" active-color="#042550">
        <van-step v-for="(item,i) in dataList" :key="i">{{item.typeName}}</van-step>
      </van-steps> -->
      <steps :dataList="dataList" :active="Number(tabsIndex)"></steps>
    </div>
    <template v-if="!!dataList[tabsIndex]">
      <div v-if="dataList[tabsIndex].typeName != '漆膜检测'">
        <van-tree-select v-if="!!dataList[tabsIndex]" :height="scrollHeight+'px'" :items="dataList[tabsIndex].detectItems" :main-active-index.sync="itemsActive" class="treeDetectCla">
          <template #content>
            <div v-for="(val,n) in dataList[tabsIndex].detectItems[itemsActive].defectList" :key="n" class="right-col-box">
              <div class="flex-sb">
                <p class="right-t1"><span v-if="val.needPhoto ===1" style="color: #D54941;">*</span>{{val.defectName}}</p>
                <!-- <span class="right-t2">判定标准</span> -->
              </div>
              <van-uploader v-model="val.defectPhotos" lazy-load capture="camera" :max-count="10" multiple :before-delete="deleteImg" :after-read="afterRead1">
                <template #preview-cover="file">
                  <div v-if="detailData.recheckFlag ===1 && file.deletable !== false" class="preview-box">
                    <div class="preview-cover van-ellipsis">已修复</div>
                  </div>
                </template>
              </van-uploader>
            </div>
            <div class="zhanwei"></div>
          </template>
        </van-tree-select>
        <div v-if="detailData.recheckFlag!==1" class="fudong-box flex-start van-hairline--top">
          <van-checkbox @click="checkboxChange(dataList[tabsIndex].detectItems[itemsActive].defectFlag)" v-model="dataList[tabsIndex].detectItems[itemsActive].defectFlag" checked-color="#042550">此部位无缺陷</van-checkbox>
        </div>
      </div>
      <div v-else>
        <div class="end-box flex-col-center">
          <h6>漆膜仪</h6>
          <div class="flex-center" :class="'end-c'+selMembrane">
            <i></i>
            <p>{{membraneTypeObj[selMembrane]}}</p>
          </div>
          <van-button @click="connectMembrane" v-if="selMembrane === 0 || selMembrane === 3" square type="primary" class="btn3">连接设备</van-button>
          <van-button v-if="selMembrane === 1" square type="primary" loading loading-text="设备连接中" class="btn4"></van-button>
          <van-button v-if="selMembrane === 2" square type="primary" class="btn3">开始采集数据</van-button>
        </div>
        <div class="dataBox">
          <div class="colBox3">
            <p @click="getDetectFilmValue(123)" class="t1">漆膜数据</p>
            <van-steps v-if="filmList.length" direction="vertical" :active="0" active-color="#042550">
              <van-step v-for="(item,i) in filmList" :key="item.index">
                <div @click="changeFilmItem(i,item.repairFlag)">
                  <div class="flex-sb step-item">
                    <h6>设备数据：{{item.detectValue}}</h6>
                    <span v-if="(detailData.recheckFlag===0 && item.repairFlag ===null) || (detailData.recheckFlag===1 && item.repairFlag ===1)" @click.stop="deteleItem(i)">删除</span>
                  </div>
                  <p class="step-p">漆面缺陷：{{item.defectName}}</p>
                  <div class="flex-start col5Box">
                    <img v-for="(val,n) in item.defectPhotos" :key="n" :src="val.url" alt="">
                  </div>
                </div>
              </van-step>
            </van-steps>
            <van-empty v-else description="暂无检测数据" />
          </div>
        </div>
        
      </div>
    </template>
    

    <div ref="bottomBox" class="bottom-box flex-sb">
      <van-button @click="prevBtn" class="btn1" plain>{{tabsIndex=='0'?'返回':'上一项'}}</van-button>
      <van-button @click="nextBtn()" square type="primary" class="btn2">{{(dataList.length-1) == tabsIndex?'完成':'下一项'}}</van-button>
    </div>

    <van-dialog v-model="dialogShow" title="" :show-confirm-button="false" closeOnClickOverlay>
      <div class="dialogBox">
        <p class="dialog-t1">漆膜数据：{{filmValue}}</p>
        <p class="right-t1">漆面缺陷</p>
        <div class="flex-start dialog-type">
          <p v-for="(item,i) in filmTypeList" :key="i" @click="filmType =i" :class="filmType==i?'sel-type':''">{{item.defectName}}</p>
        </div>
        <p class="right-t1">上传缺陷图片<i v-if="filmTypeList[filmType] && filmTypeList[filmType].needPhoto===1" class="xing">*</i></p>
        <van-uploader v-model="uploaderList" capture="camera" :max-count="6" multiple :before-delete="deleteImg" :after-read="afterRead" />
        <van-button @click="filmSubmit" type="primary" :color="(filmTypeList[filmType] && filmTypeList[filmType].needPhoto===1 &&uploaderList.length===0)?'rgba(190, 190, 190, 0.6)':'#042550'" class="dialog-btn" block>确认</van-button>
      </div>
    </van-dialog>

  </div>
</template>
<script>
  // @ is an alias to /src
  import { Steps,Button,Step,Popup,Picker,TreeSelect,Uploader,Checkbox,Dialog,Empty,ImagePreview } from 'vant'
  import { getDetectItem,deleteFileApi,saveReport } from "@/api";
  import * as OSS from "@/utils/oss";  // 引入oss.js

  import steps from "@/components/steps.vue"
  export default {
    name: 'Home',
    components: {
      [Steps.name]: Steps,
      steps,
      [Button.name]: Button,
      [Step.name]: Step,
      [Popup.name]: Popup,
      [Picker.name]: Picker,
      [TreeSelect.name]: TreeSelect,
      [Uploader.name]: Uploader,
      [Checkbox.name]: Checkbox,
      [Empty.name]: Empty,
      [Dialog.Component.name]: Dialog.Component,
      [ImagePreview.Component.name]: ImagePreview.Component,
    },
    data() {
      return {
        dataList:[],
        dialogShow:false,
        membraneTypeObj:{
          0:'设备未连接',
          1:'设备正在连接中',
          2:'设备已连接',
          3:'连接错误，打开蓝牙'
        }, 
        // 漆膜仪状态 0：未连接 1：连接中 2：连接成功 3：连接失败
        selMembrane:0,
        itemsActive: 0,
        scrollHeight:0,

        detailData:{},
        uploaderList:[],
        tabsIndex:'0',
        filmValue:'',
        filmTypeList:[],
        filmType:0, //
        filmList:[], // 检测数据
        filmIndex:0, // 自增id
        vmFilmId:'',
      }
    },
    created () {
      this.$navBar.setTitle('回用件检测')
      this.$tabBar.setShow(false);
      this.tabsIndex = this.$route.query.tabsIndex;
      this.getDetail();
      if(this.$store.state.app.isConnectFilm){
        this.selMembrane = 2;
      }
      this.$bus.$on('getDetectFilmValue',this.getDetectFilmValue);
    },
    // 无法操作this
    destroyed(){
      this.$bus.$off('getDetectFilmValue');
    },
    methods: {
      changeStepIndex(i){
        this.tabsIndex = i;
      },
      deteleItem(i){
        Dialog.alert({
          title: '请确认删除检测数据',
          message: '删除该条检测数据后，不可恢复',
          showCancelButton:true,
        }).then(() => {
          // on confirm
          this.filmList.splice(i,1);
          this.nextBtn('已删除');
        })
        .catch(() => {
          // on cancel
        });
      },
      changeFilmItem(i,repairFlag){
        if(this.detailData.recheckFlag === 1 && repairFlag ===null){
          let arr = [];
          this.filmList[i].defectPhotos.forEach(item=>{
            arr.push(item.url)
          })
          arr.length && ImagePreview(arr);
        }else{
          this.getDetectFilmValue(this.filmList[i].detectValue);
          this.uploaderList = this.filmList[i].defectPhotos;
          this.filmTypeList.forEach((item,n)=>{
            if(item.defectId == this.filmList[i].defectId){
              this.filmType = n;
            }
          })
          this.vmFilmId = this.filmList[i].uuid;
        }
      },
      filmSubmit(){
        if(this.filmTypeList[this.filmType] && this.filmTypeList[this.filmType].needPhoto===1 && this.uploaderList.length === 0){
          this.$toast('请先上传缺陷图片')
          return ;
        }
        this.dialogShow = false;
        let obj = {
          typeId:this.dataList[this.tabsIndex].typeId,
          typeName:this.dataList[this.tabsIndex].typeName,
          detectItems:[],
        },msg='';
        let defectId = this.dataList[this.tabsIndex].detectItems[0].itemId,defectPhotos=[];
        if(this.vmFilmId){ // 编辑
          this.filmList.forEach(item=>{
            if(item.uuid == this.vmFilmId){
              item.defectName = this.filmTypeList[this.filmType].defectName;
              item.defectId = this.filmTypeList[this.filmType].defectId;
              item.detectValue = this.filmValue;
              item.defectPhotos = this.uploaderList;
            }
          })
        }else{
          this.filmIndex++;
          this.filmList.push({
            index:100+this.filmIndex,
            "defectId": this.filmTypeList[this.filmType].defectId,
            defectName:this.filmTypeList[this.filmType].defectName,
            "detectResult": '',
            "detectValue": this.filmValue,
            "defectPhotos": this.uploaderList,
            repairFlag:this.detailData.recheckFlag === 1?1:null
          })
        }
        
        this.uploaderList = [];
        this.filmType = 0;
        this.nextBtn('已保存');
      },
      getDetectFilmValue(value){
        this.filmValue = value;
        this.dialogShow = true;
        this.vmFilmId = '';
        this.filmType = 0;
      },
      async connectMembrane(){
        var that = this;
        if(this.selMembrane === 0 || this.selMembrane === 3){
          this.selMembrane = 1;
          let address = await window.requestFilmDevice();
          if(address!=-1){
            let errMsg = await window.filmConnect(address);
            if(errMsg){
              that.selMembrane = 3;
              that.$store.state.app.isConnectFilm = false;
              that.membraneTypeObj[3] = errMsg;
            }else{
              that.selMembrane = 2;
            }
          }else{
            that.selMembrane = 3;
            that.$store.state.app.isConnectFilm = false;
            that.membraneTypeObj[3] = '扫描失败';
          }
        }else if(this.selMembrane === 2){
          window.filmDisConnect();
          that.selMembrane = 0;
          that.$store.state.app.isConnectFilm = false;
          that.membraneTypeObj[0] = '设备已断开';
        }
      },
      // 漆膜仪图片回调
      async afterRead(file) {
        file.status = 'uploading';
        file.message = '上传中...';
        file.uuid = Math.floor(Math.random() * 1000000000);
        try {
          // 上传文件
          const result = await OSS.client().put(`vehicle/${file.file.name}`, file.file);
          if(result.res && result.res.status == '200'){
            // result.url = result.url.replace('http://noi-ai.oss-cn-hangzhou.aliyuncs.com','https://f.auto-axc.com');
            file.url = result.url;
            file.status = 'done';
            return ;
          }
        } catch (error) {
          
        }
        file.status = 'failed';
        file.message = '上传失败';
        this.$toast('图片上传,请稍后重试');
        let i = this.uploaderList.findIndex(item=>{
          return item.uuid == file.uuid
        })
        if(i!==-1){
          this.uploaderList.splice(i,1);
        }
      },
      // 正常检测图片回调
      async afterRead1(file) {
        file.status = 'uploading';
        file.message = '上传中...';
        file.uuid = Math.floor(Math.random() * 1000000000);
        try {
          // 上传文件
          const result = await OSS.client().put(`vehicle/${file.file.name}`, file.file);
          if(result.res && result.res.status == '200'){
            // result.url = result.url.replace('http://noi-ai.oss-cn-hangzhou.aliyuncs.com','https://f.auto-axc.com');
            file.url = result.url;
            file.status = 'done';
            this.dataList[this.tabsIndex].detectItems[this.itemsActive].defectFlag = false;
            return ;
          }
        } catch (error) {
          
        }
        file.status = 'failed';
        file.message = '上传失败';
        this.$toast('图片上传,请稍后重试');
        let uuid = Math.floor(Math.random() * 1000000000);
        file.uuid = uuid;
        let n = null;
        let arr = this.dataList[this.tabsIndex].detectItems[this.itemsActive].defectList;
        arr.forEach((item,i)=>{
          item.defectPhotos.forEach(val=>{
            if(val.uuid === uuid){
              n = i;
            }
          })
        })
        let i = arr[n].defectPhotos.findIndex(item=>{
          return item.uuid == file.uuid
        })
        if(i!==-1){
          arr[n].defectPhotos.splice(i,1);
        }
      },
      checkboxChange(e){
        if(e){
          let isShowMsg = false;
          console.log(this.dataList[this.tabsIndex].detectItems[this.itemsActive].defectList);
          this.dataList[this.tabsIndex].detectItems[this.itemsActive].defectList.forEach(item=>{
            if(item.defectPhotos.length>0){
              isShowMsg = true;
            }
          })
          if(isShowMsg){
            Dialog.alert({
              title: '提示',
              message: '此操作会删除缺陷照，请确认操作',
              showCancelButton:true,
            }).then(() => {
              // on confirm
              this.dataList[this.tabsIndex].detectItems[this.itemsActive].defectList.forEach(item=>{
                item.defectPhotos = [];
              })
            })
            .catch(() => {
              // on cancel
              this.dataList[this.tabsIndex].detectItems[this.itemsActive].defectFlag = false;
            });
          }
        }
      },
      deleteImg(file){
        return new Promise((resolve, reject) => {
          resolve(true);
          // deleteFileApi(file.url).then(res=>{
          //   if(res.code == '00000'){
          //     resolve(true);
          //   }
          // })
        })
      },
      getDetail(){
        const toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '获取中',
        });
        getDetectItem(this.$route.query.orderSn).then(res=>{
          toast.clear();
          if(res.code == '00000'){
            this.detailData = res.data;
            let data = res.data.detectItemTypes;
            let derList =[],derList2=[]; // 漆膜检测放最后
            data.forEach(item => {
              if(item.typeName == '漆膜检测'){
                derList.push(item);
                if(item.detectItems){
                  item.detectItems[0].defectList.forEach(val=>{
                    let arr = [];
                    if(val.detectResult == '正常'){
                      val.filmType = 0;
                    }else if(val.detectResult == '钣金'){
                      val.filmType = 1;
                    }else{
                      val.filmType = 2;
                    }
                    if(val.repairFlag!==1 && val.defectPhotos && val.defectPhotos.length>0){
                      val.defectPhotos.forEach(val2=>{
                        arr.push({
                          url:val2,
                          status:'done',
                        })
                      })
                    }
                    if(val.repairFlag===1 && val.repairPhotoList && val.repairPhotoList.length>0){
                      val.repairPhotoList.forEach(val2=>{
                        arr.push({
                          url:val2,
                          status:'done',
                        })
                      })
                    }
                    val.defectPhotos = arr;
                  })
                  let filmTypeList = [];
                  item.detectItems[0].defectList = item.detectItems[0].defectList.filter(val3=>{ 
                    if(!val3.detectValue){
                      filmTypeList.push({
                        defectId:val3.defectId,
                        defectName:val3.defectName,
                        needPhoto:val3.needPhoto
                      })
                    }else{
                      val3.uuid = Math.floor(Math.random() * 1000000000);
                    }
                    return !!val3.detectValue
                  });
                  this.filmTypeList = filmTypeList;
                  this.filmList = item.detectItems[0].defectList;
                }
              }else{
                derList2.push(item)
                if(item.detectItems){
                  item.detectItems.forEach(val=>{
                    val.text = val.itemName;
                    val.defectFlag= val.defectFlag===0?true:false; 
                    val.defectList.forEach(val2=>{
                      let defectPhotosArr = [];
                      if(!val2.defectPhotos) val2.defectPhotos =[];
                      val2.defectPhotos.forEach((val3)=>{
                        defectPhotosArr.push({
                          url:val3,
                          deletable:this.detailData.recheckFlag===1?false:true, // 1：复检(展示已修复标签) 
                          status:'done',
                        })
                      })
                      if(val2.repairPhotoList && val2.repairPhotoList.length>0){
                        val2.repairPhotoList.forEach(val3=>{
                          defectPhotosArr.push({
                            url:val3,
                            status:'done',
                          })
                        })
                      }
                      
                      val2.defectPhotos = defectPhotosArr;
                    })
                  })
                }
            
              }
            });
            this.dataList = [...derList2,...derList];
            this.$nextTick(()=>{
              let navH = document.querySelector('.navBarBox').offsetHeight;
              let stepsH = this.$refs['stepsBox'].offsetHeight;
              let bottomH = this.$refs['bottomBox'].offsetHeight;
              this.scrollHeight = window.outerHeight - bottomH - stepsH - navH;
            })
          }
        })
      },
      prevBtn(){
        if(this.tabsIndex == '0'){
          this.$router.go(-1);
        }else{
          this.tabsIndex --;
        }
      },
      nextBtn(type){
        let obj = {},msg='';
        obj.typeId = this.dataList[this.tabsIndex].typeId;
        obj.typeName = this.dataList[this.tabsIndex].typeName;
        obj.detectItems = [];
        this.dataList[this.tabsIndex].detectItems.forEach(item=>{
        let obj2 = {
          id:item.id,
          itemId:item.itemId,
          itemName:item.itemName,
        };
          let objItem = item.defectList[0];
          obj2.defectList = [];
          if(obj.typeName == '漆膜检测'){
            obj2.defectFlag = 1;
            if(this.filmList.length==0) msg = '漆膜检测数据最少一个';
            this.filmList.forEach((val)=>{
              let imgArr = [];
              val.defectPhotos.forEach(val2=>{
                if(val2.status == 'done'){
                  imgArr.push(val2.url);
                }else if(val2.status == 'uploading'){
                  msg = '文件正在上传，请稍后再试';
                }
              })
              let itemObj = {
                id:val.id,
                defectId:val.defectId,
                defectName:val.defectName,
                detectValue:val.detectValue,
                detectResult:val.detectResult,
              }
              if(val.repairFlag === 1){ // 复检
                itemObj.repairPhotoList = imgArr;
                itemObj.repairFlag = 1;
              }else{ // 初检
                itemObj.defectPhotos = imgArr;
                itemObj.repairFlag = null;
              }
              obj2.defectList.push(itemObj);
            })
          }else{
            obj2.defectFlag = item.defectFlag?0:1;
            console.log('defectFlag=',item.defectFlag);
            if(!item.defectFlag){
              item.defectList.forEach(val=>{
                if(val.needPhoto ===1 && val.defectPhotos.length==0 && msg ===''){
                  // val
                  msg = `请完成【${item.itemName}】`;
                }else{
                  let imgArr = [],repairPhotoList=[];
                  console.log('adq=',val.defectPhotos);
                  val.defectPhotos.forEach(val2=>{
                    if(val2.status == 'done'){
                      if(this.detailData.recheckFlag ===1 && val2.deletable !== false){ // 已修复
                        repairPhotoList.push(val2.url);
                      }else{
                        imgArr.push(val2.url);
                        console.log('imgArr-',imgArr);
                      }
                    }else if(val2.status == 'uploading'){
                      msg = '文件正在上传，请稍后再试';
                    }
                  })
                  let defectObj ={
                    id:val.id,
                    defectId:val.defectId,
                    defectName:val.defectName,
                    detectResult:val.detectResult,
                    defectPhotos:imgArr
                  }
                  if(this.detailData.recheckFlag === 1){ // 复检=》已修复
                    defectObj.repairFlag = repairPhotoList.length>0?1:0;
                    defectObj.repairPhotoList = repairPhotoList;
                  }else{
                    defectObj.repairFlag = null;
                  }
                  obj2.defectList.push(defectObj);
                }
              })
            }else{
              obj2.defectList = [];
            }
          }
          obj.detectItems.push(obj2);
        })
        console.log(obj);
        if(msg){
          this.$toast(msg);
          return ;
        }
        saveReport({
          reportSn:this.detailData.reportSn,
          detectItemTypes:[obj],
          draftStatus:(this.dataList.length-1) ==this.tabsIndex?0:1 // 草稿状态（0：否，1：是）
        }).then(res=>{
          if(res.code =='00000'){
            if(type) {
              this.$toast(type);
              this.getDetail();
              return ;
            }
            this.$toast('已保存');
            if((this.dataList.length-1) ==this.tabsIndex){
              setTimeout(()=>{
                this.$router.go(-1);
              },1000)
            }else{
              this.tabsIndex ++;
              this.itemsActive = 0;
            }
          }
        })
      },
      deleteImg(file){
        return new Promise((resolve, reject) => {
          resolve(true);
          // deleteFileApi(file.url).then(res=>{
          //   if(res.code == '00000'){
          //     resolve(true);
          //   }
          // })
        })
      },
    },
  }
</script>
<style>
  .van-sidebar-item--select::before{
    background-color:#042550;
  }
  .van-sidebar-item--select{
    color: #042550;
  }
  .van-tree-select__nav,.van-sidebar-item{
    background-color: #F3F3F3;
  }
  .van-sidebar-item--select{
    background-color: white;
  }
  .preview-box{
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid #0D57D6;
  }
  .preview-cover {
    position: absolute;
    top: 0px;
    left: 0px;
    box-sizing: border-box;
    padding: 4px 10px;
    color: #fff;
    font-size: 20px;
    background: #0D57D6;
    border-radius: 0px 0px 16px 0px;
  }
  .colBox3 .van-steps{
    background-color: #F7F8F9;
  }
  /* .treeDetectCla .van-tree-select__content{
    height: 80vh;
  } */
</style>
<style lang="less" scoped>
  .xing{
    color: #D54941;
  }
  .dialogBox{
    padding: 32px;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    .dialog-t1{
      text-align: center;
      font-size: 40px;
      line-height: 40px;
      color: #1B1C33;
    }
    .dialog-btn{
      margin-top: 32px;
      border-radius: 12px;
    }
    .dialog-type{
      P{
        width: 158px;
        height: 48px;
        border-radius: 200px;
        padding: 0px 16px;
        box-sizing: border-box;
        background: #F3F3F3;
        font-size: 28px;
        line-height: 28px;
        color: #5E5E5E;
        text-align: center;
        line-height: 48px;
        margin-right: 32px;
      }
      .sel-type{
        color: #FFFFFF;
        background: #042550;
      }
    }
  }
  .dataBox{
    padding: 24px;
    box-sizing: border-box;
  }
  .col5Box{
    margin-top: 16px;
    overflow-x: scroll;
    img{
      width: 160px;
      height: 160px;
      border-radius: 4px;
      margin-right: 16px;
    }
  }
  .step-p{
    font-size: 28px;
    line-height: 40px;
    color: #1B1C33; 
  }
  .step-item{
    h6{
      font-size: 28px;
      line-height: 40px;
      color: #1B1C33;
    }
    span{
      font-size: 26px;
      line-height: 26px;
      color: #9E9E9E;
    }
  }
  .end-box{
    height: 328px;
    h6{
      font-size: 32px;
      font-weight: 900;
      line-height: 32px;
      color: #242424;
      padding-bottom: 16px;
    }
    i{
      width: 8px;
      height: 8px;
      background: #1798C3;
      border-radius: 50%;
      margin-right: 12px;
      margin-bottom: 36px;
    }
    p{
      font-size: 22px;
      line-height: 32px;
      color: #1798C3;
      padding-bottom: 36px;
    }
    .end-c0{
      i{
        background: #9E9E9E;
      }
      p{
        color: #9E9E9E;
      }
    }
    .end-c1{
      i{
        background: #E37318;
      }
      p{
        color: #E37318;
      }
    }
    .end-c2{
      i{
        background: #1798C3;
      }
      p{
        color: #1798C3;
      }
    }
    .end-c3{
      i{
        background: #D54941;
      }
      p{
        color: #D54941;
      }
    }
  }
  .zhanwei{
    height: 96px;
  }
  .fudong-box{
    position: fixed;
    bottom: 140px;
    right: 0px;
    width: 66.66%;
    background-color: white;
    height: 96px;
    padding-left: 24px;
  }
  .right-col-box{
    margin-left: 24px;
  }
  .right-t1{
    padding: 24px 0;
    font-size: 28px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.9);
  }
  .right-t2{
    font-weight: 400;
    font-size: 24px;
    color: #A6A6A6;
    margin-right: 24px;
  }
  .col-box{
    /* padding-bottom: 180px; */
  }
.ticketBox {
  height: 100%;
  padding-bottom: 150px;
  background-color: #FFFFFF;
}
.bottom-box{
  position: fixed;
  bottom: 0px;
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  background: #FFFFFF;
  box-shadow: 0px -8px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 16px 24px;
  padding-bottom: 40px;
  .btn1{
    color: #000000;
    width: 246px;
    border: 2px solid #042550;
    border-radius: 12px;
  }
  .btn2{
    width: 440px;
    background: #042550;
    border-radius: 12px;
    border: 2px solid #042550;
  }
}
.btn3{
  width: 440px;
  background: #042550;
  border-radius: 12px;
  border: 2px solid #042550;
}
.btn4{
  width: 440px;
  background: #F3F3F3;
  border-radius: 12px;
  border: 2px solid #F3F3F3;
  color: #E37318;
}
.colBox3{
  padding: 24px;
  margin-top: 32px;
  border-radius: 12px;
  background: #F7F8F9;
  .t1{
    font-size: 32px;
    line-height: 40px;
    font-weight: 900;
    color: #181818;
    padding-bottom: 12px;
  }
  .t2{
    font-size: 28px;
    line-height: 32px;
    color: #383838;
    padding: 12px 0;
  }
  .t3C{
    margin-top: 12px;
    padding: 12px;
    background: #F3F3F3;
    p{
      font-size: 28px;
      line-height: 32px;
      padding: 12px 0;
      color: #383838;
    }
  }
}
</style>
