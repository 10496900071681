/*
 * @Author: “lihui” 13723683480@163.com
 * @Date: 2024-08-20 17:39:02
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-10-11 17:04:29
 * @FilePath: \an-h5\src\api\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

export const loginApi = (data)=>{
  return request({
    url: '/an-auth/oauth2/token',
    method: 'post',
    params: data,
    headers: {
      Authorization: 'Basic YW4tYWRtaW46MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
}

/**
 * 注销
 */
export const logoutApi = ()=> {
  return request({
    url: '/an-boot/api/v1/users/logout',
    method: 'delete',
  });
}

export const getUserInfo = (data)=>{
  return request({
    url: '/system-boot/api/v1/users/me',
    method: 'get',
  });
}

/**
 * 获取图片验证码
 */
export const getCaptcha = ()=>{
  return request({
    url: '/captcha?t=' + new Date().getTime().toString(),
    method: 'get',
  });
}

/**
 * 上传文件
 * @param file
 */
export const uploadFileApi = (file,handleProgress)=>{
  const formData = new FormData();
  formData.append('file', file);
  return request({
    url: '/system-boot/api/v1/files',
    method: 'post',
    data: formData,
    timeout:300000,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress:progress=>{ // 上传进度条
      if(handleProgress) handleProgress(progress);
    }
  });
}

/**
 * 删除文件
 * @param filePath 文件名
 */
export function deleteFileApi(filePath) {
  return request({
    url: '/system-boot/api/v1/files',
    method: 'delete',
    params: { filePath: filePath },
  });
}


// 回用件类型
export const getVehiclePartTypeList = () => {
  return request({
    url: `vehicle-boot/api/v1/vehiclePart/type/optionList`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

// 回用件可选列表
export const getVehiclePartOptionList = (typeId) => {
  return request({
    url: `/vehicle-boot/api/v1/vehiclePart/optionList/${typeId}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

// 创建工单
export const submitCreateOrder = (data) => {
  return request({
    url: `/vehicle-boot/api/v1/vehiclePartDetect/createOrder`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};


// 直接上传文件到oss
export const directUpload = (data) => {
  return request({
    url: `/system-boot/api/v1/files/directUpload`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

export const getCatBrandsList = () => {
  return request({
    url: `vehicle-boot/api/v1/vehicle/brands`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

export const getCatSeriesList = (id) => {
  return request({
    url: `vehicle-boot/api/v1/vehicle/series/${id}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

export function getCatModelsList(seriesId) {
	return request({
		url: `/vehicle-boot/api/v1/vehicle/models/${seriesId}`,
		method: 'get',
		headers: {
			"Content-Type": "application/json",
		}
	})
}


// 回用件溯源
export function getBtnTraceReport(traceNo){
	return request({
		url:`/vehicle-boot/api/v1/vehiclePart/traceReportByTraceNo/${traceNo}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})
}

// 回用件溯源历史存证接口
export function traceReportHistory(partCode) {
	return request({
		url:`/vehicle-boot/api/v1/vehiclePart/traceReport/history/${partCode}`,
		method: 'get',
		headers: {
			'auth': true // 需要认证
		}
	})
}

// 获取车辆历史存证
export function history(params) {
	return request({
		url: '/vehicle-boot/api/v1/report/trace/history',
		method: 'get',
		params:params,
		headers: {
			'auth': true // 需要认证
		}
	})
}


// 工单列表
export const vehiclePartOrderList = (data) => {
  return request({
    url: `/vehicle-boot/api/v1/vehiclePartDetect/page`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

// 工单详情
export function getvehiclePartDetail(orderSn){
	return request({
		url:`/vehicle-boot/api/v1/vehiclePartDetect/${orderSn}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})
}


// 回用件标签图片
export function getPartLabel(id){
	return request({
		url:`/vehicle-boot/api/v1/reusable/printLabel/PartLabel/${id}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})
}

// 溯源标签图片
export function getTraceabilityLabel(id){
	return request({
		url:`/vehicle-boot/api/v1/reusable/printLabel/traceabilityLabel/${id}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})
}

// 关闭工单
export function setClose(data){
	return request({
		url:`/vehicle-boot/api/v1/vehiclePartDetect/close`,
		method: 'post',
    data,
		headers: { 
			'Content-Type': 'application/json',
		},
	})
}

// 回用件工单检测项
export function getDetectItem(orderSn){
	return request({
		url:`/vehicle-boot/api/v1/vehiclePartDetect/detectItem/${orderSn}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})
}

// 提交检测信息
export const vehiclePartDetectSubmit = (data) => {
  return request({
    url: `/vehicle-boot/api/v1/vehiclePartDetect/submit`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

// 工单开始检测
export const setDetecting = (data) => {
  return request({
    url: `/vehicle-boot/api/v1/vehiclePartDetect/detecting`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};


// 整备
export const setRepair = (data) => {
  return request({
    url: `/vehicle-boot/api/v1/vehiclePartDetect/repair`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

// 出入库
export const changeInboundOrOutbound = (data) => {
  return request({
    url: `/vehicle-boot/api/v1/vehiclePartDetect/inboundOrOutbound/${data.orderSn}`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};



// 保存检测数据
export const saveReport = (data) => {
  return request({
    url: `/vehicle-boot/api/v1/vehiclePartDetect/saveReport`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

// 扫一扫通过code获取详情
export function getCodeDetailItem(partCode){
	return request({
		url:`/vehicle-boot/api/v1/vehiclePartDetect/detail/${partCode}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})
}

// 获取检测报告
export function getReportDetail(reportSn){
	return request({
		url:`/vehicle-boot/api/v1/vehiclePartDetect/report/${reportSn}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})
}

// 扫一扫通过code获取详情
export function getOrderAllNum(){
	return request({
		url:`/vehicle-boot/api/v1/vehiclePartDetect/count`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})
}

// 通过vin码识别车辆信息
export const vinIdentify = (data) => {
  return request({
    url: `/vehicle-boot/api/v1/vinAnalysis/do`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

// 创建复检工单
export const getRecheckOrder = (data) => {
  return request({
    url: `/vehicle-boot/api/v1/vehiclePartDetect/recheck/${data.orderSn}`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

// 通过rfid查数据
export function getByRfId(rfId){
	return request({
		url:`/vehicle-boot/api/v1/vehiclePart/getByRfId/${rfId}`,
		method: 'get',
		headers: { 
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	})
}

// 绑定RfId
export const bindRfId = (data) => {
  return request({
    url: `/vehicle-boot/api/v1/vehiclePart/bindRfId`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};

// 保存回用件标签打印状态
export const partLabelPrintFlag = (data) => {
  return request({
    url: `/vehicle-boot/api/v1/vehiclePartDetect/partLabelPrintFlag`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
      // Authorization: 'Basic YW4tcGM6MTIzNDU2', // 客户端信息Base64明文：an-admin:123456
    },
  });
};







