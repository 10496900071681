<!--
 * @Author: DESKTOP-T047RUH\Administrator 13723683480@163.com
 * @Date: 2024-07-16 14:18:10
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-10-23 09:33:52
 * @FilePath: \vue2-rem\src\views\Home.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home">
    <div class="top1-box flex-sb">
      <div class="flex-start">
        <img class="img1" src="https://f.auto-axc.com/pc-an/an_imgs/admin-user-avatar.png" alt="">
        <div>
          <p class="t1">你好，{{userInfo.nickname}}</p>
          <p class="t2">{{userInfo.deptName}}</p>
        </div>  
      </div>
      <img class="img2" src="https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/home_top1-login.png" alt="">
    </div>
    <div class="flex-center">
      <div class="top2-box">
        <div class="flex-sb top2-t1">
          <p>待办工单</p>
          <span>最近更新：{{vmTime}}</span>
        </div>
        <div class="flex-sa col-box">
          <div @click="to_path('/workList?tabsIndex=1')" class="col-item flex-col-center">
            <p>{{orderTypeNumObj[0]}}</p>
            <span>待检测</span>
          </div>
          <div @click="to_path('/workList?tabsIndex=2')" class="col-item flex-col-center">
            <p>{{orderTypeNumObj[1]}}</p>
            <span>检测中</span>
          </div>
          <div  @click="to_path('/workList?tabsIndex=3')" class="col-item flex-col-center">
            <p>{{orderTypeNumObj[2]}}</p>
            <span>检测完成</span>
          </div>
        </div>
      </div>
    </div>
    
    <div class="flex-center">
      <div class="flex-sb">

        <div @click="connectMembrane" class="top3-col-item" :class="'sel-col'+selMembrane">
          <div class="flex-sb">
            <div>
              <p class="t1">漆膜仪</p>
              <p class="t2">{{membraneTypeObj[selMembrane]}}</p>
            </div>
            <div v-if="selMembrane == 1" class="loadingBox flex-center">
              <van-loading color="#E37318" size="26" />
            </div>
            <img v-else class="img1" :src="'https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/'+(selMembrane==2?'device_open':'dev-ce_clone')+'.png'" alt="">
          </div>
          <img class="img2" src="https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/qmy.png" alt="">
        </div>

        <div @click="connectDevice" class="top3-col-item" :class="'sel-col'+selDevice">
          <div class="flex-sb" style="align-items: flex-start;">
            <div>
              <p class="t1">标签打印机</p>
              <p class="t2">{{deviceTypeObj[selDevice]}}</p>
            </div>
            <div v-if="selDevice == 1" class="loadingBox flex-center">
              <van-loading color="#E37318" size="26" />
            </div>
            <img v-else class="img1" :src="'https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/'+(selDevice==2?'device_open':'dev-ce_clone')+'.png'" alt="">
          </div>
          <img v-if="selDevice!=3" class="img3" src="https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/bqdyj.png" alt="">
        </div>

      </div>
    </div>

    <div class="flex-center">
      <div class="cell-box">
        <van-cell is-link @click="to_path('/workList')">
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <div class="flex-start cell-left-box">
              <img src="https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/home_cell-icon1.png" alt="">
              <span class="custom-title">检测工单管理</span>
            </div>
          </template>
        </van-cell>
        <van-cell is-link @click="to_path('/reusableList')">
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <div class="flex-start cell-left-box">
              <img src="https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/home_cell-icon2.png" alt="">
              <span class="custom-title">回用件管理</span>
            </div>
          </template>
        </van-cell>
        <van-cell is-link @click="register">
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <div class="flex-start cell-left-box">
              <van-icon name="https://b.yzcdn.cn/vant/icon-demo-1126.png" />
              <!-- <img src="https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/home_cell-icon2.png" alt=""> -->
              <span class="custom-title">退出登录</span>
            </div>
          </template>
        </van-cell>
      </div>
    </div>
    <steps :stepInfo="stepInfo"></steps>

    <div class="bottom-box flex-sb">
      <!-- <van-popover v-model="showPopover" trigger="click" placement="top-start" :offset="[0,14]">
        <div>
          <p @click="sweepClick" class="popover-t1">扫描回用件标签</p>
          <p @click="printBtn" class="popover-t1">扫描RFID标签</p>
        </div>
        <template #reference>
          <span class="btn1">扫一扫</span>
        </template>
      </van-popover> -->
      <span @click="sheetShow = true" class="btn6">扫一扫</span>
      <!-- <van-button @click="sweepClick" class="btn1" plain>扫一扫</van-button> -->
      <van-button @click="to_path('/addTicket')" square type="primary" class="btn2">新建工单</van-button>

    </div>

    <van-action-sheet
      v-model="sheetShow"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="onSheetSelect"
    />

  </div>
</template>

<script>
// @ is an alias to /src
import { Cell,Button,Loading,Notify,Icon,Dialog,Popover,ActionSheet } from 'vant'
import {getOrderAllNum,logoutApi} from "@/api";
import { removeUserInfo, removeToken,getUserInfo } from '@/utils/userInfo';
import steps from "@/components/steps.vue"


export default {
  name: 'Home',
  components: {
    steps,
    [Popover.name]: Popover,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Loading.name]: Loading,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
    [Dialog.name]: Dialog,
    [ActionSheet.name]: ActionSheet,
  },
  data() {
    return {
      // 步骤条内容
				stepInfo: {
					list: [{
							name: '发现故障'
						},
						{
							name: '派发工单'
						},
						{
							name: '故障处理'
						},
					],
					step: 2
				},
      sheetShow:false,
      actions: [{ name: '扫描回用件标签' }, { name: '扫描RFID标签' }],
      orderTypeNumObj:{
        0:0, // 待检测
        1:0, // 检测中
        2:0, // 检测完成
      },
      vmTime:'',
      selMembrane:0,  // 漆膜仪状态 0：未连接 1：连接中 2：连接成功 3：连接失败
      membraneTypeObj:{
        0:'设备未连接',
        1:'设备正在连接中',
        2:'设备已连接',
        3:'连接错误，打开蓝牙'
      },
      selDevice:0,    // 打印机状态 0：未连接 1：连接中 2：连接成功 3：连接失败
      deviceTypeObj:{
        0:'设备未连接',
        1:'设备正在连接中',
        2:'设备已连接',
        3:'连接错误，打开蓝牙'
      }
    }
  },
  created () {
    this.$navBar.setShow(false);
    this.$tabBar.setShow(false);
    this.userInfo = getUserInfo();
    this.vmTime = new Date().toLocaleString().split('/').join('-');
    getOrderAllNum().then(res=>{
      if(res.code =='00000'){
        console.log(res.data);
        res.data.forEach(item=>{
          this.orderTypeNumObj[item.orderStatus] = item.count;
        })
      }
    })
  },
  methods: {
    onSheetSelect(value,i){
      if(i === 0){
        this.sweepClick();
      }else{
        this.$store.state.app.isShowRfidMask = true;
        this.$store.state.app.rfidPartCode = '';
        window.requestRFIDDevice();
      }
    },
    register(){
      Dialog.alert({
          title: '提示',
          message: '是否退出登录？',
          showCancelButton:true,
        }).then(() => {
          // logoutApi().then(res=>{
          //   if(res.code == '00000'){
              removeUserInfo(); // 清空本地存储
              removeToken(); // 清空本地token
              this.$store.commit('user/loginOut');
              this.$router.push('/login');
          //   }
          // })
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    sweepClick(){
      window.h5ScanCode();
    },
    async connectMembrane(){
      var that = this;
      if(this.selMembrane === 0 || this.selMembrane === 3){
        this.selMembrane = 1;
        let address = await window.requestFilmDevice();
        if(address!=-1){
          let errMsg = await window.filmConnect(address);
          if(errMsg){
            that.selMembrane = 3;
            that.$store.state.app.isConnectFilm = false;
            that.membraneTypeObj[3] = errMsg;
          }else{
            that.selMembrane = 2;
          }
        }else{
          that.selMembrane = 3;
          that.$store.state.app.isConnectFilm = false;
          that.membraneTypeObj[3] = '扫描失败';
        }
      }else if(this.selMembrane === 2){
        window.filmDisConnect();
        that.selMembrane = 0;
        that.$store.state.app.isConnectFilm = false;
        that.membraneTypeObj[0] = '设备已断开';
      }
    },
    connectDevice(){
      var that = this;
      try {
        if(this.selDevice === 0 || this.selDevice === 3){
          this.selDevice = 1;
          let address = localStorage.getItem('connectDeciceAddress');
          if(address){
            Bridge.interfaces.Android.printerConnect(address).then(function (res) {
              console.log(JSON.stringify(res));
              if(res.code == '00000' && res.data.codeResult){
                that.selDevice = 2;
              }
            }).catch(function (err) {
              that.selDevice = 3;
              that.deviceTypeObj[3] = '未获取到打印机地址，请检查打印机状态';
              console.log("printerConnect=>" + err.message);
            });
          }else{
            Bridge.interfaces.Android.requestPrinterDevice().then(function (s) {
              console.log(JSON.stringify(s));
              if(s.code == '00000' && s.data){
                Bridge.interfaces.Android.printerConnect(s.data[0].address).then(function (res) {
                  console.log(JSON.stringify(res));
                  if(res.code == '00000' && res.data.codeResult){
                    localStorage.setItem('connectDeciceAddress',s.data[0].address);
                    that.selDevice = 2;
                  }
                }).catch(function (err) {
                  that.selDevice = 3;
                  that.deviceTypeObj[3] = '未获取到打印机地址，请检查打印机状态';
                  console.log("printerConnect=>" + err.message);
                });
              }
            }).catch(function (err) {
              that.selDevice = 3;
              that.deviceTypeObj[3] = '未获取到打印机地址，请检查打印机状态';
              console.log("printerConnect=>" + err.message);
            });
          }
          
        }else if(this.selDevice === 2){
          Bridge.interfaces.Android.printerDisConnect().then(function (s) {
            console.log(JSON.stringify(s));
            if(s.code == '00000' && s.data.codeResult){
              that.selDevice = 0;
              that.deviceTypeObj[0] = '设备已断开';
            }
          }).catch(function (err) {
            console.log("printerDisConnect=>" + err.message);
            Notify(err.message);
          });
        }
      } catch (error) {
        Notify(error);
      }
    },
    to_path(url){
      this.$router.push({
        path:url,
      });
    }
  },
}
</script>
<style>
  
</style>
<style lang="less" scoped>
@import '~@/styles/mixins.less';

.loadingBox{
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: #FFD9C2;
}
.bottom-box{
  position: fixed;
  bottom: 0px;
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  background: #FFFFFF;
  box-shadow: 0px -8px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 16px 24px;
  padding-bottom: 40px;
  .btn1{
    color: #000000;
    width: 343px;
    border: 2px solid #042550;
    border-radius: 12px;
  }
  .btn6{
    display: inline-block;
    padding: 28px 28px;
    color: #242424;
    font-size: 32px;
  }
  .btn2{
    width: 526px;
    background: #042550;
    border-radius: 12px;
    border: 2px solid #042550;
  }
}
.cell-box{
  width: 686px;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 24px;
  .cell-left-box{
    img{
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }
  }
}
.flex-sb .sel-col3{
  .t2{
    color: #D54941;
    &::after{
      background-color: #D54941;
    }
  }
}
.flex-sb .sel-col2{
  background: linear-gradient(125deg, #D9F5FF 0%, #D9F5FF 0%, rgba(228, 248, 255, 0.75) 15%, #FFFFFF 54%);
  .t2{
    color: #1798C3;
    &::after{
      background-color: #1798C3;
    }
  }
}
.flex-sb .sel-col1{
  .t2{
    color: #E37318;
    &::after{
      background-color: #E37318;
    }
  }
}
.flex-sb .sel-col0{
  .t2{
    color: #9E9E9E;
    &::after{
      background-color: #9E9E9E;
    }
  }
}
.top3-col-item{
  width: 337px;
  height: 240px;
  padding: 24px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.6);
  margin-top: 24px;
  &:nth-child(1){
    margin-right: 12px;
  }
  .t1{
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
    color: #242424;
  }
  .t2{
    font-size: 22px;
    line-height: 32px;
    color: #9E9E9E;
    margin-top: 12px;
    padding-left: 20px;
    position: relative;
    &::after{
      content: " ";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      left: 5px;
      top: 11px;
      background-color: #A6A6A6;
    }
  }
  .img1{
    width: 64px;
    height: 64px;
  }
  .img2{
    width: 53px; 
    height: 90px;
    display: block;
    margin-top: 24px;
  }
  .img3{
    width: 89px; 
    height: 62px;
    display: block;
    margin-top: 36px;
  }
}
.top2-box{
  background: #FFFFFF;
  padding: 32px 0px;
  border-radius: 12px;
  width: 686px;
  margin-top: -60px;
  .top2-t1{
    padding: 0 32px;
    p{
      font-size: 32px;
      font-weight: 500;
      line-height: 32px;
      color: #242424;
    }
    span{
      font-size: 22px;
      line-height: 32px;
      color: #9E9E9E;
      position: relative;
      &::after{
        content: " ";
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        left: -20px;
        top: 11px;
        background-color: #2BA471;
      }
    }
  }
  .col-box{
    margin-top: 40px;
    .col-item{
      p{
        font-size: 40px;
        font-weight: 600;
        line-height: 40px;
        color: #242424;
        padding-bottom: 16px;
      }
      span{
        font-size: 26px;
        line-height: 26px;
        color: #5E5E5E;
      }
    }
  }
}
.top1-box{
 background-image: url(https://noi-ai.oss-cn-hangzhou.aliyuncs.com/an_h5/home_top1-bg.png); 
 background-size: 100% 100%;
 height: 245px;
 padding: 0 32px;
 padding-bottom: 62px;
 .img1{
  width: 80px;
  height: 80px;
  margin-right: 16px;
 }
 .img2{
  width: 192px;
  height: 64px;
 }
 .t1{
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  color: #FFFFFF;
 }
 .t2{
  margin-top: 12px;
  font-size: 22px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.8);
 }
}
.home {
  height: 100vh;
  background: #F2F3F5;
}

</style>
