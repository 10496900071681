/*
 * @Author: “lihui” 13723683480@163.com
 * @Date: 2024-07-16 14:18:10
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-10-14 14:42:37
 * @FilePath: \an-h5\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { Dialog } from 'vant';
import store from "@/store"



import BaseLayout from '@/layout/BaseLayout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'Login',
        hidden: false,
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Login.vue'),
        meta: {
          title: '登录',
          showTabBar: true
          // hiddenNavBar: false,
          // navBarLeftText: '',
          // navBarHiddenLeftArrow: '',
          // navBarRightText: '',
          // navBarRightIcon: ''
        }
      }
    ]
  },

  {
    path: '/home',
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'Home',
        hidden: false,
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
        meta: {
          title: '主页',
          showTabBar: true
          // hiddenNavBar: false,
          // navBarLeftText: '',
          // navBarHiddenLeftArrow: '',
          // navBarRightText: '',
          // navBarRightIcon: ''
        }
      }
    ]
  },
  {
    path: '/workList',
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'WorkOrderList',
        hidden: false,
        component: () => import(/* webpackChunkName: "Home" */ '@/views/WorkOrderList.vue'),
        meta: {
          title: '工单列表',
          showTabBar: true
          // hiddenNavBar: false,
          // navBarLeftText: '',
          // navBarHiddenLeftArrow: '',
          // navBarRightText: '',
          // navBarRightIcon: ''
        }
      }
    ]
  },
  {
    path: '/addTicket',
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'AddTicket',
        hidden: false,
        component: () => import(/* webpackChunkName: "Home" */ '@/views/AddTicket.vue'),
        meta: {
          title: '创建工单',
          showTabBar: true
          // hiddenNavBar: false,
          // navBarLeftText: '',
          // navBarHiddenLeftArrow: '',
          // navBarRightText: '',
          // navBarRightIcon: ''
        }
      }
    ]
  },
  {
    path: '/detailTicket',
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'DetailTicket',
        hidden: false,
        component: () => import(/* webpackChunkName: "Home" */ '@/views/DetailTicket.vue'),
        meta: {
          title: '检测工单详情',
          showTabBar: true
          // hiddenNavBar: false,
          // navBarLeftText: '',
          // navBarHiddenLeftArrow: '',
          // navBarRightText: '',
          // navBarRightIcon: ''
        }
      }
    ]
  },
  {
    path: '/detectTicket',
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'DetectTicket',
        hidden: false,
        component: () => import(/* webpackChunkName: "Home" */ '@/views/DetectTicket.vue'),
        meta: {
          title: '检测详情',
          showTabBar: true
          // hiddenNavBar: false,
          // navBarLeftText: '',
          // navBarHiddenLeftArrow: '',
          // navBarRightText: '',
          // navBarRightIcon: ''
        }
      }
    ]
  },
  {
    path: '/reusablePartsDetect',
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'ReusablePartsDetect',
        hidden: false,
        component: () => import(/* webpackChunkName: "Home" */ '@/views/ReusablePartsDetect.vue'),
        meta: {
          title: '回用件详情',
          showTabBar: true
          // hiddenNavBar: false,
          // navBarLeftText: '',
          // navBarHiddenLeftArrow: '',
          // navBarRightText: '',
          // navBarRightIcon: ''
        }
      }
    ]
  },
  {
    path: '/reusableList',
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'ReusableOrderList',
        hidden: false,
        component: () => import(/* webpackChunkName: "Home" */ '@/views/ReusableOrderList.vue'),
        meta: {
          title: '回用件管理',
          showTabBar: true
          // hiddenNavBar: false,
          // navBarLeftText: '',
          // navBarHiddenLeftArrow: '',
          // navBarRightText: '',
          // navBarRightIcon: ''
        }
      }
    ]
  },
  {
    path: '/testReport',
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'TestReport',
        hidden: false,
        component: () => import(/* webpackChunkName: "Home" */ '@/views/TestReport.vue'),
        meta: {
          title: '检测报告',
          showTabBar: true
          // hiddenNavBar: false,
          // navBarLeftText: '',
          // navBarHiddenLeftArrow: '',
          // navBarRightText: '',
          // navBarRightIcon: ''
        }
      }
    ]
  },

  {
    path: '/traceability',
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'Traceability',
        hidden: false,
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Traceability.vue'),
        meta: {
          title: '检测报告',
          showTabBar: true
          // hiddenNavBar: false,
          // navBarLeftText: '',
          // navBarHiddenLeftArrow: '',
          // navBarRightText: '',
          // navBarRightIcon: ''
        }
      }
    ]
  },
  
  
  {
    path: '/carList',
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'CarList',
        hidden: false,
        component: () => import(/* webpackChunkName: "Home" */ '@/views/CarList.vue'),
        meta: {
          title: '车辆选择',
          showTabBar: true
          // hiddenNavBar: false,
          // navBarLeftText: '',
          // navBarHiddenLeftArrow: '',
          // navBarRightText: '',
          // navBarRightIcon: ''
        }
      }
    ]
  },
  

  {
    path: '/about',
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'About',
        component: () => import(/* webpackChunkName: "About" */ '@/views/About.vue'),
        meta: { title: '我的', showTabBar: true, hiddenNavBar: true }
      }
    ]
  },

  {
    path: '/inner',
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'Inner',
        component: () => import(/* webpackChunkName: "Inner" */ '@/views/Inner.vue'),
        meta: { title: '跳转中' }
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 在你的Vue应用初始化之后，添加路由守卫
router.beforeEach((to, from, next) => {
  // 新建工单跳详情后回退时回到首页
  if(to.path == "/addTicket" && from.path == '/detailTicket' ){
    next({ path: '/home' });
  }else if(to.path == '/inner' && from.path == '/detailTicket'){ // 整备完成后回到工单页
    let innerType = localStorage.getItem('innerType');
    if(innerType =='1') {
      localStorage.removeItem('innerType');
      next();
    }else{
      localStorage.removeItem('innerType');
      router.go(-2);
    }
  }else if(from && from.path == '/detectTicket' && to.path != '/reusablePartsDetect' && to.path != '/login') {
    // 如果你想拦截返回操作，可以直接调用next(false)
    if(store.state.app.isDetectDataChange){
      Dialog.confirm({
        title: '返回后，当前信息将被保存',
        message: '是否继续退出',
        closeOnPopstate:false, // 是否在页面回退时自动关闭	
      }).then(() => {
          // on confirm
          Vue.prototype.bus.$emit('detectTicketSaveBtnInfo',next);
      })
      .catch(() => {
        next(false); // 如果你想拦截，取消返回操作
      });
    }else{
      next();
    }
  }else if(to.path == '/login' && store.state.user.token){
    next(false);
  }else{
    // 如果不拦截，调用next()继续路由跳转
    next();
  }
});

//全局后置守卫：初始化时执行、每次路由切换后执行
router.afterEach((to) => { // (to, from)
  document.body.scrollTo(0,0)
});

export default router
