<!--
 * @Author: “lihui” 13723683480@163.com
 * @Date: 2024-09-10 11:44:20
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-10-18 10:30:00
 * @FilePath: \an-h5\src\divs\Traceability.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<!-- https://noiai.com/#/traceability/detail2?tabsIndex=0&traceId=PT2024303202325 -->
<template>
  <div class="page">
    <div class="detail">
      <div class="detail_head"></div>
      <div class="common mb32 infoPd">
        <div class="common_title fn36 mb20">基本信息</div>
        <div class="common_line mt32 color">
          <div class="label weight">存证类型：</div>
          <div class="common_line_value valueFn weight">{{typeObj[info.certificateType]}}</div>
        </div>
        <div class="common_line mt32 color">
          <div class="label weight">存证机构：</div>
          <div class="common_line_value valueFn weight">中汽研汽车科技发展（天津）有限公司</div>
        </div>
        <div class="common_line mt32 color">
          <div class="label weight jigouCla">委托存证机构：</div>
          <div class="common_line_value valueFn weight">{{info.mechanismName}}</div>
        </div>
        <div class="common_line mt32 color">
          <div class="label">存证编号：</div>
          <div class="common_line_value valueFn">{{info.traceNo}}</div>
        </div>
        <div class="common_line mt32 color">
          <div class="label">存证时间：</div>
          <div class="common_line_value valueFn">{{info.traceTime}}</div>
        </div>
        <div class="common_line mt32 color">
          <div class="label">查询次数：</div>
          <div class="common_line_value valueFn">{{info.queryNum}}次</div>
        </div>
      </div>

      <div class="common mb32 infoCar">
        <div class="common_title fn36 mb20">溯源报告详情</div>
        <div class="traceBox mt24">
          <div class="traceBox_title">
            溯源查证报告
          </div>
          <div class="traceLine mt16">溯源编码：{{info.traceNo}}</div>
          <div class="traceLine mt16 flex-start-2">区块链编码：<span class="qukuaiW">{{info.blockchainNo}}</span></div>
          <div class="line mt32 mb32"></div>
          <div class="traceCar">
            <img :src="tabsIndex == '0'?info.vehiclePartPicture:info.vehiclePicture"></img>
            <!-- 二手车检测 -->
            <template v-if="tabsIndex=='1'">
              <div class="carName mt24">{{info.brandName}} {{info.modelName}} {{info.seriesName}}</div>
              <div class="carCode mt16">车辆VIN码：{{info.vin2}}</div>
              <div class="carCode mt16">查询时间：{{info.detectTime}}</div>
            </template>
            <!-- 回用件溯源 -->
            <template v-else>
              <div class="carName mt24">{{info.partName}}</div>
              <div class="carCode mt16">部件编码：<span class="codeW">{{info.partCode}}</span></div>
              <div class="carCode mt16">质量等级：<span class="codeW">{{info.resultLevel?columns2[info.resultLevel-1]:'-'}}</span></div>
            </template>
          </div>
          <div class="carInfo mt48">
            <div class="infoText">该报告由</div>
            <div class="infoText mt16">【{{info.mechanismName}}】</div>
            <div class="infoText mt16">出具的检验认证评估报告</div>
            <div class="line mt32 mb32"></div>
            <div class="common_line mt32 color">
              <div class="label">报告编码</div>
              <div class="common_line_value valueFn">{{info.traceNo}}</div>
            </div>
            <div class="common_line mt32 color">
              <div class="label">报告日期</div>
              <div class="common_line_value valueFn">{{info.reportTime}}</div>
            </div>
            <div class="common_line mt32 color">
              <div class="label">检测师</div>
              <div class="common_line_value valueFn">{{info.inspectorName}}</div>
            </div>
            <template v-if="tabsIndex=='1'">
              <div class="common_line mt32 color">
                <div class="label">检测日期</div>
                <div class="common_line_value valueFn">{{info.detectTime}}</div>
              </div>
              <div class="common_line mt32 color">
                <div class="label">品牌车型</div>
                <div class="common_line_value valueFn">{{info.brandName}} {{info.modelName}} {{info.seriesName}}</div>
              </div>
              <div class="common_line mt32 color">
                <div class="label">VIN码</div>
                <div class="common_line_value valueFn">{{info.vin2}}</div>
              </div>
            </template>
            <template v-else>
              <div class="common_line mt32 color">
                <div class="label">溯源部件</div>
                <div class="common_line_value valueFn">{{info.partName}}</div>
              </div>
              <div class="common_line mt32 color">
                <div class="label">查询时间</div>
                <div class="common_line_value valueFn">{{info.queryTime}}</div>
              </div>
              <div class="common_line mt32 color">
                <div class="label">源头车辆</div>
                <div class="common_line_value valueFn">{{info.modelName}}</div>
              </div>
            </template>
            
          </div>

          <div class="carBtn">
            <div class="btn mr12" v-if="tabsIndex=='1' && info.reportPdfUrl" @click="openUrl(info.reportPdfUrl)">查看在线报告<u-icon name="arrow-right" color="#B0783F" size="12"></u-icon></div>
            <div class="btn mr12" v-if="tabsIndex == '0' && info.certificateType!==1" @click="openUrl(info.reportH5Url)">查看在线报告<u-icon name="arrow-right" color="#B0783F" size="12"></u-icon></div>
            <div class="btn" v-if="tabsIndex == '0' && info.certificateType==1" @click="getHistory">历史存证<u-icon name="arrow-right" color="#B0783F" size="12"></u-icon></div>
          </div>
        </div>
      </div>

      <div class="textWord">
        <div class="textWord_title">溯源查证是什么？</div>
        <div class="textWord_content mt36"
          >溯源查证产品能力提供了全面的数据保护解决方案，确保您的业务数据不受篡改和损害。通过利用先进的加密技术和数字签名，帮助您实现数据的完整性验证和来源溯源，满足合规性要求，保护知识产权，以及提升商业信任度。无论汽车检测报告领域还是在其他行业，我们的产品都能为您提供可信赖的数据安全保障，并提供可信的证据链，以维护您的利益并降低法律风险。</div
        >
      </div>

      <van-action-sheet v-model="show" title="历史存证">
        <div class="steps-box">
          <van-steps direction="vertical" :active="0" active-color="#042550">
            <van-step v-for="(item,key) in popupList" :key="key">
              <h3 class="step-h3">{{item.traceTime}} 操作员：{{item.operatorName}}</h3>
              <p class="step-p">公司信息：{{item.mechanismName}}</p>
              <p class="step-p">节点：{{nodeObj[item.node]}}</p>
              <p class="step-p">存证类型：{{typeObj[info.certificateType]}} <span v-if="item.reportH5Url" @click="openUrl(item.reportH5Url)" class="baogaoCss">查看报告</span></p>
            </van-step>
          </van-steps>
        </div>
      </van-action-sheet>

    </div>
    
  </div>
</template>
<script>
  import { getBtnTraceReport,traceReportHistory } from "@/api/index";
  import { Notify,ActionSheet,Steps,Step } from 'vant';

export default {
  components: {
    [ActionSheet.name]: ActionSheet,
    [Steps.name]: Steps,
    [Step.name]: Step,
  },
  data() {
    return {
      columns2:['一级','二级','三级','四级'],
      loadShow:false,
      closeOnClickOverlay: false,
      show: false,
      bgColor: "transparent",
      fontColor:'#000',
      height:'',
      requestData:{},
      info:{},
      popupList:[],
      traceId:'',
      typeObj:{
        0:'车辆检测报告',
        1:'报废机动车回用件'
      },
      tabsIndex:'0', // 0:回用件溯源  1:二手车检测
      nodeObj:{
        '1':'整备',
        '2':'检测',
        '3':'入库',
        '4':'出库',
      },
      isRequery:true, // 是否请求正常
      option:{}
    }
  },
  created() {
    this.$navBar.setShow(false)
    this.$tabBar.setShow(false);
    this.tabsIndex = this.$route.query.tabsIndex;
    if(this.$route.query.traceId){
      getBtnTraceReport(this.$route.query.traceId).then((res) => {
        if (res.code == '00000') {
          if(res.data.vin){
            res.data.vin2 = `${res.data.vin.slice(0,3)}${'*'.repeat(res.data.vin.length - 9)}${res.data.vin.slice(-6)}`;
          }
          this.info = res.data
        }
      });
    }else{
      Notify({ type: 'warning', message: '缺少参数' });
    }
  },
  methods: {
    getHistory() {
			if(this.tabsIndex == '0'){ // 回用件溯源历史存证
				traceReportHistory(this.info.partCode).then((res) => {
					if (res.code == '00000') {
						this.popupList = res.data
            this.show = true;
					}
				});
			}else{ // 二手车检测历史存证
				history({vin:this.info.vin}).then((res) => {
				  if (res.code == '00000') {
				    this.popupList = res.data
            this.show = true;
				  }
				});
			}
    },
    openUrl(url){
      window.open(url)
    }
  },
}
</script>
<style lang="less" scoped>
  .qukuaiW{
    margin-top: -5px;
    width: 490px;
    display: inline-block;
    text-align: left;
    word-wrap: break-word;
  }
  .codeW{
    width: 290px;
    display: inline-block;
    text-align: left;
  }
  .baogaoCss{
    color: #093F87;
    margin-left: 40px;
  }
  .steps-box{
    padding: 40px;
  }
  .step-p{
    font-size: 28px;
    line-height: 40px;
    color: #1B1C33;
  }
  .step-h3{
    font-size: 28px;
    line-height: 40px;
    font-weight: 400;
    color: #8D8E99;
  }
  .mt48{
    margin-top: 48px;
  }
  .mt24{
    margin-top: 24px;
  }
  .mt32{
    margin-top: 32px;
  }
  .mt16{
    margin-top: 16px;
  }
  .mt36{
    margin-top: 36px;
  }
  .mb32{
    margin-bottom: 32px;
  }
  .fn36{
    font-size: 36px;
  }
  .mb20{
    margin-bottom: 20px;
  }
  .common {
  padding: 48px 32px;
  background: #fff;
  &_title {
    font-size: 32px;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 48px;
  }
  &_line {
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
    &_label {
      font-size: 28px;
      font-weight: normal;
      color: #1b1d33;
      min-width: 144px;
    }
    &_value {
      font-size: 28px;
      font-weight: normal;
      color: #8d8e99;
      text-align: right;
    }
  }
}
  .baogaoCss{
    font-size: 28px;
    color: #093F87;
    margin-left: 40px;
  }
.page {
  background: #f7f7f7;
  padding-bottom: 30upx;
}
.weight{
  font-weight: 600!important;
  color: #181818!important;
}
.detail {
  &_head {
    width: 750px;
    height: 460px;
    background-image: url("https://f.auto-axc.com/images/icon_trace_bg_new.png");
    background-size: 100% 100%;
    text-align: center;
    padding: 40px 32px;
    
  }

  &_line{
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #F3F3F3;
    height: 80px;
    padding-left: 34px;
    &_item{
      font-family: PingFang SC;
      font-size: 28px;
      font-weight: normal;
      color: #181818;
      display: flex;
      align-items: center;
      margin-right: 24px;
      image{
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }
    }
  }

  .errImage{
    width: 400px;
    height: 141px;
    margin: 95px 175px 40px 175px;
  }
}
.jigouCla{
  width: 230px;
}
.traceBox{
  width: 686px;
  height: max-content;
  background-image: url("https://noi-ai.oss-cn-hangzhou.aliyuncs.com/images/admin/traceability-c-bg.png");
  background-size: 100% 100%;
  padding: 40px 32px;
  padding-bottom: 130px;
  position: relative;
  &_title{
    font-size: 36px;
    font-weight: 600;
    color: #B0783F;
  }
  .traceLine{
    font-size: 22px;
    font-weight: normal;
    color: #C9925B;
  }
  .line{
    border: 1px dashed #F2DDBE;
  }
  .traceCar{
    text-align: center;
    img{
      width: 236px;
      height: 176px;
      border-radius: 12px;
    }
    .carName{
      font-size: 28px;
      font-weight: 500;
      color: #181818;
    }
    .carCode{
      font-size: 24px;
      font-weight: normal;
      color: #5E5E5E;
    }
  }

  .carInfo {
    border: 1px solid #F2DDBE;
    background: #FFF7EC;
    padding: 24px 36px;
    .infoText{
      text-align: center;
      font-size: 28px;
      font-weight: 600;
      color: #B0783F;
    }
  }
  .carBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 686px;
    bottom: 46px;
    left: 0;
    .btn{
      border-radius: 28px;
      border: 2px solid #B0783F;
      padding: 12px 24px;
      border-radius: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: 500;
      color: #B0783F;
      justify-items: center;
    }
  }
}

.textWord {
  padding: 36px 24px;
  background: #fff;
  border-radius: 6px;
  &_title {
    font-size: 32px;
    font-weight: 600;
    color: #181818;
  }
  &_content {
    font-size: 24px;
    font-weight: normal;
    line-height: 36px;
    color: #5e5e5e;
  }
}

.popupTitle{
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin: 32px;
  justify-content: space-between;
}

.over{
		max-height: 600px;
    overflow-y: auto;
}

.popupItem{
  border-left: 2px dashed #DCDCDC;
  position: relative;
  padding-left: 58px;
  margin: 0px 58px;
  .position {
    position: absolute;
    top:-20px;
  }
  .time{
    font-size: 28px;
    font-weight: normal;
    color: #8D8E99;
  }
  .vin{
    font-size: 28px;
    font-weight: normal;
    color: #1B1C33;
  }
  .icon{
    background: #BFBFBF;
    width:12px;
    height:12px;
    border-radius: 100%;
    position: absolute;
    top:-3px;
    left:-6px;
  }
}
  

.infoPd{
  padding: 52px 32px 32px 32px;
  margin-top: -50px;
  border-radius: 32px 32px 0px 0px;
}

.infoCar{
  padding: 52px 32px 32px 32px;

}
.color{
  color: #181818;
  display: flex;
  justify-content: left;
}
.label{
  width: 192px;
  min-width: 192px;
  font-size: 28px;
  color: #5E5E5E;
}
.valueFn{
  font-size: 28px;
  color: #181818;
  text-align: left;
}
.flex {
  display: flex;
  justify-content: flex-end;
}
</style>