const OSS = require('ali-oss'); // 引入ali-oss依赖
 
export function client() {  
  const client = new OSS({
    region: 'oss-cn-hangzhou',
    accessKeyId: 'LTAI4FybL3uB714RDtguyxfr',
    accessKeySecret: 'jqJTpWxP65eGPX5NJWGndhtSsTMbGV',
    bucket: 'noi-ai',
  })  
  return client
};