/*
 * @Author: “lihui” 13723683480@163.com
 * @Date: 2024-07-16 14:18:10
 * @LastEditors: lihui 13723683480@163.com
 * @LastEditTime: 2024-10-06 23:53:01
 * @FilePath: \an-h5\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Toast,Lazyload } from 'vant';

// 适配
import 'amfe-flexible'

// 全局 css
import './styles/index.less'

// plugins
import navBar from '@/plugins/nav-bar'
import tabBar from '@/plugins/tab-bar'

Vue.config.productionTip = false

Vue.use(navBar)
Vue.use(tabBar)
Vue.use(Toast);
Vue.use(Lazyload, {
  lazyComponent: true,
})
Vue.prototype.bus = new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
		Vue.prototype.$bus = this;
	},
}).$mount('#app')
