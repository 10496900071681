/*
 * @Author: “lihui” 13723683480@163.com
 * @Date: 2024-07-16 14:18:10
 * @LastEditors: lihui 13723683480@163.com
 * @LastEditTime: 2024-09-20 23:53:15
 * @FilePath: \an-h5\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import store from '@/store';
import router from '@/router';
import { removeUserInfo, removeToken } from '@/utils/userInfo';
import { Toast } from 'vant';
// 创建axios实例
const service = axios.create({
	// 基础路径
	// baseURL: 'https://apipc-xiaotuxian-front.itheima.net/', // 上线请求地址
	// baseURL: 'http://pcapi-xiaotuxian-front-devtest.itheima.net/',
	baseURL: process.env.VUE_APP_BASE_API,
	// baseURL: '/',
	// 超时时间
	timeout: 60000,
});

service.interceptors.request.use(
  config => {
    // 如果有token进行头部携带 | 没有则后续操作通知用户登录
		if (store.state.user.token) {
			config.headers.Authorization = `Bearer ${store.state.user.token}`;
		}

    return config
  }, error => {
    console.log(error)
    return Promise.reject(error)
  })

service.interceptors.response.use(
  response => {
    const res = response.data
    if(res.code !='00000'){
      Toast(res.msg);
    }
    return res
  },
  error => {
    if (error.response && error.response.status === 401) {
      removeUserInfo(); // 清空本地存储
			removeToken(); // 清空本地token
			store.commit('user/loginOut');
			router.push('/login');
			return error.response;
    }else{
      Toast(error.response.data.msg);
    }
    return Promise.reject(error)
  })

export default service
