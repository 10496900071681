<!--
 * @Author: lihui 13723683480@163.com
 * @Date: 2024-09-09 20:44:36
 * @LastEditors: “lihui” 13723683480@163.com
 * @LastEditTime: 2024-10-23 09:35:03
 * @FilePath: \an-h5\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <router-view :key="$route.path" />
  </div>
</template>
<script>
import { getPartLabel,getTraceabilityLabel,getCodeDetailItem,getByRfId } from '@/api'
export default {
  data() {
    return {
      scanToast:null,
      timeId:null,
    }
  },
  created() {
    window.h5ScanCode = this.scanCode; // 扫一扫
    window.cancelH5ScanCode = this.cancelScanCode; // 取消扫一扫
    window.deviceConnect = this.deviceConnect; // 连接打印机
    window.printerStatusInfo = this.printerStatusInfo; // 检查打印机状态
    window.setPrintDevice = this.setPrintDevice; // 打印标签使用
    window.requestFilmDevice = this.requestFilmDevice; // 扫描漆膜仪
    window.filmConnect = this.filmConnect; // 连接漆膜仪
    window.filmDisConnect = this.filmDisConnect; // 漆膜仪断连
    window.setFilmH5Value = this.setFilmH5Value; // 漆膜仪value设置
    window.printerPrintDevice = this.printerPrintDevice; // 打印标签
    window.requestRFIDDevice = this.requestRFIDDevice; // 扫描RFID
  },
  methods: {
    requestRFIDDevice(){
      var that = this;
      that.$store.state.app.rfidList = [];
      that.$store.state.app.isLoadingRfidRes = true;
      // var s = {"code":"00000","data":[{"epc":"19911191A5030061FC6192F6","rssi":"90","tid":""},{"epc":"00124700A5030061FC6192FD","rssi":"68","tid":""},{"epc":"E2801191A5030061FC6192F8","rssi":"97","tid":""},{"epc":"12343536A5030061FC6192FC","rssi":"88","tid":""},{"epc":"92314567A5030061FC6192F7","rssi":"84","tid":""},{"epc":"90001191A5030061FC6192FA","rssi":"102","tid":""},{"epc":"92311191A5030061FC6192F9","rssi":"90","tid":""}]}
      return new Promise((resolve)=>{
        Bridge.interfaces.Android.requestRFIDDevice().then(function (s) {
          that.$store.state.app.isLoadingRfidRes = false;
          that.$toast('扫描rfid成功',JSON.stringify(s));
          console.log(JSON.stringify(s));
          if(s.code == '00000' && s.data){
            let list =[],allNum =5;
            s.data.sort(function(a,b){
              return Number(b.rssi) - Number(a.rssi)
            })
            s.data.forEach(item=>{
              allNum--;
              if(allNum<0) return ;
              item.detail = null;
              list.push(item);
            })
            list.forEach(item=>{
              getByRfId(item.epc).then(res=>{
                if(res.code =='00000'){
                  item.detail = res.data;
                }
              })
            })
            console.log('2233=',list);
            that.$store.state.app.rfidList = list;
            resolve(list);
          }
        }).catch(function (err) {
          that.$store.state.app.isLoadingRfidRes = false;
          that.$toast('请检查手持设备是否已开启');
          console.log('扫描rfid失败',JSON.stringify(err));
          resolve(-1);
        });
      })
    },
    setFilmH5Value(value){
      this.$toast(value);
      if(this.$route.path == '/reusablePartsDetect'){
        this.$bus.$emit('getDetectFilmValue',value);
      }
    },
    // 扫描漆膜仪
    requestFilmDevice(){
      var that = this;
      return new Promise((resolve)=>{
        Bridge.interfaces.Android.requestFilmDevice().then(function (s) {
          console.log(JSON.stringify(s));
          that.$toast('扫描漆膜仪成功',JSON.stringify(s));
          if(s.code == '00000' && s.data){
            resolve(s.data[0].address);
          }
        }).catch(function (err) {
          resolve(-1);
          that.$toast('扫描漆膜仪失败',err.message);
          console.log("openCamera=>" + err.message);
        });

      })      
    },
    // 漆膜仪连接
    filmConnect(address){
      const that = this;
      return new Promise((resolve)=>{
        Bridge.interfaces.Android.filmConnect(address).then(function (s) {
          console.log(JSON.stringify(s));
          that.$toast('连接漆膜仪成功',JSON.stringify(s));
          if(s.code == '00000' && s.data && s.data.codeResult){
            that.$store.state.app.isConnectFilm = true;
            resolve();
          }
        }).catch(function (err) {
          that.$store.state.app.isConnectFilm = false;
          that.$toast('连接漆膜仪失败',err.message);
          resolve(err.message);
          console.log("openCamera=>" + err.message);
        });
      })
    },
    // 漆膜仪断连
    filmDisConnect(){
      const that = this;
      Bridge.interfaces.Android.filmDisConnect().then(function (s) {
        console.log(JSON.stringify(s));
        that.$store.state.app.isConnectFilm = false;
      }).catch(function (err) {
        that.$store.state.app.isConnectFilm = false;
        console.log("openCamera=>" + err.message);
      });

    },
    /**
     * 打印标签
     * type: 1:溯源标签 undefined:回用件标签
     * */ 
    setPrintDevice(partId,type){
      return new Promise((resolve)=>{
        let fn = type===1?getTraceabilityLabel:getPartLabel;
        fn(partId).then(async res2=>{
          if(res2.code == '00000' && res2.data){
            console.log(res2);
            let url = res2.data;
            if(this.$store.state.app.isConnectDevice){ // 是否连接中
              let errMsg = await window.printerStatusInfo(); // 查打印机状态是否正常
                if(!errMsg){
                  this.$store.state.app.isShowPrintMask = true;
                  let errMsg2 = await window.printerPrintDevice(url); // 打印
                  this.$store.state.app.isShowPrintMask = false;
                  if(!errMsg2){
                    resolve(1);
                  }else{
                    resolve(-1);
                  }
                }else{
                  this.$store.state.app.isShowPrintMask = false;
                  resolve(-1);
                }
            }else{
              let errMsg = await window.deviceConnect();  // 连接打印机
              if(!errMsg){
                let errMsg2 = await window.printerStatusInfo(); // 查打印机状态是否正常
                if(!errMsg2){
                  this.$store.state.app.isShowPrintMask = true;
                  let errMsg3 = await window.printerPrintDevice(url); // 打印
                  this.$store.state.app.isShowPrintMask = false;
                  if(!errMsg3){
                    resolve(1);
                  }else{
                    resolve(-1);
                  }
                }else{
                  resolve(-1);
                  this.$store.state.app.isShowPrintMask = false;
                }
              }else{
                resolve(-1);
                this.$store.state.app.isShowPrintMask = false;
              }
            }
          }
        })
      })
      
    },
    cancelScanCode(){
      this.scanToast && this.scanToast.clear();
      this.scanToast = null;
    },
    // 扫一扫
    scanCode(){
      // this.$bus.$emit('detailWorkPartCode','VPC172649764065136300002');
      // return ;
      var that = this;
      var parameter={"timeout":50}; // 超时时间
      this.scanToast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '识别中',
      });
      if(this.timeId) {
        clearTimeout(this.timeId);
      }
      this.timeId =  setTimeout(() => {
        this.timeId = null;
        if(that.scanToast){
          that.scanToast.clear();
          that.scanToast = null;
        }
      }, 10000);
      Bridge.interfaces.Android.scan(JSON.stringify(parameter)).then(async function (s) {
        console.log(s.toString());
        if(that.scanToast){
          that.scanToast.clear();
          that.scanToast = null;
        }
        if(s.code == '00000' && s.data){
          if(that.$route.path == '/addTicket'){
            that.$bus.$emit('detailWorkPartCode',s.data.codeResult);
          }else{
            let orderData = await that.getOrderDetail(s.data.codeResult);
            if(orderData.workOrderInfo.inboundOutboundStatus === 0){ // 待入库
              that.$store.state.app.isShowWarehousingMask = true;
              that.$store.state.app.vmScanOrderSn = orderData.workOrderInfo.orderSn;
            }else if(orderData.workOrderInfo.inboundOutboundStatus === 1 || orderData.workOrderInfo.inboundOutboundStatus === 2){ // 已入库，已出库状态
              that.$store.state.app.isShowUseMask = true;
              that.$store.state.app.vmScanOrderSn = orderData.workOrderInfo.orderSn;
            }else{
              that.$router.push('/detailTicket?partCode='+s.data.codeResult);
            }
          }
        }else{
          that.$toast("扫一扫异常" + JSON.stringify(res));
        }
      }).catch(function (err) {
        that.scanToast && that.scanToast.clear();
        that.scanToast = null;
        that.$toast(err.message);
        console.log("openCamera=>" + err.message);
      });
    },
    // 扫一扫工单详情
    getOrderDetail(partCode){
      return new Promise((resolve)=>{
        const toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '获取中',
        });
        getCodeDetailItem(partCode).then(res=>{
          toast.clear();
          if(res.code == '00000'){
            resolve(res.data);
          }
        })
      })
      
      },
    // 连接打印机
    deviceConnect(){
      var that = this;
      return new Promise((resolve)=>{
        let address = localStorage.getItem('connectDeciceAddress');
        if(address){
          const toast = this.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: '连接打印机中',
          });
          Bridge.interfaces.Android.printerConnect(address).then(function (res) {
            console.log(JSON.stringify(res));
            toast.clear();
            if(res.code == '00000' && res.data.codeResult){
              console.log('连接打印机成功');
              that.$toast("连接打印机成功");
              that.$store.state.app.isConnectDevice = true;
              resolve();
            }else{
              resolve('连接打印机异常');
              that.$toast("连接打印机异常" + JSON.stringify(res),address);
            }
          }).catch(function (err) {
            console.log('连接打印机失败=',err);
            resolve(err.message);
            // that.$toast("连接打印机失败=>" + err.message);
            that.$store.state.app.isConnectDevice = false;
          });
        }else{
          const toast = this.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: '扫描打印机中',
          });
          Bridge.interfaces.Android.requestPrinterDevice().then(function (s) {
            console.log(JSON.stringify(s));
            toast.clear();
            if(s.code == '00000' && s.data){
              Bridge.interfaces.Android.printerConnect(s.data[0].address).then(function (res) {
                console.log(JSON.stringify(res));
                if(res.code == '00000' && res.data.codeResult){
                  localStorage.setItem('connectDeciceAddress',s.data[0].address);
                  console.log('扫描打印机成功');
                  that.$toast("扫描打印机成功");
                  that.$store.state.app.isConnectDevice = true;
                  resolve();
                }else{
                  that.$toast("扫描打印机异常" + JSON.stringify(res));  
                }
              }).catch(function (err) {
                console.log('扫描打印机失败=',err);
                resolve(err.message);
                // that.$toast("扫描打印机失败=>" + err.message);
                that.$store.state.app.isConnectDevice = false;
              });
            }else{
              that.$toast("扫描打印机异常" + JSON.stringify(s));  
            }
          }).catch(function (err) {
            toast.clear();
            console.log('扫描打印机失败=',err);
            resolve(err.message);
            // that.$toast("扫描打印机失败=>" + err.message);
          });
        }
      })
      
    },
    /**
     * 检查连接的打印机状态
     * codeResult *
     * 00 就绪  01 上盖开启  02 卡纸 03 卡纸且上盖开启 04 标签用尽 05 标签用尽且上盖开启 08 碳带用尽 09 碳带用尽且上盖开启 
     * 0A 碳带用尽且卡纸 0B 碳带用尽、卡纸且上盖开启 0C 碳带用尽且标签用尽 0D 碳带用尽、标签用尽且上盖开启 10 暂停 20 打印中 80 其他错误
     * */ 
    printerStatusInfo(){
      var that = this;
      return new Promise((resolve)=>{
        Bridge.interfaces.Android.printerState().then(function (s) {
            console.log(JSON.stringify(s));
            if(s.code == '00000' && s.data){
              if(s.data.codeResult == '00'){
                resolve();
              }else{
                resolve('打印机状态异常：'+JSON.stringify(s));
                that.$toast('打印机状态异常：'+JSON.stringify(s));
              }
            }
        }).catch(function (err) {
            console.log("检查打印机状态失败" + err.message);
            resolve(err.message);
            // that.$toast("检查打印机状态失败=>" + err.message);
        });
      })
    },
    // 打印标签
    printerPrintDevice(url){
      var that = this;
      return new Promise((resolve)=>{
        Bridge.interfaces.Android.printer(url).then(function (s) {
          if(s.code == '00000' && s.data && s.data.codeResult){
            resolve();
          }else{
            resolve('打印失败：'+JSON.stringify(s));
            that.$toast('打印失败：'+JSON.stringify(s));
          }
        })
        .catch(function (err) {
          resolve('打印失败：'+err.message);
          // that.$toast('打印失败：'+err.message);
        });
      })
    },
  },
}
</script>