import { getToken, getUserInfo } from '@/utils/userInfo';

export default {
	// 开启命名空间
	namespaced: true,
	state: {
		// 用户信息
		userInfo: getUserInfo() || {},
		token: getToken(),
		// 用户地址信息
		userAddress: [],
	},
  mutations: {
		// 设置token
		setToken(state, val) {
			state.token = val;
		},
		// 设置用户信息
		setUserInfo(state, val) {
			state.userInfo = val;
		},
		// 移除token | 用户信息
		loginOut(state) {
			state.token = null;
			state.userInfo = {};
			state.userAddress = [];
		},
		GETUSERADDRESS(state, data) {
			state.userAddress = data;
		},
	},
	actions: {
	},
	getters: {},
}